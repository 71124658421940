import React from "react";
//import gifImage from "../../assets/videos/animation.gif";
import gifImage from "../../assets/videos/cubicaje/presentacionM_mameluco.gif";

// import { BlobServiceClient } from '@azure/storage-blob';

// const azureStorageConfig = {
//   accountName: 'interconectarblob',
//   accountKey: 'GGQB0VbdMfwT3+hKjD6vdT+s6oVfx+foJ7PaU+kWNfFVWSPVpPAxEzzMB391hVuCSXB1ZtO2bXo8+AStbpsC+Q==',
//   containerName: 'test-exportador',
// };

// const blobServiceClient = BlobServiceClient.fromConnectionString(
//   `DefaultEndpointsProtocol=https;AccountName=${azureStorageConfig.accountName};AccountKey=${azureStorageConfig.accountKey};EndpointSuffix=core.windows.net`
// );

// const containerClient = blobServiceClient.getContainerClient(azureStorageConfig.containerName);

// async function listBlobs() {
//   let blobNames: string[] = [];

//   for await (const blob of containerClient.listBlobsFlat()) {
//     blobNames.push(blob.name);
//   }

//   return blobNames;
// }

// listBlobs().then((blobNames) => {
// }).catch((error) => {
//   console.error('Error al listar blobs:', error);
// });

interface Props {
  showSrcImage: boolean;
  urlImagen: string | null;
}

const VideoAnimation: React.FC<Props> = ({ showSrcImage, urlImagen }) => {
  const srcParameter = urlImagen !== null ? urlImagen : gifImage;
  return (
    <img
      src={showSrcImage ? gifImage : srcParameter}
      alt="Animación GIF"
      style={{ maxWidth: "100%", height: "auto" }}
    />
  );
};

export default VideoAnimation;
