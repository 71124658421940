import * as React from 'react';

type IconHerramientasProps = React.SVGProps<SVGSVGElement>;

export const IconHerramientas: React.FC<IconHerramientasProps> = (props) => (
	<svg
		width={18}
		height={16}
		viewBox="0 0 18 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path d="M0.000976562 1C0.000976562 0.446875 0.447852 0 1.00098 0H3.2791C4.13848 0 4.9041 0.55 5.17598 1.36875L8.0541 10C8.99473 10.0156 9.8291 10.4656 10.3666 11.1562L16.6822 9.05C17.2072 8.875 17.7729 9.15937 17.9479 9.68125C18.1229 10.2031 17.8385 10.7719 17.3166 10.9469L11.001 13.0531C10.9729 14.6844 9.6416 16 8.00098 16C6.34473 16 5.00098 14.6562 5.00098 13C5.00098 12.0375 5.4541 11.1813 6.15723 10.6313L3.2791 2H1.00098C0.447852 2 0.000976562 1.55313 0.000976562 1ZM7.65098 4.20312C7.4791 3.67812 7.7666 3.1125 8.2916 2.94375L9.71973 2.48125L10.3385 4.38438L12.2416 3.76562L11.6197 1.8625L13.0479 1.4C13.5729 1.22812 14.1385 1.51563 14.3072 2.04063L15.851 6.79688C16.0229 7.32188 15.7354 7.8875 15.2104 8.05625L10.4541 9.6C9.9291 9.77188 9.36348 9.48438 9.19473 8.95938L7.65098 4.20312Z" fill="#425563"/>
	</svg>
);
// export default IconHerramientas;

