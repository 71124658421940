import React from "react";
import { useTranslation } from 'react-i18next';
import "./Banner.css";

const Banner: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className=" banner__container">
      <div className=" banner__row">
        <div className="banner__col banner__col--flex">
            <h2 className="banner__title">{t('banner.title')}</h2>
            <p className="banner__description">{t('banner.description')}</p>
        </div>
        <div className="banner__col">
          <div className="banner__image">
            <img src="../../assets/images/hero.svg" alt="" className="banner__img" />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Banner;
