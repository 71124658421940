import React from "react";
import RPassword from "../../components/RPassword/RPassword";
import { useParams } from "react-router-dom";

const RestorePasswordPage : React.FC = () => {
  const params = useParams();
  return (
    <div>
      <RPassword token={params.token} />
    </div>
  );
};

export default RestorePasswordPage;
