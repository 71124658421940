/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Interconectar.API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation
} from 'react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from 'react-query'
import type {
  ActivateUserCommand,
  BooleanValueResult,
  LoginCommand,
  LoginResponseViewModelValueResult,
  PersonalInformationCommand,
  RecoverPasswordCommand,
  RegisterUserCommand,
  ResetPasswordCommand
} from './models'
import postApiUserRegisterMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserRegisterErrorType } from './mutator/customInstance';
import postApiUserActivateMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserActivateErrorType } from './mutator/customInstance';
import postApiUserLoginMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserLoginErrorType } from './mutator/customInstance';
import postApiUserPersonalInformationMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserPersonalInformationErrorType } from './mutator/customInstance';
import postApiUserRecoverPasswordMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserRecoverPasswordErrorType } from './mutator/customInstance';
import postApiUserResetPasswordMutator from './mutator/customInstance';
import type { ErrorType as PostApiUserResetPasswordErrorType } from './mutator/customInstance';




export const postApiUserRegister = (
    registerUserCommand: RegisterUserCommand,
 ) => {
      
      
      return postApiUserRegisterMutator<BooleanValueResult>(
      {url: `/api/User/register`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registerUserCommand
    },
      );
    }
  


export const getPostApiUserRegisterMutationOptions = <TError = PostApiUserRegisterErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserRegister>>, TError,{data: RegisterUserCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserRegister>>, TError,{data: RegisterUserCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserRegister>>, {data: RegisterUserCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserRegister(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserRegister>>>
    export type PostApiUserRegisterMutationBody = RegisterUserCommand
    export type PostApiUserRegisterMutationError = PostApiUserRegisterErrorType<unknown>

    export const usePostApiUserRegister = <TError = PostApiUserRegisterErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserRegister>>, TError,{data: RegisterUserCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserRegister>>,
        TError,
        {data: RegisterUserCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserRegisterMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserActivate = (
    activateUserCommand: ActivateUserCommand,
 ) => {
      
      
      return postApiUserActivateMutator<BooleanValueResult>(
      {url: `/api/User/activate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activateUserCommand
    },
      );
    }
  


export const getPostApiUserActivateMutationOptions = <TError = PostApiUserActivateErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserActivate>>, TError,{data: ActivateUserCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserActivate>>, TError,{data: ActivateUserCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserActivate>>, {data: ActivateUserCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserActivate(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserActivateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserActivate>>>
    export type PostApiUserActivateMutationBody = ActivateUserCommand
    export type PostApiUserActivateMutationError = PostApiUserActivateErrorType<unknown>

    export const usePostApiUserActivate = <TError = PostApiUserActivateErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserActivate>>, TError,{data: ActivateUserCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserActivate>>,
        TError,
        {data: ActivateUserCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserActivateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserLogin = (
    loginCommand: LoginCommand,
 ) => {
      
      
      return postApiUserLoginMutator<LoginResponseViewModelValueResult>(
      {url: `/api/User/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginCommand
    },
      );
    }
  


export const getPostApiUserLoginMutationOptions = <TError = PostApiUserLoginErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserLogin>>, TError,{data: LoginCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserLogin>>, TError,{data: LoginCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserLogin>>, {data: LoginCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserLogin(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserLoginMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserLogin>>>
    export type PostApiUserLoginMutationBody = LoginCommand
    export type PostApiUserLoginMutationError = PostApiUserLoginErrorType<unknown>

    export const usePostApiUserLogin = <TError = PostApiUserLoginErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserLogin>>, TError,{data: LoginCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserLogin>>,
        TError,
        {data: LoginCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserPersonalInformation = (
    personalInformationCommand: PersonalInformationCommand,
 ) => {
      
      
      return postApiUserPersonalInformationMutator<BooleanValueResult>(
      {url: `/api/User/personalInformation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: personalInformationCommand
    },
      );
    }
  


export const getPostApiUserPersonalInformationMutationOptions = <TError = PostApiUserPersonalInformationErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserPersonalInformation>>, TError,{data: PersonalInformationCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserPersonalInformation>>, TError,{data: PersonalInformationCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserPersonalInformation>>, {data: PersonalInformationCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserPersonalInformation(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserPersonalInformationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserPersonalInformation>>>
    export type PostApiUserPersonalInformationMutationBody = PersonalInformationCommand
    export type PostApiUserPersonalInformationMutationError = PostApiUserPersonalInformationErrorType<unknown>

    export const usePostApiUserPersonalInformation = <TError = PostApiUserPersonalInformationErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserPersonalInformation>>, TError,{data: PersonalInformationCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserPersonalInformation>>,
        TError,
        {data: PersonalInformationCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserPersonalInformationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserRecoverPassword = (
    recoverPasswordCommand: RecoverPasswordCommand,
 ) => {
      
      
      return postApiUserRecoverPasswordMutator<BooleanValueResult>(
      {url: `/api/User/recoverPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: recoverPasswordCommand
    },
      );
    }
  


export const getPostApiUserRecoverPasswordMutationOptions = <TError = PostApiUserRecoverPasswordErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserRecoverPassword>>, TError,{data: RecoverPasswordCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserRecoverPassword>>, TError,{data: RecoverPasswordCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserRecoverPassword>>, {data: RecoverPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserRecoverPassword(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserRecoverPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserRecoverPassword>>>
    export type PostApiUserRecoverPasswordMutationBody = RecoverPasswordCommand
    export type PostApiUserRecoverPasswordMutationError = PostApiUserRecoverPasswordErrorType<unknown>

    export const usePostApiUserRecoverPassword = <TError = PostApiUserRecoverPasswordErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserRecoverPassword>>, TError,{data: RecoverPasswordCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserRecoverPassword>>,
        TError,
        {data: RecoverPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserRecoverPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const postApiUserResetPassword = (
    resetPasswordCommand: ResetPasswordCommand,
 ) => {
      
      
      return postApiUserResetPasswordMutator<BooleanValueResult>(
      {url: `/api/User/resetPassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: resetPasswordCommand
    },
      );
    }
  


export const getPostApiUserResetPasswordMutationOptions = <TError = PostApiUserResetPasswordErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: ResetPasswordCommand}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: ResetPasswordCommand}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiUserResetPassword>>, {data: ResetPasswordCommand}> = (props) => {
          const {data} = props ?? {};

          return  postApiUserResetPassword(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiUserResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof postApiUserResetPassword>>>
    export type PostApiUserResetPasswordMutationBody = ResetPasswordCommand
    export type PostApiUserResetPasswordMutationError = PostApiUserResetPasswordErrorType<unknown>

    export const usePostApiUserResetPassword = <TError = PostApiUserResetPasswordErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiUserResetPassword>>, TError,{data: ResetPasswordCommand}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiUserResetPassword>>,
        TError,
        {data: ResetPasswordCommand},
        TContext
      > => {

      const mutationOptions = getPostApiUserResetPasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
