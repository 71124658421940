import { Option } from '../../core/MultiSelect/multi-select';

const destinos: Option[] = [
    { label: 'Países que integran la Comunidad Europea', value: 'comunidad_europea' },
    { label: 'Alemania', value: 'alemania', parent: 'comunidad_europea' },
    { label: 'Austria', value: 'austria', parent: 'comunidad_europea' },
    { label: 'Bélgica', value: 'bélgica', parent: 'comunidad_europea' },
    { label: 'Bulgaria', value: 'bulgaria', parent: 'comunidad_europea' },
    { label: 'Chipre', value: 'chipre', parent: 'comunidad_europea' },
    { label: 'Croacia', value: 'croacia', parent: 'comunidad_europea' },
    { label: 'Dinamarca', value: 'dinamarca', parent: 'comunidad_europea' },
    { label: 'Eslovaquia', value: 'eslovaquia', parent: 'comunidad_europea' },
    { label: 'Eslovenia', value: 'eslovenia', parent: 'comunidad_europea' },
    { label: 'España', value: 'españa', parent: 'comunidad_europea' },
    { label: 'Estonia', value: 'estonia', parent: 'comunidad_europea' },
    { label: 'Finlandia', value: 'finlandia', parent: 'comunidad_europea' },
    { label: 'Francia', value: 'francia', parent: 'comunidad_europea' },
    { label: 'Grecia', value: 'grecia', parent: 'comunidad_europea' },
    { label: 'Hungría', value: 'hungría', parent: 'comunidad_europea' },
    { label: 'Irlanda', value: 'irlanda', parent: 'comunidad_europea' },
    { label: 'Italia', value: 'italia', parent: 'comunidad_europea' },
    { label: 'Letonia', value: 'letonia', parent: 'comunidad_europea' },
    { label: 'Lituania', value: 'lituania', parent: 'comunidad_europea' },
    { label: 'Luxemburgo', value: 'luxemburgo', parent: 'comunidad_europea' },
    { label: 'Malta', value: 'malta', parent: 'comunidad_europea' },
    { label: 'Países Bajos', value: 'países bajos', parent: 'comunidad_europea' },
    { label: 'Polonia', value: 'polonia', parent: 'comunidad_europea' },
    { label: 'Portugal', value: 'portugal', parent: 'comunidad_europea' },
    { label: 'República Checa', value: 'república checa', parent: 'comunidad_europea' },
    { label: 'Rumanía', value: 'rumanía', parent: 'comunidad_europea' },
    { label: 'Suecia', value: 'suecia', parent: 'comunidad_europea' },
    { label: 'Países que no integran la Comunidad Europea', value: 'no_comunidad_europea' },
    { label: 'Noruega', value: 'noruega', parent: 'no_comunidad_europea' },
    { label: 'Suiza', value: 'suiza', parent: 'no_comunidad_europea' },
    { label: 'Islandia', value: 'islandia', parent: 'no_comunidad_europea' },
    { label: 'Liechtenstein', value: 'liechtenstein', parent: 'no_comunidad_europea' },
    { label: 'Albania', value: 'albania', parent: 'no_comunidad_europea' },
    { label: 'Bosnia y Herzegovina', value: 'bosnia_y_herzegovina', parent: 'no_comunidad_europea' },
    { label: 'Macedonia del Norte', value: 'macedonia_del_norte', parent: 'no_comunidad_europea' },
    { label: 'Montenegro', value: 'montenegro', parent: 'no_comunidad_europea' },
    { label: 'Serbia', value: 'serbia', parent: 'no_comunidad_europea' },
    { label: 'Kosovo', value: 'kosovo', parent: 'no_comunidad_europea' },
    { label: 'Inglaterra', value: 'inglaterra', parent: 'no_comunidad_europea' },
    { label: 'Gales', value: 'gales', parent: 'no_comunidad_europea' },
    { label: 'Irlanda del Norte', value: 'irlanda_del_norte', parent: 'no_comunidad_europea' },
    { label: 'Escocia', value: 'escocia', parent: 'no_comunidad_europea' },
    { label: 'Vaticano', value: 'vaticano', parent: 'no_comunidad_europea' },
    { label: 'Andorra', value: 'andorra', parent: 'no_comunidad_europea' },
    { label: 'Aserbadjian', value: 'aserbadjian', parent: 'no_comunidad_europea' },
    { label: 'Bielorusia', value: 'bielorusia', parent: 'no_comunidad_europea' },
    { label: 'Georgia', value: 'georgia', parent: 'no_comunidad_europea' },
    { label: 'Kasajistan', value: 'kasajistan', parent: 'no_comunidad_europea' },
    { label: 'Moldavia', value: 'moldavia', parent: 'no_comunidad_europea' },
    { label: 'Monaco', value: 'monaco', parent: 'no_comunidad_europea' },
    { label: 'Rusia', value: 'rusia', parent: 'no_comunidad_europea' },
    { label: 'San Marino', value: 'san_marino', parent: 'no_comunidad_europea' },
    { label: 'Turquia', value: 'turquia', parent: 'no_comunidad_europea' },
    { label: 'Ucrania', value: 'ucrania', parent: 'no_comunidad_europea' },
    { label: 'Armenia', value: 'armenia', parent: 'no_comunidad_europea' },
];


export {  destinos };

export const unidades_distancias = ["km", "ml"]

export const options__units = ["kg","gr"]

//export const container_type = ["DRY-VAN 20", "REEFER 42", "DRY-VAN 40", "DRY-VAN 40 HUGH CUBE", "REEFER 40", "REEFER 40 HIGH CUBE"]

export const container_type = ["Contenedor Seco 20' (Dry-Van)", "Contenedor Seco 40' (Dry-Van)", "Contenedor Seco 40' High Cube (Dry-Van)", "Contenedor Seco 20' High Cube (Dry-Van)", "Contenedor Refrigerado 40' (Reefer)", "Contenedor Refrigerado 40' High Cube (Reefer)"]


interface Range{
  rangeTo: string;
  rangeFrom: string;
}

export const flete_range: Range = {rangeTo:"2000",rangeFrom:"8000"}

export const puertos_europa = [ 
    { label: "Alemania", value: "Alemania" },  
    { label: "Bremen", value: "Bremen", parent: "Alemania" }, 
    { label: "Bremerhaven", value: "Bremerhaven", parent: "Alemania" }, 
    { label: "Hamburgo", value: "Hamburgo", parent: "Alemania" }, 
    { label: "Lübeck", value: "Lübeck", parent: "Alemania" }, 
    { label: "Rostock", value: "Rostock", parent: "Alemania" }, 
    { label: "Bélgica", value: "Bélgica" },  
    { label: "Amberes", value: "Amberes", parent: "Bélgica" }, 
    { label: "Zeebrugge", value: "Zeebrugge", parent: "Bélgica" }, 
    { label: "Croacia", value: "Croacia" },  
    { label: "Rijeka", value: "Rijeka", parent: "Croacia" }, 
    { label: "Dinamarca", value: "Dinamarca" },  
    { label: "Aarhus", value: "Aarhus", parent: "Dinamarca" }, 
    { label: "Copenhague", value: "Copenhague", parent: "Dinamarca" }, 
    { label: "España", value: "España" },  
    { label: "Algeciras", value: "Algeciras", parent: "España" }, 
    { label: "Barcelona", value: "Barcelona", parent: "España" }, 
    { label: "Bilbao", value: "Bilbao", parent: "España" }, 
    { label: "Valencia", value: "Valencia", parent: "España" }, 
    { label: "Vigo", value: "Vigo", parent: "España" }, 
    { label: "Finlandia", value: "Finlandia" },  
    { label: "Helsinki", value: "Helsinki", parent: "Finlandia" }, 
    { label: "Turku", value: "Turku", parent: "Finlandia" }, 
    { label: "Francia", value: "Francia" },  
    { label: "Burdeos", value: "Burdeos", parent: "Francia" }, 
    { label: "Dunkerque", value: "Dunkerque", parent: "Francia" }, 
    { label: "LeHavre", value: "LeHavre", parent: "Francia" }, 
    { label: "Marsella-Fos", value: "Marsella-Fos", parent: "Francia" }, 
    { label: "Nantes", value: "Nantes", parent: "Francia" }, 
    { label: "Irlanda", value: "Irlanda" },  
    { label: "Belfast", value: "Belfast", parent: "Irlanda" }, 
    { label: "Cork", value: "Cork", parent: "Irlanda" }, 
    { label: "Dublin", value: "Dublin", parent: "Irlanda" }, 
    { label: "Islandia", value: "Islandia" },  
    { label: "Akureyi", value: "Akureyi", parent: "Islandia" }, 
    { label: "Raykjavik", value: "Raykjavik", parent: "Islandia" }, 
    { label: "Italia", value: "Italia" },  
    { label: "Génova", value: "Génova", parent: "Italia" }, 
    { label: "GioiaTauro", value: "GioiaTauro", parent: "Italia" }, 
    { label: "Nápoles", value: "Nápoles", parent: "Italia" }, 
    { label: "Trieste", value: "Trieste", parent: "Italia" }, 
    { label: "Lituania", value: "Lituania" },  
    { label: "Klaipeda", value: "Klaipeda", parent: "Lituania" }, 
    { label: "Malta", value: "Malta" },  
    { label: "Marsaxlokk", value: "Marsaxlokk", parent: "Malta" }, 
    { label: "Noruega", value: "Noruega" },  
    { label: "Bergen", value: "Bergen", parent: "Noruega" }, 
    { label: "Oslo", value: "Oslo", parent: "Noruega" }, 
    { label: "PaísesBajos", value: "PaísesBajos" },  
    { label: "Ámsterdam", value: "Ámsterdam", parent: "PaísesBajos" }, 
    { label: "Rotterdam", value: "Rotterdam", parent: "PaísesBajos" }, 
    { label: "Rotterdam-LaHaya", value: "Rotterdam-LaHaya", parent: "PaísesBajos" }, 
    { label: "Polonia", value: "Polonia" },  
    { label: "Gdansk", value: "Gdansk", parent: "Polonia" }, 
    { label: "Portugal", value: "Portugal" },  
    { label: "Leixoes", value: "Leixoes", parent: "Portugal" }, 
    { label: "Lisboa", value: "Lisboa", parent: "Portugal" }, 
    { label: "Sines", value: "Sines", parent: "Portugal" }, 
    { label: "Sines", value: "Sines", parent: "Portugal" }, 
    { label: "ReinoUnido", value: "ReinoUnido" },  
    { label: "Felixstowe", value: "Felixstowe", parent: "ReinoUnido" }, 
    { label: "Grimsby-Imminham", value: "Grimsby-Imminham", parent: "ReinoUnido" }, 
    { label: "Liverpool", value: "Liverpool", parent: "ReinoUnido" }, 
    { label: "Londres", value: "Londres", parent: "ReinoUnido" }, 
    { label: "Southampton", value: "Southampton", parent: "ReinoUnido" }, 
    { label: "Rusia", value: "Rusia" },  
    { label: "Novorossiysk", value: "Novorossiysk", parent: "Rusia" }, 
    { label: "SanPetesburgo", value: "SanPetesburgo", parent: "Rusia" }, 
    { label: "Suecia", value: "Suecia" },  
    { label: "Estocolmo", value: "Estocolmo", parent: "Suecia" }, 
    { label: "Gotemburgo", value: "Gotemburgo", parent: "Suecia" }, 
    { label: "Ucrania", value: "Ucrania" },  
    { label: "Oddessa", value: "Oddessa", parent: "Ucrania" }, 
]

export const europe_ports = {
    "Alemania": ["Bremen", "Bremerhaven", "Hamburgo", "Lübeck", "Rostock"],
    "Bélgica": ["Amberes", "Zeebrugge"],
    "Croacia": ["Rijeka"],
    "Dinamarca": ["Aarhus", "Copenhague"],
    "España": ["Algeciras", "Barcelona", "Bilbao", "Valencia", "Vigo"],
    "Finlandia": ["Helsinki", "Turku"],
    "Francia": ["Burdeos", "Dunkerque", "LeHavre", "Marsella-Fos", "Nantes"],
    "Irlanda": ["Belfast", "Cork", "Dublin"],
    "Islandia": ["Akureyi", "Raykjavik"],
    "Italia": ["Génova", "GioiaTauro", "Nápoles", "Trieste"],
    "Lituania": ["Klaipeda"],
    "Malta": ["Marsaxlokk"],
    "Noruega": ["Bergen", "Oslo"],
    "PaísesBajos": ["Ámsterdam", "Rotterdam", "Rotterdam-LaHaya"],
    "Polonia": ["Gdansk"],
    "Portugal": ["Leixoes", "Lisboa", "Sines"],
    "ReinoUnido": ["Felixstowe", "Grimsby-Imminham", "Liverpool", "Londres", "Southampton"],
    "Rusia": ["Novorossiysk", "SanPetesburgo"],
    "Suecia": ["Estocolmo", "Gotemburgo"],
    "Ucrania": ["Oddessa"]
  };

export const countries = {
    "América del Sur": ["Argentina", "Bolivia", "Brasil", "Chile", "Colombia", "Ecuador", "Guyana", "Paraguay", "Perú", "Surinam", "Uruguay", "Venezuela"],
    "América Central": ["Belice", "Costa Rica", "El Salvador", "Guatemala", "Honduras", "Nicaragua", "Panamá"],
    "América del Norte": ["Canadá", "Estados Unidos", "México"],
    "Caribe": ["Antigua y Barbuda", "Bahamas", "Barbados", "Cuba", "Dominica", "Granada", "Haití", "Jamaica", "Puerto Rico", "República Dominicana", "San Cristóbal y Nieves", "Santa Lucía", "San Vicente y las Granadinas", "Trinidad y Tobago"],
    "Otros países": ["Aruba (Países Bajos)", "Bonaire (Países Bajos)", "Curazao (Países Bajos)", "Groenlandia (Reino de Dinamarca)", "Guayana Francesa", "Islas Caimán (Reino Unido)", "Islas Turcas y Caicos (Reino Unido)", "Islas Vírgenes Británicas (Reino Unido)"]
  };

export const destination_country = {
  "Países que integran la Comunidad Europea": ['Alemania','Austria','Bélgica','Bulgaria','Chipre','Croacia','Dinamarca','Eslovaquia','Eslovenia','España','Estonia','Finlandia','Francia','Grecia','Hungría','Irlanda','Italia','Letonia','Lituania','Luxemburgo','Malta','Países Bajos','Polonia','Portugal','República Checa','Rumanía','Suecia'],
  "Países que no integran la Comunidad Europea": ['Noruega','Suiza','Islandia','Liechtenstein','Albania','Bosnia y Herzegovina','Macedonia del Norte','Montenegro','Serbia','Kosovo','Inglaterra','Gales','Irlanda del Norte','Escocia','Vaticano','Andorra','Aserbadjian','Bielorusia','Georgia','Kasajistan','Moldavia','Monaco','Rusia','San Marino','Turquia','Ucrania','Armenia']
}
