import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function handleRedirect(url: string): void {
    navigate(url);
  }

  return (
    <footer className="footer">
      <div className="footer__row">
        <div className="footer__col  footer__col--left">
          <span onClick={() => handleRedirect("acerca-interconectar")}>
            {t("footer.about_interconnect")}
          </span>
          <span onClick={() => handleRedirect("/servicios")}>
            {t("footer.services")}
          </span>
          <span onClick={() => handleRedirect("/terminosservicio")}>
            {t("footer.terms_of_service")}
          </span>
          <span onClick={() => handleRedirect("/politicasprivacidad")}>
            {t("footer.privacy_policy")}
          </span>
        </div>
        <div className="footer__col  footer__col--right"> 
          <a className="footer__social footer__youtube" target="_blank" rel="noreferrer" href="https://youtube.com/@exportopiaoficial?si=27tsAYLZOajDJI2q">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a className="footer__social footer__facebook" target="_blank" rel="noreferrer" href="#">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a className="footer__social footer__instagram" target="_blank" rel="noreferrer" href="#">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a className="footer__social footer__linkedin" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/exportopia/">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
