//import guapaletasLogo from "./clients/guapaletasLogo.svg";
//import originoLogo from "./clients/originoLogo.svg";
//import mulinoLogo from "./clients/mulinoLogo.svg";
//import wakasLogo from "./clients/wakasLogo.svg";
//import adsLogo from "./clients/adsLogo.svg";
//import fruitLogo from "./clients/fruitWorldLogo.svg";
//import karinatLogo from "./clients/karinatLogo.svg";
//import gauklerLogo from "./clients/gauklerLogo.svg";
//import aleluyaLogo from "./clients/aleluyaLogo.svg";

import A00_minproduccionytrabajoarg  from "./clients/A00_minproduccionytrabajoarg.svg";
import A01_logopeabodyalmadediseño_01  from "./clients/A01_logopeabodyalmadediseño_01.svg";
import A02_minproduccionytrabajoarg  from "./clients/A02_minproduccionytrabajoarg.svg";
import A03_mamuschka  from "./clients/A03_mamuschka.svg";
import A04_idclp  from "./clients/A04_idclp.svg";
import A05_handypizza  from "./clients/A05_handypizza.svg";
import A06_gaukler_logo_150  from "./clients/A06_gaukler_logo_150.svg";
import A07_faecar  from "./clients/A07_faecar.svg";
import A08_aleluya  from "./clients/A08_aleluya.svg";
import A09_01_isologotipo_guapaletas_celeste_p  from "./clients/A09_01_isologotipo_guapaletas_celeste_p.svg";
import A10_wakas  from "./clients/A10_wakas.svg";
import A11_saldecampo  from "./clients/A11_saldecampo.svg";
import A12_pastasmulini  from "./clients/A12_pastasmulini.svg";
import A13_noly  from "./clients/A13_noly.svg";
import A14_logo_20coop_20png_edited  from "./clients/A14_logo_20coop_20png_edited.svg";
import A15_logo_saintgottard  from "./clients/A15_logo_saintgottard.svg";
import A16_fincaselparaiso  from "./clients/A16_fincaselparaiso.svg";
import A17_logokohlenia  from "./clients/A17_logokohlenia.svg";
import A18_cfilogo  from "./clients/A18_cfilogo.svg";
import A19_chocolatesindustrialeslogo  from "./clients/A19_chocolatesindustrialeslogo.svg";
import A20_expofreshlogo  from "./clients/A20_expofreshlogo.svg";
import A21_fruitworldargentina  from "./clients/A21_fruitworldargentina.svg";
import A22_logo_1  from "./clients/A22_logo_1.svg";
import A23_airesdelsurargentina  from "./clients/A23_airesdelsurargentina.svg";
import A24_group  from "./clients/A24_group.svg";
import A25_mole  from "./clients/A25_mole.svg";


//export const clientsList = [
//  guapaletasLogo,
//  originoLogo,
//  mulinoLogo,
//  wakasLogo,
//  adsLogo,
//  fruitLogo,
//  karinatLogo,
//  gauklerLogo,
//  aleluyaLogo,
//];

export const clientsList = [
  A00_minproduccionytrabajoarg,
  A01_logopeabodyalmadediseño_01,
  A02_minproduccionytrabajoarg,
  A03_mamuschka,
  A04_idclp,
  A05_handypizza,
  A06_gaukler_logo_150,
  A07_faecar,
  A08_aleluya,
  A09_01_isologotipo_guapaletas_celeste_p,
  A10_wakas,
  A11_saldecampo,
  A12_pastasmulini,
  A13_noly,
  A14_logo_20coop_20png_edited,
  A15_logo_saintgottard,
  A16_fincaselparaiso,
  A17_logokohlenia,
  A18_cfilogo,
  A19_chocolatesindustrialeslogo,
  A20_expofreshlogo,
  A21_fruitworldargentina,
  A22_logo_1,
  A23_airesdelsurargentina,
  A24_group,
  A25_mole,
];
