import React from "react";
import { useTranslation } from 'react-i18next';
import paper from "../../../assets/images/papper.svg";
import circleError from "../../../assets/images/circle-exclamation-error.svg";

import "../generalStyles.css";

const EstudioDeMercado = () => {
  const {t} = useTranslation()
  const width = window.innerWidth;

  const checkRowContainer = width > 1220 ? "rowMainContainer" : "rowMainContainerResponsive"

  return (
    <div className="container p0 sectionMainContainer">
      <div className="col-6 mt-5">
        {/* <img src={paper} alt="paper-icon" /> */}
        <h4 className="sectionText">{t('services.tab_1.sectionTitle')}</h4>
        <p className="sectionDescription">{t('services.tab_1.sectionDescription')}</p>
        <div
          className={checkRowContainer}
        >
          <p className="itemTitle">{t('services.tab_1.competitionSurvey')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyList.item_5')}</p>
          </div>
        </div>
        <div
          className={checkRowContainer}
        >
          <p className="itemTitle">{t('services.tab_1.commercialDefinitionsForCustomersTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.commercialDefinitionsForCustomersList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.commercialDefinitionsForCustomersList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.commercialDefinitionsForCustomersList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.commercialDefinitionsForCustomersList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.commercialDefinitionsForCustomersList.item_5')}</p>
          </div>
        </div>
      </div>
      <div className="col-6 pr-5 mt-2">
        <div
          className={checkRowContainer}
        >
          <p className="itemTitle">{t('services.tab_1.competitionSurveyInEuropeTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_5')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_6')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_7')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_8')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_9')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.competitionSurveyInEuropeList.item_10')}</p>
          </div>
        </div>
        <div
          className={checkRowContainer}
        >
          <p className="itemTitle">{t('services.tab_1.marketInformationInEuropeTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.marketInformationInEuropeList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.marketInformationInEuropeList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.marketInformationInEuropeList.item_3')}</p>
          </div>
        </div>
        <div
          className={checkRowContainer}
        >
          <p className="itemTitle">{t('services.tab_1.priceAnalysisTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.priceAnalysisList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.priceAnalysisList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.priceAnalysisList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_1.priceAnalysisList.item_4')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstudioDeMercado;
