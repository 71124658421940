import React from "react";
import register_ok from "../../assets/images/register_ok.png";
import email_register from "../../assets/images/email_register.png";
import email_password from "../../assets/images/email_password.png";
import error from "../../assets/images/error.png";
import "./Information.css";
import { useNavigate } from "react-router-dom";

interface InformationeComponentProps {
  imageName: string;
  title: string;
  text: string;
  btnText: string;
  lblText: string;
  urlText: string;
}

const getImagePath = (imageName: string): string => {
  switch (imageName) {
    case "register_ok":
      return register_ok;
    case "email_register":
      return email_register;
    case "email_password":
      return email_password;
    case "error":
      return error;
    default:
      return error;
  }
};

const InformationComponent: React.FC<InformationeComponentProps> = ({
  imageName,
  title,
  text,
  btnText,
  lblText,
  urlText,
}) => {
  const navigate = useNavigate();
  const handleRedirectClick = (url: string) => {
    navigate(url);
  };
  return (
    <div className="container-information">
      <span className="title-information">{title}</span>
      <img src={getImagePath(imageName)} alt={title} />
      <p className="txt-information">{text}</p>
      {lblText ? (
        <label
          onClick={() => handleRedirectClick(urlText)}
          className="lbl-information"
        >
          {lblText}
        </label>
      ) : (
        (btnText) && btnText !== undefined &&
        <button
          onClick={() => handleRedirectClick(urlText)}
          className="btn-information"
        >
          {btnText}
        </button>
      )}
    </div>
  );
};

export default InformationComponent;
