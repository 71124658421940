import React, { useEffect, useState } from "react";
import market from "../../../assets/images/market.svg";
import product from "../../../assets/images/shoping.svg";
import country from "../../../assets/images/paises.svg";
import figures from "../../../assets/images/publicaciones.svg";
import pencil from "../../../assets/images/pencil.svg";

const tabs = [
  { id: 0, title: "Tipo de Mercado", key: "market", image: market },
  { id: 1, title: "Tipo de Producto", key: "product", image: product },
  { id: 2, title: "Países", key: "country", image: country },
  { id: 3, title: "Publicaciones", key: "publish", image: figures },
  { id: 4, title: "Normativas EU", key: "others", image: market },
];

const PreferencesDetail = ({
  preferences,
  onEditPreference,
}: {
  preferences: any[];
  onEditPreference: () => void;
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [preferencesGroup, setPreferencesGroup] = useState<any>([]);

  useEffect(() => {
    const obj = groupItemsBySubcategory();
    setPreferencesGroup(obj);
  }, [activeTab]);

  const groupItemsBySubcategory = () => {
    const listFiltered: any = preferences.filter(
      (e: any) => e.parentCategory === tabs[activeTab].title
    );
    return listFiltered.reduce((accumulator: any, obj: any) => {
      const parent = obj.tag.parent;
      if (!accumulator[parent]) {
        accumulator[parent] = [];
      }
      accumulator[parent].push(obj);
      return accumulator;
    }, {});
  };

  return (
    <div className="preferencesMainContainer">
      <div className="navtabs">
        {tabs.map((tab, index) => (
          <span
            onClick={() => setActiveTab(tab.id)}
            key={tab.id}
            className={` navtabs__tab  ${activeTab === index ? "active" : ""}`}
          >
            <img src={tab.image} alt={tab.title} className="navtabs__tab__icon" />
            <div className="navtabs__tab__text">{tab.title}</div>
          </span>
        ))}
      </div>
      <div className="preferences__tag-container preferencesDetailContainer">
        {Object.keys(preferencesGroup).map((group: any, groupIndex: number) => (
          <div key={groupIndex} className="preferences__tag-group">
            <span className="preferences__tag-title">{group}</span>
            <div className="preferences__container">
              {preferencesGroup[group].map((item: any) => (
                <div className="preferences__tags">
                  <div
                    key={groupIndex}
                    className={`preferences__tag preferences__tag--selected`}
                  >
                    {item?.tag.label}
                  </div>
                </div>
              ))}
              <div
                className="pencilIconImage"
                onClick={() => onEditPreference()}
              >
                <img src={pencil} alt="edit-preference-icon" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PreferencesDetail;
