import * as React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import './CustomToolTip.css';

interface StyledTooltipProps extends TooltipProps {
  tooltipText: string | React.ReactNode;
}

const CustomToolTip: React.FC<StyledTooltipProps> = ({ tooltipText, ...props }) => (
    <Tooltip
        {...props}
        title={tooltipText}
        classes={{ tooltip: 'custom-tooltip' }}
      />
);

export default CustomToolTip;
