import React, { SyntheticEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import circleCheckOrange from "../../../assets/images/circle-check-solid-orange.svg";
import circleCheckRegular from "../../../assets/images/circle-check-regular.svg";

interface IModalServicesDetailProps {
  title: string;
  detail?: IDetailOurServices;
  show: boolean;
  handleClose: () => void;
}

export interface ISubItem {
  name: string;
  available: boolean;
}

export interface Item {
  name?: string;
  available?: boolean;
  subItems?: ISubItem[];
}

export interface IDetailOurServices {
  title: string;
  subtitle: string;
  items: Item[];
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "#425563",
    borderRadius: "12px",
    maxWidth: "800px",
  },
}));

const BootstrapAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    background: "#2E3C46F0",
    color: "#ffffff",
    borderRadius: "12px",
    marginBottom: "15px",
  },
  "&.MuiAccordion-root.Mui-expanded": {
    background: "#CEDFD5",
    color: "#1A2228",
  },
}));

const ModalServicesDetail = ({
  title,
  detail,
  show,
  handleClose,
}: IModalServicesDetailProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("");

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : "");
    };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={show}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle
        sx={{ color: "#ECEEEF", m: 0, padding: "16px 20px 0px" }}
        id="customized-dialog-title"
      >
        {detail?.title}
        <Typography sx={{ color: "#D4D8DB" }}>{detail?.subtitle}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          color: "#D4D8DB",
          fontSize: "17px",
          fontFamily: "Barlow",
          padding: "8px 20px 0 20px",
        }}
      >
        {detail &&
          detail.items?.map((item, index) => (
            <BootstrapAccordion
              key={index}
              onChange={handleChange(`panel${index}`)}
              expanded={expanded === `panel${index}`}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownRoundedIcon
                    sx={{
                      fontSize: "2rem",
                      color:
                        expanded === `panel${index}` ? "#000000" : "#ffffff",
                    }}
                  />
                }
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                {item.available ? (
                  <img
                    src={circleCheckOrange}
                    alt="check"
                    style={{ marginRight: "0.5rem" }}
                  />
                ) : (
                  <img
                    src={circleCheckRegular}
                    alt="check"
                    style={{ marginRight: "0.5rem" }}
                  />
                )}
                <Typography sx={{}}>{item.name}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Box
                  component="ul"
                  sx={{
                    listStyleType: "none",
                    paddingLeft: 2,
                    "& li": {
                      position: "relative",
                      paddingLeft: 2,
                      "&::before": {
                        content: '"•"',
                        position: "absolute",
                        left: 0,
                        top: "-1rem",
                        fontSize: "2rem",
                        color: "#97A2A9",
                      },
                      "&.check::before": {
                        color: "#FF5C39",
                      },
                    },
                  }}
                >
                  {item.subItems?.map((subItem, index) => (
                    <li className={subItem.available ? "check" : ""}>
                      <Typography
                        sx={{
                          fontWeight: subItem.available ? 400 : 400,
                        }}
                      >
                        {subItem.name}
                      </Typography>
                    </li>
                  ))}
                </Box>
              </AccordionDetails>
            </BootstrapAccordion>
          ))}
      </DialogContent>
      <DialogActions
        sx={{ borderTop: "solid 1px #2E3C46", padding: "16px 32px" }}
      >
        <Button
          autoFocus
          onClick={handleClose}
          sx={{
            backgroundColor: "#97A2A9",
            color: "#1A2228",
            fontSize: "13px",
            fontFamily: "Barlow",
            textTransform: "none",
            borderRadius: "20px",
            fontWeight: "600",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#97A2A9",
            },
          }}
        >
          {t("finalReport.modal_services_detail.button")}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ModalServicesDetail;
