import React from 'react';
import 'react-phone-number-input/style.css';
import './CustomizedSelect.css';

interface CustomizedSelectProps {
  title: string;
  options: string[];
  onChange?: (value: any) => void;
  value?: string;
}

const CustomizedSelect: React.FC<CustomizedSelectProps> = ({ title, options, onChange, value, ...prop}) => {
  return (
    <div className='CustomizedSelect__container'>
      <div className="CustomizedSelect__title">{title}</div>
      <div className="country-info">
        <select 
          {...prop}
          value={value}
          onChange={(e) => {
            if (onChange) { // Check if onChange is provided
              onChange(e.target.value); // Call onChange only if it's defined
            }
          }}>
          {options.map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomizedSelect;