import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ExporterTest from "../../components/ExporterTest/ExporterTest";
import ExporterTestInformation from "../../components/ExporterTest/ExporterTestInformation";
import VideoComponent from "../../core/Video/VideoAnimation";
import AnimationTest from "../../components/ExporterTest/AnimationTest";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import "./exporter-test.css";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";

const ExporterTestPage: React.FC = () => {
  const {t} = useTranslation()
  const [showExporterTest, setShowExporterTest] = useState(true);
  const [showVideoComponent, setShowVideoComponent] = useState(false);
  const [percentageValue, setPercentageValue] = useState<number>(0);
  const [urlImage, setUrlImage] = useState<string | null>(null);

  const handleComponentChange = () => {
    setShowExporterTest(!showExporterTest);
  };

  const handleStartButtonClick = () => {
    setShowVideoComponent(true);
  };

  const onHandleImageChange = (image: string | null) => {
    setUrlImage(image);
  };

  useEffect(() => {}, [urlImage]);

  return (
    <div className="">
    <div className="mainExporterTestContainer">
      <div className="exporter-test-information__header">
        <div className="exporter-test-information__principal-title">
          <div className="exporter-test-information__top">
            <span>{t('exporterTest.header.title')}</span>
            <CustomToolTip
              title=""
              tooltipText={t('exporterTest.header.tooltip')}
            >
              <InfoIcon className="exporter-test-information__icon-title" />
            </CustomToolTip>
            </div>
          <div className="exporter-test-information__percent-bar">
            <span>{percentageValue}% {t('exporterTest.header.percentage')}</span>
          </div>
        </div>
        <div className="exporter-test-information__principal-sub-title">
          <p>{t('exporterTest.header.subtitle')}</p>
        </div>
      </div>
      
      <div className="exporterTest__row">
        <div className="exporterTest__flex test-exporter-container">
          {showExporterTest ? (
            <ExporterTestInformation
              onComponentChange={handleComponentChange}
            />
          ) : (
            <ExporterTest
              onChangeImage={onHandleImageChange}
              onStepChange={setPercentageValue}
            />
          )}
        </div>
        <div className="exporterTest__fixed">
          {showVideoComponent ? (
            <div className="stickyVideo">
              <VideoComponent
                showSrcImage={urlImage !== null ? false : true}
                urlImagen={urlImage}
              />
            </div>
          ) : (
            <AnimationTest onStartButtonClick={handleStartButtonClick} />
          )}
        </div>
      </div>
    </div>
  
    </div>

  );
};

export default ExporterTestPage;
