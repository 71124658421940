import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./Aside.css";
import testExpImg from "../../assets/images/test-exportador.png";
import deliveryTruck from "../../assets/images/calculadora-paletizado.png";
import calculator from "../../assets/images/calculadora-precios.svg";
import priceComparator from "../../assets/images/compador-precios.svg";

const Aside: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const handleButtonClick = (url: string) => {
    navigate(isAuthenticated ? url : "/register");
  };

  return (
    <div className=" aside__container">
      <div className=" aside__row">
        <div className=" aside_card">
            <div className="frame-35 aside_card__figure card-1">
              <img src={testExpImg} alt="test exportador" />
            </div>
            <div className="aside_card__flex aside_card__card-1">
              <div className="frame-37">
                <span className="test-exporter">{t("aside.box1.title")}</span>
                <span className="description-text">
                  {t("aside.box1.description")}
                </span>
              </div>
              <span
                className="btn-ver"
                onClick={() => handleButtonClick("/exportertest")}
              >
                <span className="txt-ver">{t("aside.box1.button")}</span>
                <FontAwesomeIcon icon={faArrowRight} color="#ECEEEF" />
              </span>
            </div>
        </div>
        <div className=" aside_card">
            <div className="frame-35 aside_card__figure card-4">
              <img src={deliveryTruck} alt="delivery" />
            </div>
            <div className="aside_card__flex aside_card__card-4">
              <div className="frame-37">
                <span className="calculadora-de-cubicaje">
                  {t("aside.box2.title")}
                </span>
                <span className="texto-descriptivo">
                  {t("aside.box2.description")}
                </span>
              </div>
              <span
                className="btn-ver"
                onClick={() => handleButtonClick("/cubicaje")}
              >
                <span className="txt-ver">{t("aside.box2.button")}</span>
                <FontAwesomeIcon icon={faArrowRight} color="#ECEEEF" />
              </span>
            </div>
        </div>
        <div className=" aside_card">
          <div className="frame-35 aside_card__figure card-2">
              <img src={calculator} alt="calculator" />
            </div>
            <div className="aside_card__flex card-2">
              <div className="frame-37">
                <span className="calculadora-precios">
                  {t("aside.box3.title")}
                </span>
                <span className="texto-descriptivo">
                  {t("aside.box3.description")}
                </span>
              </div>
              <span
                className="btn-ver"
                onClick={() => handleButtonClick("/calcularprecios")}
              >
                <span className="txt-ver">{t("aside.box3.button")}</span>
                <FontAwesomeIcon icon={faArrowRight} color="#ECEEEF" />
              </span>
            </div>
        </div>
        <div className=" aside_card">
          <div className="frame-35 aside_card__figure card-3">
              <img src={priceComparator} alt="price" />
            </div>
            <div className="aside_card__flex card-3">
              <div className="frame-37">
                <span className="calculadora-de-precios">
                  {t("aside.box4.title")}
                </span>
                <span className="texto-descriptivo">
                  {t("aside.box4.description")}
                </span>
              </div>
              <span
                className="btn-ver"
                onClick={() => handleButtonClick("/pricecomparator")}
              >
                <span className="txt-ver">{t("aside.box4.button")}</span>
                <FontAwesomeIcon icon={faArrowRight} color="#ECEEEF" />
              </span>
            </div>
        </div>
      </div>

    </div>
  );
};

export default Aside;
