import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import finalReportAnimated from "../../../assets/images/finalReportAnimated.svg";
import download from "../../../assets/images/download.svg";
import "./topContent.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";

import emergenteUno from "../../../assets/images/emergenteUno.svg";
import emergenteDos from "../../../assets/images/emergenteDos.svg";
import emergenteTres from "../../../assets/images/emergenteTres.svg";
import consolidadoUno from "../../../assets/images/consolidadoUno.svg";
import consolidadoDos from "../../../assets/images/consolidadoDos.svg";
import consolidadoTres from "../../../assets/images/consolidadoTres.svg";
import expertoUno from "../../../assets/images/expertoUno.svg";
import expertoDos from "../../../assets/images/expertoDos.svg";
import expertoTres from "../../../assets/images/expertoTres.svg";

type ITopContent = {
  name: string;
  level: number;
  sublevel: number;
};

const TopContent = ({ name, level, sublevel }: ITopContent) => {
  const {t} = useTranslation()
  const [levelData, setLevelData] = useState<any>({});
  const navigate = useNavigate();

  const levelDescription = [
    {
      level: 1,
      sublevel: 1,
      logo: emergenteUno,
      characteristics: [
        t('finalReport.characteristics_1'),
        t('finalReport.characteristics_2'),
        t('finalReport.characteristics_3'),
      ],
      summary:
        t('finalReport.summary_1'),
      description:
        t('finalReport.description_1'),
      description2:
        t('finalReport.description2_1'),
    },
    {
      level: 1,
      sublevel: 2,
      logo: emergenteDos,
      characteristics: [
        t('finalReport.characteristics_1'),
        t('finalReport.characteristics_2'),
        t('finalReport.characteristics_3'),
      ],
      summary:
        t('finalReport.summary_1'),
      description:
        t('finalReport.description_1'),
      description2:
        t('finalReport.description2_1'),
    },
    {
      level: 1,
      sublevel: 3,
      logo: emergenteTres,
      characteristics: [
        t('finalReport.characteristics_1'),
        t('finalReport.characteristics_2'),
        t('finalReport.characteristics_3'),
      ],
      summary:
        t('finalReport.summary_1'),
      description:
        t('finalReport.description_1'),
      description2:
        t('finalReport.description2_1'),
    },
    {
      level: 2,
      sublevel: 1,
      logo: consolidadoUno,
      characteristics: [
        t('finalReport.characteristics_4'),
        t('finalReport.characteristics_5'),
        t('finalReport.characteristics_6'),
      ],
      summary:
        t('finalReport.summary_2'),
      description:
        t('finalReport.description_2'),
      description2:
        t('finalReport.description2_2'),
    },
    {
      level: 2,
      sublevel: 2,
      logo: consolidadoDos,
      characteristics: [
        t('finalReport.characteristics_4'),
        t('finalReport.characteristics_5'),
        t('finalReport.characteristics_6'),
      ],
      summary:
        t('finalReport.summary_2'),
      description:
        t('finalReport.description_2'),
      description2:
        t('finalReport.description2_2'),
    },
    {
      level: 2,
      sublevel: 3,
      logo: consolidadoTres,
      characteristics: [
        t('finalReport.characteristics_4'),
        t('finalReport.characteristics_5'),
        t('finalReport.characteristics_6'),
      ],
      summary:
        t('finalReport.summary_2'),
      description:
        t('finalReport.description_2'),
      description2:
        t('finalReport.description2_2'),
    },
    {
      level: 3,
      sublevel: 1,
      logo: expertoUno,
      characteristics: [
        t('finalReport.characteristics_7'),
        t('finalReport.characteristics_8'),
        t('finalReport.characteristics_9'),
      ],
      summary:
      t('finalReport.summary_3'),
      description:
      t('finalReport.description_3'),
      description2:
      t('finalReport.description2_3'),
      description3:
      t('finalReport.description3_1'),
    },
    {
      level: 3,
      sublevel: 2,
      logo: expertoDos,
      characteristics: [
        t('finalReport.characteristics_7'),
        t('finalReport.characteristics_8'),
        t('finalReport.characteristics_9'),
      ],
      summary:
      t('finalReport.summary_3'),
      description:
      t('finalReport.description_3'),
      description2:
      t('finalReport.description2_3'),
      description3:
      t('finalReport.description3_1'),
    },
    {
      level: 3,
      sublevel: 3,
      logo: expertoTres,
      characteristics: [
        t('finalReport.characteristics_7'),
        t('finalReport.characteristics_8'),
        t('finalReport.characteristics_9'),
      ],
      summary:
      t('finalReport.summary_3'),
      description:
      t('finalReport.description_3'),
      description2:
      t('finalReport.description2_3'),
      description3:
      t('finalReport.description3_1'),
    },
  ];

  useEffect(() => {
    const levelFounded = foundLevel(level, sublevel);
    setLevelData(levelFounded);
  }, [level, sublevel]);

  const foundLevel = (level: number, sublevel: number) => {
    const optionFounded = levelDescription.find(
      (e) => e.level === level && e.sublevel === sublevel
    );
    return optionFounded || "";
  };

  const downloadPdf = () => {
    const input = document.getElementById("finalTest"); // The ID of the div you want to download as PDF
    html2canvas(input!).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("informeFinalTestExportador.pdf");
    });
  };

  return (
    <div id="finalTest" className="topContentMainContainer">
        <div className="finalTest__flex mainSquareContainer">
          <div className="topDataContainer">
            <div>
              <h5 className="subtitleText">{t('finalReport.title')}</h5>
              <h2 className="actualLevelTitleText">{t(name)}</h2>
              <div className="characteristicsContainer">
                <p className="characteristicsLabelTitle">{t('finalReport.features')}:</p>
                {levelData?.characteristics?.map((item: any) => (
                  <div className="characteristicsItem">
                    <div className="circleItem" />
                    <p>{item}</p>
                  </div>
                ))}
              </div>
            </div>
            <img src={levelData?.logo} className="levelImage" />
          </div>
          <div className="summaryContainer">
            <p>{levelData?.summary}</p>
          </div>
          <div className=" descriptionContainer">  
              <p>{levelData?.description}</p>
              <p>{levelData?.description2}</p>
              <p>{levelData?.description3}</p>
          </div>
          <div className="divider" />
          <div className="footerSquareContainer">
            <div onClick={downloadPdf}>
              <img src={download} alt="download" />
              <a className="downloadText">{t('finalReport.footer.link')}</a>
            </div>
            <div
              onClick={() => navigate("/exportertest")}
              className="editFormButton"
            >
              <a>{t('finalReport.footer.button')}</a>
            </div>
          </div>
        </div>
        <div className="finalTest__fixed">
          <img src={finalReportAnimated} alt="final-report-animation" />
        </div>
    </div>
  );
};

export default TopContent;
