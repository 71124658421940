import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

interface ComparatorVideoContextType {
    comparatorVideo: number;
    setComparatorVideo: Dispatch<SetStateAction<number>>;
  }

const initialComparatorVideoContext = {
    comparatorVideo: 0,
    setComparatorVideo: () => {}
}

export const ComparatorVideoContext = createContext<ComparatorVideoContextType>(initialComparatorVideoContext)

export const useComparatorVideo = () => useContext(ComparatorVideoContext);

export const ComparatorVideoProvider = ({ children }: {children: ReactNode}) => {
    const [comparatorVideo, setComparatorVideo] = useState(0);
  
    return (
        <ComparatorVideoContext.Provider value={{ comparatorVideo, setComparatorVideo }}>
            {children}
        </ComparatorVideoContext.Provider>
    );
};
