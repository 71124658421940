import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import circleError from "../../assets/images/circle-exclamation-error.svg";
import "react-phone-number-input/style.css";
import "./Cubicaje.css";
import CustomizedSwitch from "../../core/CustomizedSwitch/CustomizedSwitch";
import { Contenedor, IFormData, Pallet } from "./Cubicaje";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import CustomMessage from "../../core/CustomMessage/CustomMessage";
import seco20 from "../../assets/videos/cubicaje/20ft.gif";
import seco40 from "../../assets/videos/cubicaje/40ft.gif";
import reefer40 from "../../assets/videos/cubicaje/reefer40ft.png";
interface Props {
  contenedoresArray: Contenedor[];
  palletItem: Pallet;
  handleContinueClick: () => void;
  formData: IFormData;
  setFormData: Function;
  onCubicajeSelected: Function;
  setIsLegalModalVisible: Function;
}

const CubicajeSeccionCuatro: React.FC<Props> = ({
  contenedoresArray,
  palletItem,
  handleContinueClick,
  formData,
  setFormData,
  onCubicajeSelected,
  setIsLegalModalVisible,
}) => {
  const { t } = useTranslation();
  const [idpalletSelected, setIdPalletSelected] = useState<any>(null);
  const [isChekedContenedor, setisChekedContenedor] = useState<any>(null);
  const [showMessageContenedor, setShowMessageContenedor] = useState(false);
  const [showMessageContenedorPeso, setShowMessageContenedorPeso] =
    useState(false);
  const [containterSelecte, setContainterSelecte] = useState<Contenedor>(
    contenedoresArray[0]
  );
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const [isErrorContainerSelected, setIsErrorContainerSelected] =
    useState<boolean>(false);

  function handleSwitchChange_Cuatro(arg0: boolean): void {
    setisChekedContenedor(arg0);
  }

  function ContainerSelected(contenedor: Contenedor) {
    getImage(contenedor.palletIdName);
    setContainterSelecte(contenedor);
    setIdPalletSelected(contenedor?.index);
    kPalets(contenedor);
    const validadorAltura = kFilasMaxAltura(contenedor);
    if (validadorAltura > 10000) {
      setShowMessageContenedor(true);
    } else {
      setShowMessageContenedor(false);
    }
    CalcularPesoCarga(contenedor);
    setFormData((prevData: IFormData) => ({
      ...prevData,
      contenedor: contenedor,
    }));
    setIsErrorContainerSelected(false);
  }

  const getImage = (containerName: string) => {
    switch (containerName) {
      case "Contenedor Seco 20 (Dry-Van)":
        onCubicajeSelected(false, seco20);
        return;
      case "Contenedor Seco 40 (Dry-Van)":
        onCubicajeSelected(false, seco40);
        return;
      case "Contenedor Seco 40 High Cube (Dry-Van)":
        onCubicajeSelected(false, seco20);
        return;
      case "Contenedor Seco 20 High Cube (Dry-Van)":
        onCubicajeSelected(false, seco40);
        return;
      case "Contenedor Refrigerado 40 (Reefer)":
        onCubicajeSelected(false, reefer40);
        return;
      case "Contenedor Refrigerado 40 High Cube (Reefer)":
        onCubicajeSelected(false, reefer40);
        return;
    }
  };

  function kFilasMaxAltura(contenedor: Contenedor) {
    const varPuetasAlto = contenedor.puertasAbiertasAlto;
    const varAltoPallet = palletItem.alto;
    const varAltoCaja = formData.altoStep2;
    const altoCaje = varAltoCaja !== "" ? parseFloat(varAltoCaja) : 0;
    const varFilasMaxAlturaContenedor =
      (varPuetasAlto - varAltoPallet) / altoCaje;
    setFormData((prevData: IFormData) => ({
      ...prevData,
      KFilaMaxAltura: varFilasMaxAlturaContenedor.toLocaleString(),
    }));
    return varFilasMaxAlturaContenedor;
  }

  function kPalets(contenedor: Contenedor) {
    const valorFinal = formData.palletSelected === 0 ? contenedor.europaletWeight : contenedor.standardWeight
    
    setFormData((prevData: IFormData) => ({
      ...prevData,
      kPallet: valorFinal.toString(),
    }));
    return valorFinal;
  }

  function CalcularPesoCarga(contenedor: Contenedor) {
    const FilaMaxPesoPallet = formData.FilasMaximoPeso;
    const varValorPesoMax =
      FilaMaxPesoPallet !== "" ? parseFloat(FilaMaxPesoPallet) : 0;
    if (varValorPesoMax > contenedor.pesoContenedor) {
      setShowMessageContenedorPeso(true);
    } else {
      setShowMessageContenedorPeso(false);
    }
  }

  const validateBeforeSubmit = () => {
    if (isChekedContenedor === null || isErrorContainerSelected) {
      setIsErrorVisible(true);
    } else {
      handleContinueClick();
    }
  };

  return (
    <div>
      <form>
        <div>
          <label className="form-label">{t("cubicaje.step_4.subtitle")}</label>
          <div className="auto-group-wrs9-G7f">
            <CustomizedSwitch
              onChange={([isChekedContenedor]) => {
                handleSwitchChange_Cuatro(isChekedContenedor || false);
                setIsErrorVisible(false);
                setIsErrorContainerSelected(isChekedContenedor);
              }}
              checked={isChekedContenedor}
            />
          </div>
          {isErrorVisible && (
            <div className="container errorContainer">
              <img src={circleError} alt="error-icon" />
              <p className="errorInputValidation">
                {t("cubicaje.step_4.required")}
              </p>
            </div>
          )}
          <div className="auto-group-6qah-KNM">
            {isChekedContenedor ? (
              <>
                <p className="conteoPalletsPestanaUno">
                  {contenedoresArray.length} {t("cubicaje.step_4.available")}.{" "}
                  <span>
                    {t("cubicaje.step_4.availableLink")}{" "}
                    <CustomToolTip
                      title=""
                      //tooltipText="Certificado según estándares EPAL y UIC, recomendada para reducir costos, optimizar  el apilamiento de cajas y asegurar una logística eficiente. Favorece la sostenibilidad con reutilización y reciclaje."
                      tooltipText={t("cubicaje.step_4.availableLink.tooltip")}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        className="font-size: 15px"
                      />
                    </CustomToolTip>
                  </span>
                </p>
                {showMessageContenedor ? (
                  <CustomMessage
                    background={"#F4B400"}
                    textMessage={`${t("cubicaje.step_4.message_1")} ${containterSelecte?.anchoLargo}`}
                  />
                ) : (
                  <></>
                )}
                {showMessageContenedorPeso ? (
                  <CustomMessage
                    background={"#F4B400"}
                    textMessage={`${t("cubicaje.step_4.message_2")} ${
                      containterSelecte.pesoContenedor +
                      containterSelecte.pesoMedida
                    }`}
                  />
                ) : (
                  <></>
                )}
                {contenedoresArray.map((xData, questionIndex) => (
                  <div
                    className="typePallet"
                    key={questionIndex}
                    onClick={() => ContainerSelected(xData)}
                  >
                    <div
                      className={
                        idpalletSelected === questionIndex
                          ? `auto-group-pspf-ddw palletSelected`
                          : `auto-group-pspf-ddw`
                      }
                    >
                      <div className="auto-group-pund-wed">
                        <p className="europallet-U8m">
                          {xData.titlePallet}{" "}
                          <CustomToolTip title="" tooltipText={xData.tooltip}>
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              className="font-size: 15px"
                            />
                          </CustomToolTip>
                        </p>
                      </div>
                      <div className="group-84-cXo">
                        <div className="auto-group-6spv-9Gq">
                          <p className="x800x145mm-ToK">{xData.anchoLargo}</p>
                          <p className="ancho-x-largo-x-alto-mJD">
                            {t("cubicaje.step_4.subItem_1.title")}
                          </p>
                        </div>
                        <div className="auto-group-pxb3-aus">
                          <p className="carga-dinmica-vyj" id="1:201">
                            {t("cubicaje.step_4.subItem_2.title")}
                          </p>
                          <p className="kg-RvV" id="1:205">
                            {xData.cargaDinamica}
                          </p>
                        </div>
                        <div className="auto-group-2gox-MZF">
                          <p className="carga-esttica-7HX" id="1:200">
                            {t("cubicaje.step_4.subItem_1.title")}{" "}
                            <CustomToolTip
                              title=""
                              //tooltipText={xData.tooltipPallets}
                              tooltipText={t(
                                "cubicaje.step_4.subItem_3.tooltip"
                              )}
                            >
                              <FontAwesomeIcon
                                icon={faCircleQuestion}
                                className="font-size: 15px"
                              />
                            </CustomToolTip>
                          </p>
                          <p className="kg-ow3" id="1:204">
                            {xData.cargaEstatica}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {isErrorContainerSelected && (
                  <div className="container errorContainer">
                    <img src={circleError} alt="error-icon" />
                    <p className="errorInputValidation">
                      {t("cubicaje.step_4.required")}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </form>
      <div className="mainContainerFooter">
        <div
          className="see_legals"
          onClick={() => setIsLegalModalVisible(true)}
        >
          <p>{t('cubicaje.legal')}</p>
        </div>
        <div
          className="cubicaje-information__next"
          onClick={validateBeforeSubmit}
        >
          <div className="btn-test-next-text">{t("cubicaje.button")}</div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
    </div>
  );
};

export default CubicajeSeccionCuatro;
