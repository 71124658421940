import * as React from 'react';

type IconExporterTestProps = React.SVGProps<SVGSVGElement>;

export const IconExporterTest: React.FC<IconExporterTestProps> = (props) => (
	<svg
		width={18}
		height={16}
		viewBox="0 0 18 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<rect
			x={0.5}
			y={0.842041}
			width={17}
			height={14.3158}
			rx={1.78947}
		/>
		<path
			d="M6.41002 4.11414C6.65677 4.33597 6.67671 4.71232 6.45488 4.95908L4.66032 6.95304C4.55065 7.07517 4.39612 7.14745 4.23162 7.14994C4.06712 7.15243 3.91009 7.09012 3.79295 6.97547L2.79348 5.97849C2.56168 5.7442 2.56168 5.36535 2.79348 5.13106C3.02527 4.89677 3.40662 4.89677 3.63842 5.13106L4.18925 5.68189L5.56259 4.15651C5.78442 3.90976 6.16077 3.88982 6.40753 4.11164L6.41002 4.11414ZM8.20209 5.55477C8.20209 5.11361 8.55851 4.75719 8.99967 4.75719H14.5828C15.0239 4.75719 15.3803 5.11361 15.3803 5.55477C15.3803 5.99594 15.0239 6.35236 14.5828 6.35236H8.99967C8.55851 6.35236 8.20209 5.99594 8.20209 5.55477ZM8.09283 10.8464C8.09283 10.4052 8.44925 10.0488 8.89041 10.0488H14.5828C15.0239 10.0488 15.3803 10.4052 15.3803 10.8464C15.3803 11.2876 15.0239 11.644 14.5828 11.644H8.89041C8.44925 11.644 8.09283 11.2876 8.09283 10.8464ZM4.45759 9.65002C4.77489 9.65002 5.07919 9.77607 5.30356 10.0004C5.52792 10.2248 5.65397 10.5291 5.65397 10.8464C5.65397 11.1637 5.52792 11.468 5.30356 11.6924C5.07919 11.9167 4.77489 12.0428 4.45759 12.0428C4.14029 12.0428 3.83599 11.9167 3.61163 11.6924C3.38726 11.468 3.26121 11.1637 3.26121 10.8464C3.26121 10.5291 3.38726 10.2248 3.61163 10.0004C3.83599 9.77607 4.14029 9.65002 4.45759 9.65002Z"
			fill="#D4D8DB"
		/>
	</svg>
);
// export default IconExporterTest;
