import * as React from 'react';

type IconPreferenciasProps = React.SVGProps<SVGSVGElement>;

export const IconPreferencias: React.FC<IconPreferenciasProps> = (props) => (
	<svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 83">
      <rect
        id="Rectangle 78"
        y={8.88867}
        width={7.11111}
        height={7.11111}
        rx={2}
        fill="#425563"
      />
      <rect
        id="Rectangle 79"
        x={8.88892}
        y={8.88867}
        width={7.11111}
        height={7.11111}
        rx={2}
        fill="#425563"
      />
      <rect
        id="Rectangle 81"
        x={8.88892}
        width={7.11111}
        height={7.11111}
        rx={2}
        fill="#425563"
      />
      <path
        id="Vector"
        d="M6.75658 0.988956C7.19621 1.38418 7.23173 2.05473 6.83651 2.49437L3.63918 6.04695C3.44379 6.26455 3.16846 6.39333 2.87538 6.39777C2.58229 6.40221 2.30252 6.29119 2.09381 6.08692L0.313072 4.31063C-0.104357 3.8932 -0.104357 3.21821 0.313072 2.80522C0.730501 2.39223 1.40549 2.38779 1.81848 2.80522L2.79988 3.78662L5.24673 1.06889C5.64195 0.629257 6.3125 0.593731 6.75214 0.988956H6.75658Z"
        fill="#425563"
      />
    </g>
  </svg>
);


