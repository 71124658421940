import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cubicaje from '../../components/Cubicaje/Cubicaje';
//import VideoComponent from "../../core/Video/VideoAnimation";
import VideoComponent from '../../core/Video/VideoAnimation_cubicaje';
import AnimationCubicaje from '../../components/Cubicaje/AnimationCubicaje';
import './cubicaje.css';
import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const CubicajePage: React.FC = () => {
	const { t } = useTranslation();
	const [showVideoComponent, setShowVideoComponent] = useState(false);
	const [imgPerson, setimgPerson] = useState(true);
	const [imgUrltoShow, setImgUrltoShow] = useState<string | null>(null);
	const [showTitle, setShowTitle] = useState(true);

	const handleStartButtonClick = () => {
		setShowVideoComponent(true);
	};

	const handleCubicajeSelected = (
		varImg: boolean | ((prevState: boolean) => boolean),
		imageToShow: string,
	) => {
		setimgPerson(false);
		setImgUrltoShow(imageToShow);
	};

	function onShowtitle(arg0: boolean) {
		setShowTitle(arg0);
	}

	useEffect(() => {}, [imgUrltoShow]);

	return (
		<div className="">
			<div className="cubicaje-information__container-page">
				<div className="cubicaje-information__header">
					<div className="cubicaje-information__principal-title">
						{showTitle && (
							<div>
								<span className="cubicaje-information__principal-title-text">
									{t('cubicaje.header.title')}
								</span>
								<CustomToolTip
									title=""
									tooltipText={t('cubicaje.header.tooltip')}>
									<FontAwesomeIcon
										icon={faCircleQuestion}
										className="font-size: 15px"
									/>
								</CustomToolTip>
							</div>
						)}
					</div>
				</div>
				<div className="exporter-test-information__container">
					<div className="cubicaje-information_row">
						<div className="cubicaje-form-container">
							<Cubicaje
								onShowtitle={onShowtitle}
								onCubicajeSelected={handleCubicajeSelected}
							/>
						</div>
						<div className="col-4 cubicaje-information__col-4 col_sticky">
							{showVideoComponent ? (
								<div className="stickyVideo">
									<VideoComponent
										showSrcImage={imgPerson}
										urlImagen={imgUrltoShow}
									/>
								</div>
							) : (
								<AnimationCubicaje
									onStartButtonClick={handleStartButtonClick}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CubicajePage;
