import React, { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

interface VideoContextType {
    videoName: string;
    setVideoName: Dispatch<SetStateAction<string>>;
  }

const initialVideoContext = {
    videoName: '',
    setVideoName: () => {}
}

export const VideoContext = createContext<VideoContextType>(initialVideoContext)

export const useVideo = () => useContext(VideoContext);

export const VideoProvider = ({ children }: {children: ReactNode}) => {
    const [videoName, setVideoName] = useState('');
  
    return (
        <VideoContext.Provider value={{ videoName, setVideoName }}>
            {children}
        </VideoContext.Provider>
    );
};
