import * as React from 'react';

type IconLogoMobileProps = React.SVGProps<SVGSVGElement>;

export const IconLogoMobile: React.FC<IconLogoMobileProps> = (props) => (
	<svg
    width={19}
    height={40}
    viewBox="0 0 19 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_34_12311)">
      <path
        d="M14.0004 19.9999C11.6581 16.1451 9.28903 12.9194 9.28903 12.9194C9.28903 12.9194 6.9233 16.1451 4.57764 19.9999C6.9233 23.8513 9.28903 27.077 9.28903 27.077C9.28903 27.077 11.6548 23.8513 14.0004 19.9999Z"
        fill="white"
      />
      <path
        d="M14.0003 19.9999C11.658 23.8514 9.28894 27.0771 9.28894 27.0771C9.28894 27.0771 6.92321 23.8514 4.57755 19.9999C2.27874 23.7811 0 28.1679 0 30.7076C0 35.8373 4.15928 39.9966 9.28894 39.9966C14.4186 39.9966 18.5779 35.8373 18.5779 30.7076C18.5779 28.1679 16.2991 23.7811 13.997 19.9966L14.0003 19.9999Z"
        fill="#ADC9B8"
      />
      <path
        d="M9.28894 0C4.15928 0 0 4.15928 0 9.28894C0 11.8287 2.27873 16.2155 4.58089 20C6.92655 16.1452 9.29229 12.9195 9.29229 12.9195C9.29229 12.9195 11.658 16.1452 14.0037 20C16.3025 16.2188 18.5846 11.832 18.5846 9.28894C18.5779 4.15928 14.4186 0 9.28894 0Z"
        fill="#FF5C35"
      />
    </g>
    <defs>
      <clipPath id="clip0_34_12311">
        <rect width={18.5714} height={40} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
// export default IconLogoMobile;


