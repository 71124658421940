import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import registerImage from "../../assets/images/register-img.png";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import "./Register.css";
import { usePostApiUserRegister } from '../../api/interconectar-api'

const Register: React.FC = () => {
  const {t} = useTranslation()
  const [username, setUsername] = useState("");
  const [alias, setAlias] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { mutate: registerUser } = usePostApiUserRegister();

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const handleBlur = (field: string) => {
    switch (field) {
      case "username":
        if (username.length === 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: t('register.username.error'),
          }));
        } else if (!validateEmail(username)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: t('register.username.valid_email'),
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: "",
          }));
        }
        break;
      case "alias":
        if (alias.length < 3) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            alias: t('register.alias.error'),
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            alias: "",
          }));
        }
        break;
      case "password":
        if (password.length < 8) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: t('register.password.error'),
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
        }
        break;
      case "confirmPassword":
        if (password !== confirmPassword) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: t('register.confirm_password.error'),
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "",
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    // Verificar si hay errores en los campos antes de enviar los datos al servidor
    if (Object.values(errors).some((error) => error !== "")) {
      // Si hay errores, no continuar con el registro
      return;
    }

    registerUser({
      data: {
        alias : alias,
        email: username,
        password: password,
        confirmPassword: confirmPassword
      }
    }, {
      onSuccess(data, variables, context) {
        localStorage.setItem("username", username);
        localStorage.setItem("alias", alias);
        navigate('/information', { state: { variation: 'email_register' } });
      },
      onError(error, variables, context) {
        console.log(error, variables, context);
      },
    })
  };

  const validateEmail = (email: string) => {
    // Aquí puedes implementar la validación del formato de correo electrónico
    // Puedes utilizar una expresión regular para verificar el formato
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <div className="register-container">
      <img
        className="register-image"
        src={registerImage}
        alt="register"
      />
      <h2 className="register-title">{t('register.title')}</h2>
      <div>
        <input
          className={`${errors.username ? "txt-error" : username.length > 0 ? "txt-success" : ""} txt-user-rp`}
          placeholder={t('register.username.placeholder')}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onBlur={() => handleBlur("username")}
        />
      </div>
      {errors.username && (
        <div className="error-container">
          <InfoIcon />
          <div className="error-message">{errors.username}</div>
        </div>
      )}
      <div>
        <input
          className={`${errors.alias ? "txt-error" : alias.length > 0 ? "txt-success" : ""} txt-alias-rp`}
          placeholder={t('register.alias.placeholder')}
          type="text"
          value={alias}
          onChange={(e) => setAlias(e.target.value)}
          onBlur={() => handleBlur("alias")}
        />
        {errors.alias && (
          <div className="error-container">
            <InfoIcon />
            <div className="error-message">{errors.alias}</div>
          </div>
        )}
      </div>
      <div className="password-input-container">
        <input
          placeholder={t('register.password.placeholder')}
          className={`${errors.password ? "txt-error" : password.length > 0 ? "txt-success" : ""} txt-pass-rp`}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => handleBlur("password")}
          inputMode="none"
        />
        <div className="toggle-password-icon-container">
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={toggleShowPassword}
            className="toggle-password-icon"
          />
        </div>
      </div>
      {errors.password && (
        <div className="error-container">
          <InfoIcon />
          <div className="error-message">{errors.password}</div>
        </div>
      )}
      <div className="password-input-container">
        <input
          placeholder={t('register.confirm_password.placeholder')}
          className={`${errors.confirmPassword ? "txt-error" : confirmPassword.length > 0 ? "txt-success" : ""} txt-pass-rp`}
          type={showPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={() => handleBlur("confirmPassword")}
          inputMode="none"
        />
        <div className="toggle-password-icon-container">
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={toggleShowPassword}
            className="toggle-password-icon"
          />
        </div>
      </div>
      {errors.confirmPassword && (
        <div className="error-container">
          <InfoIcon />
          <div className="error-message">{errors.confirmPassword}</div>
        </div>
      )}

      <button className="register-btn register-btn-text" onClick={handleButtonClick}>{t('register.button')}</button>
      <div>
        <span className="normal-text">{t('register.login.text')}</span><Link className="txt-recover-password" to="/login">{t('register.login.link')}</Link>
      </div>
    </div>
  );
};

export default Register;
