import * as React from 'react';

type IconCalcularPreciosProps = React.SVGProps<SVGSVGElement>;

export const IconCalcularPrecios: React.FC<IconCalcularPreciosProps> = (props) => (
	<svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2295_55)">
      <path
        d="M14.625 7.3125C14.625 8.92617 14.1012 10.4168 13.2188 11.6262L17.6695 16.0805C18.109 16.5199 18.109 17.2336 17.6695 17.673C17.2301 18.1125 16.5164 18.1125 16.077 17.673L11.6262 13.2188C10.4168 14.1047 8.92617 14.625 7.3125 14.625C3.27305 14.625 0 11.352 0 7.3125C0 3.27305 3.27305 0 7.3125 0C11.352 0 14.625 3.27305 14.625 7.3125ZM8.01562 3.65625C8.01562 3.26953 7.69922 2.95312 7.3125 2.95312C6.92578 2.95312 6.60938 3.26953 6.60938 3.65625V4.14844C6.34219 4.2082 6.075 4.30313 5.82891 4.44727C5.34023 4.73906 4.91836 5.24883 4.92188 5.99062C4.92539 6.7043 5.34375 7.1543 5.79023 7.42149C6.17695 7.65352 6.65859 7.80117 7.0418 7.91367L7.10156 7.93125C7.54453 8.06484 7.86797 8.17031 8.08594 8.30742C8.26523 8.41992 8.28984 8.49727 8.29336 8.5957C8.29688 8.77149 8.23008 8.87695 8.08594 8.96484C7.91016 9.07383 7.63242 9.14063 7.33359 9.13008C6.94336 9.11602 6.57773 8.99297 6.09961 8.83125C6.01875 8.80313 5.93437 8.775 5.84648 8.74688C5.47734 8.62383 5.08008 8.82422 4.95703 9.18984C4.83398 9.55547 5.03438 9.95625 5.4 10.0793C5.4668 10.1004 5.54063 10.125 5.61445 10.1531C5.90625 10.2551 6.24375 10.3711 6.60586 10.4484V10.9688C6.60586 11.3555 6.92227 11.6719 7.30898 11.6719C7.6957 11.6719 8.01211 11.3555 8.01211 10.9688V10.4836C8.29336 10.4238 8.57461 10.3254 8.82773 10.1672C9.33047 9.8543 9.71016 9.31992 9.69961 8.58516C9.68906 7.87148 9.28828 7.41094 8.83477 7.12266C8.43047 6.86953 7.92422 6.71484 7.53047 6.59531L7.50586 6.58828C7.05586 6.45117 6.73594 6.35273 6.51094 6.21914C6.32813 6.11016 6.32461 6.04688 6.32461 5.98359C6.32461 5.85352 6.37383 5.75508 6.54258 5.65664C6.73242 5.54414 7.0207 5.47734 7.29844 5.48086C7.63594 5.48438 8.00859 5.5582 8.39531 5.66367C8.77149 5.76211 9.15469 5.54063 9.25664 5.16445C9.35859 4.78828 9.13359 4.40508 8.75742 4.30313C8.52891 4.24336 8.27578 4.18359 8.01562 4.13789V3.65625Z"
        fill="#425563"
      />
    </g>
    <defs>
      <clipPath id="clip0_2295_55">
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
// export default IconCalcularPrecios;


