import React, { useEffect, useState } from "react";
import Preferences from "../../components/Preferences/Preferences";
import PreferencesEditor from "../../components/Preferences/Editor/Preferences-editor";
import "./preferences-page.css";
import PreferencesDetail from "../../components/Preferences/Detail/PreferencesDetail";
import pencil from "../../assets/images/pencil.svg";
import reorder from "../../assets/images/reorder.svg";

const PreferencesPage: React.FC = () => {
  const [mostrarEditor, setMostrarEditor] = useState(false);
  const [preferencesSelected, setPreferencesSelected] = useState<any>([]);

  const toggleEditor = () => {
    setMostrarEditor(!mostrarEditor);
  };

  useEffect(() => {
    getDataFromStorage();
  }, [mostrarEditor]);

  const getDataFromStorage = () => {
    const data: any = window.localStorage.getItem("preferencesSelected");
    setPreferencesSelected(JSON.parse(data));
  };

  return (
    <div className="mod_preferences">
      <div className="mod_preferences__top topMainPreferencesContainer">
        <div className="mod_preferences__header">
          <span className="preferences__principal-title">Preferencias</span>
          <span className="preferences__principal-subtitle">
            Son temas o categorías de tu interés para ver noticias y
            sugerencias, recibir novedades, información de regulaciones y
            mucho más.
          </span>
        </div>
        {preferencesSelected &&
          !mostrarEditor &&
          preferencesSelected?.length > 0 && (
            <div className="buttonsPreferencesActionContainer">
              <div className="preferences__action" onClick={toggleEditor}>
                <img src={pencil} alt="pencil-icon" />
                <p>Editar</p>
              </div>
              <div className="preferences__action">
                <img src={reorder} alt="reorder" />
                <p>Reordenar</p>
              </div>
            </div>
          )}
      </div>
      <div className=" preferences__container-information">
        {preferencesSelected && preferencesSelected?.length > 0 ? (
          <>
            {mostrarEditor ? (
              <PreferencesEditor
                preferencesList={preferencesSelected}
                onHandleBack={toggleEditor}
              />
            ) : (
              <PreferencesDetail
                onEditPreference={toggleEditor}
                preferences={preferencesSelected}
              />
            )}
          </>
        ) : (
          <>
            {mostrarEditor ? (
              <PreferencesEditor
                preferencesList={[]}
                onHandleBack={toggleEditor}
              />
            ) : (
              <Preferences onClickEdit={toggleEditor} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PreferencesPage;
