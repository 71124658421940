import React from "react";
import { useTranslation } from 'react-i18next';
import paper from "../../../assets/images/papper.svg";
import "../generalStyles.css";

const InformeLegal = () => {
  const {t} = useTranslation()
  const width = window.innerWidth;

  return (
    <>
      {width > 1220 ? (
        <div className="container p0 sectionMainContainer">
          <div className="col-6 mt-5">
            {/* <img src={paper} alt="paper-icon" /> */}
            <h4 className="sectionText">{t('services.tab_3.sectionTitle')}</h4>
            <p className="sectionDescription">{t('services.tab_3.sectionDescription')}</p>
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.complianceTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_6')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_7')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_8')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_9')}</p>
              </div>
            </div>
          </div>
          <div className="col-6 pr-5 mt-2">
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.newEURegulatoryFrameworkTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_6')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_7')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_8')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_9')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_10')}</p>
              </div>
            </div>
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.otherTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_6')}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p0 sectionMainContainer">
          <div className="col-6 mt-5">
            {/* <img src={paper} alt="paper-icon" /> */}
            <h4 className="sectionText">{t('services.tab_3.sectionTitle')}</h4>
            <p className="sectionDescription">{t('services.tab_3.sectionDescription')}</p>
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.complianceTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_6')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_7')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_8')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.complianceList.item_9')}</p>
              </div>
            </div>
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.newEURegulatoryFrameworkTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_6')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_7')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_8')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_9')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.newEURegulatoryFrameworkList.item_10')}</p>
              </div>
            </div>
            <div className="rowMainContainer">
              <p className="itemTitle">{t('services.tab_3.otherTitle')}</p>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_1')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_2')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_3')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_4')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_5')}</p>
              </div>
              <div className="subitemTextContainer">
                <div className="itemCircleIcon" />
                <p className="itemText">{t('services.tab_3.otherList.item_6')}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InformeLegal;
