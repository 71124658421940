import * as React from 'react';

type IconCubicajeProps = React.SVGProps<SVGSVGElement>;

export const IconCubicaje: React.FC<IconCubicajeProps> = (props) => (
	<svg
    width={24}
    height={20}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.25664 12.9194C2.71929 12.9194 2.28516 13.3577 2.28516 13.9003C2.28516 14.4428 2.71929 14.8811 3.25664 14.8811H4.22812V16.8427H3.25664C2.71929 16.8427 2.28516 17.281 2.28516 17.8235C2.28516 18.3661 2.71929 18.8044 3.25664 18.8044H5.1996H12H18.8003H20.7433C21.2806 18.8044 21.7148 18.3661 21.7148 17.8235C21.7148 17.281 21.2806 16.8427 20.7433 16.8427H19.7718V14.8811H20.7433C21.2806 14.8811 21.7148 14.4428 21.7148 13.9003C21.7148 13.3577 21.2806 12.9194 20.7433 12.9194H18.8003H12H5.1996H3.25664ZM6.17108 14.8811H11.0285V16.8427H6.17108V14.8811ZM12.9714 14.8811H17.8288V16.8427H12.9714V14.8811Z"
      fill="#425563"
    />
    <g clipPath="url(#clip0_2295_41)">
      <path
        d="M11.1936 2.40845H10.3872C9.85305 2.40845 9.41964 2.85852 9.41964 3.41325V5.75781C9.41964 6.49676 9.99818 7.09755 10.7098 7.09755H13.29C14.0016 7.09755 14.5801 6.49676 14.5801 5.75781V3.41325C14.5801 2.85852 14.1467 2.40845 13.6125 2.40845H12.8062V4.08313C12.8062 4.26734 12.6611 4.41806 12.4837 4.41806H11.5161C11.3387 4.41806 11.1936 4.26734 11.1936 4.08313V2.40845ZM7.48446 7.76742C6.77288 7.76742 6.19434 8.36821 6.19434 9.10716V11.7867C6.19434 12.5256 6.77288 13.1264 7.48446 13.1264H10.7098C11.4214 13.1264 11.9999 12.5256 11.9999 11.7867V9.10716C11.9999 8.36821 11.4214 7.76742 10.7098 7.76742H9.90344V9.4421C9.90344 9.62632 9.7583 9.77704 9.58091 9.77704H8.61332C8.43593 9.77704 8.29079 9.62632 8.29079 9.4421V7.76742H7.48446ZM13.29 13.1264H16.5153C17.2269 13.1264 17.8054 12.5256 17.8054 11.7867V9.10716C17.8054 8.36821 17.2269 7.76742 16.5153 7.76742H15.709V9.4421C15.709 9.62632 15.5639 9.77704 15.3865 9.77704H14.4189C14.2415 9.77704 14.0963 9.62632 14.0963 9.4421V7.76742H13.29C12.9876 7.76742 12.7095 7.87418 12.4897 8.0563C12.5885 8.27401 12.645 8.51684 12.645 8.77223V12.1216C12.645 12.377 12.5885 12.6198 12.4897 12.8375C12.7095 13.0196 12.9876 13.1264 13.29 13.1264Z"
        fill="#425563"
      />
    </g>
    <defs>
      <clipPath id="clip0_2295_41">
        <rect
          width={11.6111}
          height={10.7179}
          fill="white"
          transform="translate(6.19434 2.40845)"
        />
      </clipPath>
    </defs>
  </svg>
);



