// src/context/FormContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FormData {
  id: number;
  productName: string;
  productPrice: string;
  volumeValue: string;
  unidad: string
  tipoDeEnvase?: string; // Optional field
  posicionArancelaria?: string; // Optional field
  countries: string[]; // Optional field for storing selected countries

  // include other form fields as needed
}

interface FormContextType {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const initialFormData: FormData = {
  id: 0,
  productName: '',
  productPrice: '',
  volumeValue: '',
  unidad: 'kg',
  countries: ['']
  // The optional fields do not need to be initialized, they are undefined by default
};

const initialFormContext: FormContextType = {
  formData: initialFormData,
  setFormData: () => {},
};

export const FormContext = createContext<FormContextType>(initialFormContext);

export const useForm = () => useContext(FormContext);

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState(initialFormData);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
