/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import * as DO from './DataOptions';

import './PriceCalculator.css';
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";

const PriceCalculatorStepOne = ({ handleClickOpen, handleContinueClick }:{ handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void  }) => {
    const {t} = useTranslation()
    const {setVideoName} = useVideo()
    const [formOneValues, setFormOneValues] = useState<any>();

    const emptyForm = {
        titleName: '', 
        country: '',
        exchangeRate: '',
        fob: '',
        quantity: '',
        tariffPosition: '',
        valor: ''
    }

    const defaultValues = {
        titleName: formOneValues?.titleName, 
        country: formOneValues?.country,
        exchangeRate: formOneValues?.exchangeRate,
        fob: formOneValues?.fob,
        quantity: formOneValues?.quantity,
        tariffPosition: formOneValues?.tariffPosition,
        valor: formOneValues?.valor
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({

        defaultValues
      });

    const onSubmit = (data: any) => {
        localStorage.setItem('formOne', JSON.stringify(data))
        setFormOneValues(data)
        handleContinueClick()
    }

    useEffect( () => {
        const formOne = localStorage.getItem('formOne') ? JSON.parse(localStorage.getItem('formOne')!) : ''
        if(Object.keys(formOne).length) {
            setFormOneValues(formOne)
        } else {
            reset(emptyForm)
            setFormOneValues(emptyForm)
        }
    },[])

    useEffect(() => {
        if (formOneValues) {
          reset((prev) => ({...prev, ...formOneValues}));
        }
    }, [formOneValues, reset]);

    const countriesByRegion = Object.entries(DO.countries);

    setVideoName('firstVideo')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="exporter-test__read-only">
                <div className='price-calculator__container-main'>
                    <div className="price-calculator___inner">
                        <div className='row price-calculator__row mb-1'>
                            <div className='col-12'>
                                <div className='price-calculator__field-with-icon'>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general"
                                        placeholder={t('price_calculator.step_1.titleName.placeholder')}
                                        value={!!formOneValues?.titleName ? formOneValues?.titleName : null}
                                        {...register('titleName', { required: true })}
                                        onChange={ (e) => { setFormOneValues({...formOneValues, titleName: e.target.value})}}
                                    />
                                    <div style={{ marginLeft: "12px"}}>
                                        <CustomToolTip
                                            title=""
                                            tooltipText={t('price_calculator.step_1.titleName.tooltip')}
                                        >
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            className="font-size: 18px"
                                        />
                                        </CustomToolTip>
                                    </div>
                                </div>

                                {!!errors?.titleName && 
                                    <div className="error-container">
                                        <InfoIcon fill="#F8A0D2" />
                                        <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="price-calculator___inner">
                        <div className=" price-calculator___container">
                            <div className="row">
                                <div className='col-12'>
                                    <span className='price-calculator__container-title'>{t('price_calculator.step_1.subtitle')}</span>
                                </div>
                            </div>

                            <div className='row price-calculator__row mb-4'>
                                <div className='col-12 col-sm-12 col-md-6 '>
                                    <div className='price-calculator__field-with-icon'>

                                        <select
                                            {...register('country', { required: true })}
                                            value={!!formOneValues?.country ? formOneValues?.country : null}
                                            onChange={ (e) => { setFormOneValues({...formOneValues, country: e.target.value})}}
                                            className="price-calc__dropdown-general price-calculator__select-font"
                                            style={{ color: 'var(--gray-300, #BBC2C7)', fontFamily: 'Barlow', fontSize: '15.19px' }}
                                        >
                                            <option value="" selected disabled>{t('price_calculator.step_1.country.placeholder')}</option>
                                            {countriesByRegion.map(([region, countries]) => (
                                            <optgroup key={region} label={region} className='price-calculator__select-font'
                                            >
                                                {countries.map(country => (
                                                <option
                                                    key={country}
                                                    value={country}
                                                    className='price-calculator__select-font'
                                                >
                                                    {country}
                                                </option>
                                                ))}
                                            </optgroup>
                                            ))}
                                        </select>

                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_1.country.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.country && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 '>
                                    <input
                                        {...register('exchangeRate', { required: true })}
                                        value={!!formOneValues?.exchangeRate ? formOneValues?.exchangeRate : ''}
                                        onChange={ e => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormOneValues({...formOneValues, exchangeRate: formatNumber(e.target.value) })
                                            }
                                        }}
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_1.exchangeRate.placeholder')}
                                    />
                                    {!!errors?.exchangeRate && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6 '>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            {...register('tariffPosition', { required: true })}
                                            value={!!formOneValues?.tariffPosition ? formOneValues?.tariffPosition : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormOneValues({...formOneValues, tariffPosition: formatNumber(e.target.value)})
                                                }
                                            }}
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_1.tariffPosition.placeholder')}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={<>{t('price_calculator.step_1.tariffPosition.tooltip_first')} <a target='_blank' rel="noreferrer" href='https://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp' className='price-calc__tootip-link'>{t('price_calculator.step_1.tariffPosition.tooltip_link')}</a> {t('price_calculator.step_1.tariffPosition.tooltip_last')}</>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleQuestion}
                                                    className="font-size: 18px"
                                                />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.tariffPosition && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 '>
                                    <input
                                        {...register('valor', { required: true })}
                                        value={!!formOneValues?.valor ? formOneValues?.valor : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormOneValues({...formOneValues, valor: formatNumber(e.target.value)})
                                            }
                                        }}
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_1.valor.placeholder')}
                                    />

                                    {!!errors?.valor && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                
                            </div>

                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6 '>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            {...register('fob', { required: true })}
                                            value={!!formOneValues?.fob ? formOneValues?.fob : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormOneValues({...formOneValues, fob: formatNumber(e.target.value)})
                                                }
                                            }}
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_1.fob.placeholder')}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_1.fob.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.fob && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            {...register('quantity', { required: true })}
                                            value={!!formOneValues?.quantity ? formOneValues?.quantity : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormOneValues({...formOneValues, quantity: formatNumber(e.target.value)})
                                                }
                                            }}
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_1.quantity.placeholder')}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_1.quantity.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.quantity && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_1.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div className="price-calculator__container-footer">
                        <div
                            className="price-calculator__footer-link"
                            onClick={handleClickOpen}
                        >{t('price_calculator.footer.link')}</div>
                        <button type="submit" className="price-calc__next">
                            <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                            <FontAwesomeIcon icon={faArrowRight} color="black" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PriceCalculatorStepOne