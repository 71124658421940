import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PriceCalculator.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: '#425563',
    borderRadius: '12px',
    maxWidth: '800px'
  },
}));

interface Props {
  open: boolean,
  handleClose: () => void;
}

const ModalLegal: React.FC<Props> = ({handleClose, open}) => {
  const {t} = useTranslation()

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle sx={{ color: "#ECEEEF", m: 0, padding: '16px 20px 0px' }} id="customized-dialog-title">
        {t('price_calculator.modal_legal.title')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent 
        sx={{
          color: '#D4D8DB',
          fontSize: '17px',
          fontFamily: 'Barlow',
          padding: '8px 20px 0 20px'
        }}
      >
        <Typography sx={{ mb: 3}}>
          {t('price_calculator.modal_legal.p_1')}
        </Typography>
        <Typography sx={{ mb: 3}}>
        {t('price_calculator.modal_legal.p_2')} <Link sx={{ color: '#FFA692'}} href="mailto:info@exportopia.eu">info@exportopia.eu</Link>.
        </Typography>
        <Typography sx={{ fontWeight: '600'}}>
        {t('price_calculator.modal_legal.p_3')}
        </Typography>
        <Typography sx={{ mb: 3}}>
          {t('price_calculator.modal_legal.p_4')} <Link sx={{ color: '#FFA692'}} href="mailto:info@exportopia.eu">info@exportopia.eu</Link>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ borderTop: 'solid 1px #2E3C46', padding: '16px 32px'}}>
        <Button
          autoFocus 
          onClick={handleClose}
          sx={{
            backgroundColor: '#97A2A9',
            color: '#1A2228',
            fontSize: '13px',
            fontFamily: 'Barlow',
            textTransform: 'none',
            borderRadius: '20px',
            fontWeight: '600',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#97A2A9',
            }
          }}
        >
          {t('price_calculator.modal_legal.button')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default ModalLegal;
