import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CubicajeSeccionUno from "./CubicajeSeccionUno";
import CubicajeSeccionDos from "./CubicajeSeccionDos";
import CubicajeSeccionTres from "./CubicajeSeccionTres";
import CubicajeResumen from "./CubicajeResumen";
import CubicajeSeccionCuatro from "./CubicajeSeccionCuatro";
import euroPalet from "../../assets/videos/cubicaje/45.png";
import standardPalet from "../../assets/videos/cubicaje/standard pallet 45.png";
import europalet360 from "../../assets/videos/cubicaje/europallet360.gif";
import standardpalet360 from "../../assets/videos/cubicaje/Standard-pallet-360.gif";
//import containerDry from "../../assets/videos/cubicaje/20ft.gif";
import containerDry from "../../assets/videos/cubicaje/20ftcarga.gif";
import ModalLegal from "./ModalLegal";
interface Tab {
  title: string;
  contentTitle: string;
  id: Number;
  contentRows: Pallet[];
  contenedores: Contenedor[];
}

export interface Pallet {
  titlePallet: string;
  index: number;
  anchoLargo: string;
  ancho: number;
  alto: number;
  largo: number;
  pesoPallet: string;
  unidadMedida: string;
  unidadPeso: string;
  cargaDinamica: number;
  cargaEstatica: string;
  pesoPalletUnidad: number;
  nota: string;
  europa: boolean;
  tooltip: string;
  imagen: string;
  aprov: number;
  allowedWeight: number;
}

export interface Contenedor {
  titlePallet: string;
  index: number;
  anchoLargo: string;
  pesoContenedor: number;
  pesoMedida: string;
  cargaDinamica: string;
  cargaEstatica: string;
  puertasAbiertasAlto: number;
  imagen: string;
  tooltipPallets: string;
  tooltip: string;
  internoLargo: number;
  allowedWeight?: number;
  palletIdName: string;
  standardWeight: number;
  europaletWeight: number;
}

export interface IFormData {
  FilasMaximoPeso: string;
  altoStep2: string;
  pesoStep2: string;
  newCalculoPesoFilasMax: string;
  largoStep2: string;
  KFilaMaxAltura: string;
  anchoStep2: string;
  PesoFilaCajas: string;
  kPallet: string;
  KcajasXFila: string;
  palletSelected: number;
  palletData: any;
  contenedor: any;
  referenceName: string;
}

interface Props {
  onCubicajeSelected: (varImg: boolean, tabStep: string) => void;
  onShowtitle: (itemshow: boolean) => void;
}

const Cubicaje: React.FC<Props> = ({ onCubicajeSelected, onShowtitle }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [summaryTab, setSummaryTab] = useState(false);
  const [statusSecondStep, setStatusSecondStep] = useState(false);
  const [isLegalModalVisible, setIsLegalModalVisible] =
    useState<boolean>(false);
  const [tabs] = useState<Tab[]>([
    {
      title: t("cubicaje.step_1.name"),
      id: 0,
      contentTitle: "",
      contentRows: [
        {
          titlePallet: t("cubicaje.step_1.europalet.title"),
          index: 0,
          anchoLargo: "800x1200x144mm",
          ancho: 800,
          largo: 1200,
          alto: 144,
          unidadMedida: "mm",
          unidadPeso: "kg",
          pesoPallet: "27kg",
          cargaDinamica: 1000,
          pesoPalletUnidad: 27,
          cargaEstatica: "4000kg",
          allowedWeight: 4000,
          //nota: "Es el mas usado en Europa debido a las dimensiones de las cajas de los remolques.",
          nota: "",
          europa: true,
          tooltip: t("cubicaje.step_1.europalet.tooltip"),
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/Europalet.JPG",
          aprov: 5,
        },
        {
          titlePallet: t("cubicaje.step_1.americano.title"),
          index: 1,
          anchoLargo: "1000x1200x145mm",
          ancho: 1000,
          largo: 1200,
          alto: 145,
          unidadMedida: "mm",
          unidadPeso: "kg",
          pesoPallet: "27kg",
          pesoPalletUnidad: 27,
          cargaDinamica: 1000,
          cargaEstatica: "2500kg",
          allowedWeight: 2500,
          nota: "",
          europa: false,
          //tooltip:
          //  "Certificado según estándares EPAL y UIC, recomendada para reducir costos, optimizar el apilamiento de cajas y asegurar una logística eficiente. Favorece la sostenibilidad con reutilización  reciclaje.",
          tooltip: "",
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/Pallet%20arlog.jpeg",
          aprov: 5,
        },
      ],
      contenedores: [
        {
          titlePallet: t("cubicaje.step_4.containers.item_1.title"),
          palletIdName: "Contenedor Seco 20 (Dry-Van)",
          index: 0,
          anchoLargo: "2438x6058x2591mm",
          pesoContenedor: 2250,
          pesoMedida: "kg",
          cargaDinamica: "28240kg",
          cargaEstatica: "28240kg",
          puertasAbiertasAlto: 2290,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/DRY_VAN40.jpg",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_1.tooltip"),
          internoLargo: 5900,
          europaletWeight: 11,
          standardWeight: 10
        },
        {
          titlePallet: t("cubicaje.step_4.containers.item_2.title"),
          palletIdName: "Contenedor Seco 40 (Dry-Van)",
          index: 1,
          anchoLargo: "2438x12192x2591mm",
          pesoContenedor: 3630,
          pesoMedida: "kg",
          cargaDinamica: "26850kg",
          cargaEstatica: "26850kg",
          puertasAbiertasAlto: 2290,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/REFER40.jpg",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_2.tooltip"),
          internoLargo: 12030,
          europaletWeight: 25,
          standardWeight: 21
        },
        {
          titlePallet: t("cubicaje.step_4.containers.item_3.title"),
          palletIdName: "Contenedor Seco 40 High Cube (Dry-Van)",
          index: 2,
          anchoLargo: "2438x12192x2896mm",
          pesoContenedor: 3800,
          pesoMedida: "kg",
          cargaDinamica: "26600kg",
          cargaEstatica: "26600kg",
          puertasAbiertasAlto: 2595,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/DRY_VAN40.jpg",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_3.tooltip"),
          internoLargo: 12030,
          europaletWeight: 25,
          standardWeight: 21
        },
        {
          //ALEX Este no va, debe ir el Contenedor Seco 20' High Cube
          titlePallet: t("cubicaje.step_4.containers.item_4.title"),
          index: 3,
          anchoLargo: "2438x6058x2896mm",
          palletIdName: "Contenedor Seco 20 High Cube (Dry-Van)",
          pesoContenedor: 3170,
          pesoMedida: "kg",
          cargaDinamica: "27280kg",
          cargaEstatica: "27280kg",
          puertasAbiertasAlto: 2494,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/Imagen%20del%20contenedor.png",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_4.tooltip"),
          internoLargo: 5898,
          europaletWeight: 11,
          standardWeight: 10
        },
        {
          titlePallet: t("cubicaje.step_4.containers.item_5.title"),
          palletIdName: "Contenedor Refrigerado 40 (Reefer)",
          index: 4,
          anchoLargo: "2438x12192x2591mm",
          pesoContenedor: 4500,
          pesoMedida: "kg",
          cargaDinamica: "30400kg",
          cargaEstatica: "30400kg",
          puertasAbiertasAlto: 2200,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/REFER40.jpg",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_5.tooltip"),
          internoLargo: 11575,
          europaletWeight: 23,
          standardWeight: 20
        },
        {
          titlePallet: t("cubicaje.step_4.containers.item_6.title"),
          palletIdName: "Contenedor Refrigerado 40 High Cube (Reefer)",
          index: 5,
          anchoLargo: "2438x12192x2895mm",
          pesoContenedor: 5200,
          pesoMedida: "kg",
          cargaDinamica: "29250kg",
          cargaEstatica: "29250kg",
          puertasAbiertasAlto: 2435,
          imagen:
            "https://interconectarblob.blob.core.windows.net/paletizado-cubicaje/imagenes/Imagen%20del%20contenedor.png",
          tooltipPallets: "",
          tooltip: t("cubicaje.step_4.containers.item_6.tooltip"),
          internoLargo: 11575,
          europaletWeight: 23,
          standardWeight: 20
        },
      ],
    },
    {
      title: t("cubicaje.step_2.name"),
      id: 1,
      contentTitle: "",
      contentRows: [],
      contenedores: [],
    },
    {
      title: t("cubicaje.step_3.name"),
      id: 2,
      contentTitle: "",
      contentRows: [],
      contenedores: [],
    },
    {
      title: t("cubicaje.step_4.name"),
      id: 3,
      contentTitle: "",
      contentRows: [],
      contenedores: [],
    },
  ]);
  const [palletSelected, setPalletSelected] = useState<any>();
  const [formData, setFormData] = useState<IFormData>({
    FilasMaximoPeso: "",
    altoStep2: "",
    pesoStep2: "",
    newCalculoPesoFilasMax: "",
    largoStep2: "",
    KFilaMaxAltura: "",
    anchoStep2: "",
    PesoFilaCajas: "",
    kPallet: "",
    KcajasXFila: "",
    palletSelected: -1,
    palletData: {},
    contenedor: {},
    referenceName: "",
  });

  const areErrors = useRef<boolean>(false);

  const handleContinueClick = () => {
    setSummaryTab(false);
    onShowtitle(true);
    if (activeTab < tabs.length - 1) {
      if (activeTab === 1) {
        if (!statusSecondStep) {
          switch (palletSelected.titlePallet) {
            case "Europalet":
              onCubicajeSelected(false, europalet360);
              return;
            case "Palet Americano (standard)":
              onCubicajeSelected(false, standardpalet360);
              return;
          }
          return false;
        }
      }
      if (!areErrors.current) {
        setActiveTab(activeTab + 1);
      }
    } else {
      onShowtitle(false);
      setSummaryTab(true);
    }
  };

  function handlePalletSelected(palletItem: Pallet) {
    setPalletSelected(palletItem);
    switch (palletItem.titlePallet) {
      case "Europalet":
        onCubicajeSelected(false, euroPalet);
        return;
      case "Palet Americano (standard)":
        onCubicajeSelected(false, standardPalet);
        return;
    }
  }

  const handleTabClick = (index: number) => {
    if (palletSelected) {
      if (!areErrors.current && activeTab > index) {
        setActiveTab(index);
      }

      if (!areErrors.current) {
        setActiveTab(index);
      }
    }
  };

  function handleSecondStep(statusSecondStep: boolean) {
    setStatusSecondStep(statusSecondStep);
  }

  useEffect(() => {
    if (activeTab === 3) {
      onCubicajeSelected(false, containerDry);
    }
  }, [activeTab]);

  const renderSwitch = (activeTab: Number) => {
    switch (activeTab) {
      case 0:
        return (
          <CubicajeSeccionUno
            palletItems={tabs[0].contentRows}
            onPalletSelected={handlePalletSelected}
            handleContinueClick={handleContinueClick}
            areErrors={areErrors}
            formData={formData}
            setFormData={setFormData}
            setIsLegalModalVisible={setIsLegalModalVisible}
          />
        );
      case 1:
        return (
          <CubicajeSeccionDos
            palletItem={palletSelected}
            handleSecondStep={handleSecondStep}
            handleContinueClick={handleContinueClick}
            areErrors={areErrors}
            formData={formData}
            setFormData={setFormData}
            onCubicajeSelected={onCubicajeSelected}
            setIsLegalModalVisible={setIsLegalModalVisible}
          />
        );
      case 2:
        return (
          <CubicajeSeccionTres
            palletItem={palletSelected}
            handleContinueClick={handleContinueClick}
            formData={formData}
            setFormData={setFormData}
            onCubicajeSelected={onCubicajeSelected}
            setIsLegalModalVisible={setIsLegalModalVisible}
          />
        );
      case 3:
        return (
          <CubicajeSeccionCuatro
            contenedoresArray={tabs[0].contenedores}
            palletItem={palletSelected}
            handleContinueClick={handleContinueClick}
            formData={formData}
            setFormData={setFormData}
            onCubicajeSelected={onCubicajeSelected}
            setIsLegalModalVisible={setIsLegalModalVisible}
          />
        );
    }
  };

  function resetForm() {
    onShowtitle(true);
    setSummaryTab(false);
    setActiveTab(0);

    setFormData({
      FilasMaximoPeso: "",
      altoStep2: "",
      pesoStep2: "",
      newCalculoPesoFilasMax: "",
      largoStep2: "",
      KFilaMaxAltura: "",
      anchoStep2: "",
      PesoFilaCajas: "",
      kPallet: "",
      KcajasXFila: "",
      palletSelected: -1,
      palletData: {},
      contenedor: {},
      referenceName: "",
    });
  }

  useEffect(() => {
    if (activeTab === 0) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {summaryTab ? (
        <div >
          <CubicajeResumen resetFormClick={resetForm} formData={formData} />
        </div>
      ) : (
        <div className="cubicaje-information__container">
          <div className="cubicaje-test__tabs">
            {tabs.map((tab, index) => (
              <div
                key={index}
                className={`tab-test-cubicaje ${
                  activeTab === index ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick(index)}
              >
                <div
                  className={`circle ${
                    activeTab === index ? "circle-active" : ""
                  }`}
                >
                  {index + 1}
                </div>
                <div
                  className={`text-test-cubicaje ${
                    activeTab === index ? "text-test-cubicaje-active" : ""
                  }`}
                >
                  {tab.title}
                </div>
              </div>
            ))}
          </div>
          <div className="cubicaje-information__tab-container exporter-test__tab-container">
            <div className="cubicaje-container_father mb-2">
              {tabs[activeTab].contentTitle}
              {renderSwitch(activeTab)}
            </div>
          </div>
        </div>
      )}
      <ModalLegal
        open={isLegalModalVisible}
        handleClose={() => setIsLegalModalVisible(false)}
      />
    </div>
  );
};

export default Cubicaje;
