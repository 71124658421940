import React from "react";
import Register from "../../components/Register/Register";

const RegisterPage: React.FC = () => {
  return (
    <div>
      <Register />
    </div>
  );
};

export default RegisterPage;
