import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "./servicios.css";
import infoTecnico from "../../assets/images/infoTecnico.svg";
import imgTerServ from "../../assets/images/nosotrosIcono.png";
import EstudioDeMercado from "../../components/Servicios/estudioDeMercado/estudioDeMercado";
import InformeTecnico from "../../components/Servicios/informeTecnico/informeTecnico";
import InformeLegal from "../../components/Servicios/informeLegal/informeLegal";

const ServiciosPage: React.FC = () => {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [
    {
      id: 0,
      text: t('services.tab_1.name'),
    },
    {
      id: 1,
      text: t('services.tab_2.name'),
    },
    {
      id: 2,
      text: t('services.tab_3.name'),
    },
  ];

  return (
    <div>
      <div className="container p0">
        <div className="row servicesMainContainer">
          <div className="col-6 mb-5 terminosServicio-information__principal-title">
            <span>{t('services.title')}</span>
            <p className="mt-5">{t('services.introducctionText')}</p>
          </div>
          <div className="col-5">
            <img src={imgTerServ} alt="" />
          </div>
        </div>
      </div>
      <div className="container p0">
        {tabs.map((item) => (
          <div
            className={item.id === activeTab ? "activeTab" : "inactiveTab"}
            key={item.id}
            onClick={() => setActiveTab(item.id)}
          >
            <p className="tabText">{item.text}</p>
          </div>
        ))}
      </div>
      <div className="container">
        {activeTab === 0 && <EstudioDeMercado />}
        {activeTab === 1 && <InformeTecnico />}
        {activeTab === 2 && <InformeLegal />}
      </div>
    </div>
  );
};

export default ServiciosPage;
