export const validateNumber = (s: string) => {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
}

export const numberRegex = /^[0-9.,]+$/;
export const numberDotRegex = /^\d*\.?\d*$/;
export const validateNumberRegex = /^[0-9]{1,2}([.][0-9]{1,2})?$/;

export const formatNumber = (number: string) => (
    Array.from(number)[0] === '.' 
    ? '0' + number
    : number
)
