import React from "react";
import { useTranslation } from 'react-i18next';
import "./acercaInterconectar.css";
import imgTerServ from "../../assets/images/nosotrosIcono.png";
import medalIcon from "../../assets/images/medalla.svg";
import { clientsList } from "./utils/ourClients";

const AcercaInterconectarPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className="mt-5">
      <div className="container">
        <div className="row termsAndServicesMainContainer">
          <div className="col-lg-6 mb-5 terminosServicio-information__principal-title">
            <span>{t('about.title')}</span>
            <p className="mt-5">{t('about.introductionText')}</p>
          </div>
          <div className="col-lg-5">
            <img src={imgTerServ} alt="" />
          </div>
        </div>
      </div>
      <div className="container terminosServicioContainer">
        <div className="row col-lg-6">
          <div className="col-lg-5">
            <img src={medalIcon} alt="" />
          </div>
          <p className="mt-3 descriptionText">{t('about.descriptionTextPartOne')}</p>
        </div>
      </div>
      <div className="container summaryAboutUsContainer">
        <div className="row">
          <div className="col-lg-6">
            <div className="col-lg-5">
              <img src={medalIcon} alt="" />
            </div>
            <p className="mt-3 descriptionText">{t('about.descriptionTextPartTwo')}</p>
          </div>
          <div className="col-lg-5 summaryDescription">
            <p className="mt-3 summaryText">{t('about.descriptionGreenSquare')}</p>
          </div>
        </div>

      </div>
      <div className="container mt-5 mb-4">
        <div className="row termsAndServicesMainContainer">
          <div className="col-lg-6 terminosServicio-information__principal-title">
            <span>{t('about.customer')}</span>
            <p className="mt-2">{t('about.ourClientsIntroductionText')}</p>
          </div>
        </div>
      </div>
      <div className="container clientsContainer">
        {clientsList.map((item, index) => (
          <div className="col-lg-3 row clientIconItem" key={index}>
            <div className="imageContainer">
              <img src={item} width="auto" alt="clientLogo" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcercaInterconectarPage;
