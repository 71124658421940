import React, { useEffect } from "react";
import "./noticias.css";
import { ReactComponent as LinkSolid } from "../../assets/images/link-solid.svg";
import { ReactComponent as Bookmark } from "../../assets/images/bookmark-regular.svg";
import { useGetApiNews } from "../../api/news.api";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { Button, Stack } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Clipboard = ({ text, label }: { text: string, label: string }) => {
  return (<CopyToClipboard text={text}>
    <button className="clipboard__btn ">
      <LinkSolid className="clipboard__svg" />
      {label}
    </button>
  </CopyToClipboard>
  )
}

const NewLayout = ({ children }: { children: React.ReactNode }) => {
  return (
   
    
      <div className="singlenews" 
      // style={{ padding: "20px" }}
      >
        {children}
    
    </div>
  
  )
}

const NoticiaPage: React.FC = () => {
  const { id } = useParams()
  const { data: dataNews, isLoading, mutate: newsList } = useGetApiNews()
  const newsLink = window.location.href;

  useEffect(() => {
    if (id) {
      newsList({ data: { id: id } })
    }
  }, [id]);

  return (
    <>
      <NewLayout>
        {dataNews?.length &&
          <>
            <div className="singlenews__top" >
              <span className="singlenews__top__left">
                published {dataNews[0].date}
              </span>
              <div className="singlenews__top__right ">
                <a href="#" className="singlenews__top__link">
                  <LinkSolid className="singlenews__top__share " />
                </a>
                <a href="#" className="singlenews__top__link">
                  <Bookmark className="singlenews__top__save " />
                </a>
              </div>
            </div>

            <h2 className="singlenews__title" >
              {dataNews[0].title}
            </h2>

            <div className="singlenews__main " >
              <figure className="singlenews__figure">
              <img
                className="singlenews__img"
                src={dataNews[0].image}
                alt={dataNews[0].title}
              />
              </figure>
            </div>
            <p className="singlenews__body">
              {dataNews[0].summary}
            </p>

            <div className="singlenews__actions">
              <p className="singlenews__actions__text">
                {dataNews[0].source}
              </p>
            

              <div className="singlenews__actions__right " >
                <div className="singlenews__actions__copy">
                  <Clipboard text={newsLink} label={t("news.copyNewsLink")} />
                </div>
                <span className="singlenews__actions__save">
                  <Bookmark className="singlenews__actions__save__svg " />
                  {t("news.saveNews")}
                </span>
              </div>
            </div>

            <div className="singlenews__footer">
              
                <a href="/#" className="singlenews__footer__text">
                  {t("news.preferences")}: {dataNews[0].preferences?.join(", ")}
                </a>
              
            </div>


          </>
        }
      </NewLayout>
    </>
  );
};

export default NoticiaPage;
