import React from "react";
import "react-phone-number-input/style.css";
import { useTranslation } from 'react-i18next';
import pdf from "../../assets/images/file-pdf-solid.png";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './PriceCalculator.css';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';
import { Link } from 'react-router-dom';

interface Props {
  resetFormClick: () => void;
}

const PriceCalculatorResumen: React.FC<Props> = ({ resetFormClick }) => {
  const {t} = useTranslation()

  const formOne = JSON.parse(window.localStorage.getItem("formOne")!)
  const formTwo = JSON.parse(window.localStorage.getItem("formTwo")!)
  const formThree = JSON.parse(window.localStorage.getItem("formThree")!)
  const formFour = JSON.parse(window.localStorage.getItem("formFour")!)

  //ALEX: invertí el orden de las deficiniones eur y fob y multipliqué fob * eur
  const eur = parseFloat(formOne.exchangeRate)
  const fob = parseFloat(formOne.fob) * eur //ALEX
  
  const fi = parseFloat(formTwo.internationalFreightValue)
  const un = parseFloat(formOne.quantity)
  const net = parseFloat(formOne.valor)
  const taxPercentage = parseFloat(formThree.adValoremTax)
  const taxValue = parseFloat(formThree.weightTax)
  const gp = parseFloat(formThree.valuePortExpenses)
  const ga = parseFloat(formThree.valueCustomsExpenses)
  const fe = parseFloat(formThree.transportationCost)
  const ui = parseFloat(formFour.wholesalerProfit)
  const fei = parseFloat(formFour.transportationCost)
  const unf = parseFloat(formFour.quantitymerchandise)
  const um = parseFloat(formFour.retailerProfit)

  //ALEX: saqué la multiplicación del fob * eur ya que fob viene multiplicado por eur
  const cif = fob + fi * eur / un

  //ALEX: ajusté los dividendos 100 por que faltaba alguno
  const ddp = (cif * (1 + taxPercentage / 100)) + (taxValue * net / 100) + (gp + ga + fe) / un

  //ALEX: ajusté los dividendos 100 por que faltaba alguno
  const pue = ((ddp * (1 + ui / 100)) + (fei / unf)) * (1 + um / 100)
 
  const downloadPdf = () => {
    const input = document.getElementById('divToPrint'); // The ID of the div you want to download as PDF
    html2canvas(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('priceCalculator.pdf');
      });
  };

  let euro = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
  });
  
  return (
    <div id="divToPrint" className='price-calculator_resume'>
      <form>
        <div className="mb-5">
          <div className=" price-calculator_resume_container">
            {/* Primera file */}
            <div className="row text-light text-sm-left price-calculator_resume_container_row">
              <div className="col-sm-12">
                <div className="price-calculator_resume-title">
                  <span>{t('price_calculator.resume.title')}</span>
                  <CustomToolTip
                      title=""
                      tooltipText={<> {t('price_calculator.resume.title_tooltip')} <a href='mailto:info@exportopia.eu' className='price-calc__tootip-link'>info@exportopia.eu</a></>}
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="font-size: 15px"
                    />
                  </CustomToolTip>
                </div>
                <div className="price-calculator_resume-subtitle">
                  <span>{formOne.titleName}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="price-calculator_resume_container-card">
            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(fob)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.fob')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(cif)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.cif')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card size-sm">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(ddp)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.ddp')}</p>
              </div>
            </div>

            <div className="price-calculator_resume_card">
              <div className="card-body">
                <h5 className="price-calculator_resume_card-title">{euro.format(pue)}</h5>
                <p className="price-calculator_resume_card-text">{t('price_calculator.resume.total')}</p>
              </div>
            </div>
          </div>

          <div className="price-calculator_resume_card-link">
            <Link to="/pricecomparator">{t('price_calculator.resume.compare')}</Link>
          </div>
          
          {/* Primera file */}
          <div className="row mb-3 mt-4 text-light price-calculator__result">
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_1')}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_1')}: {formOne.valor} {t('price_calculator.resume.section_1.unit')}
                </p>
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_2')}: {formOne.country}
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                  {t('price_calculator.resume.section_1.title_3')}: {formOne.valor * formOne.quantity} {formOne.unit}
                </p>
              </div>
            </div>
          </div>
          {/* Segunda file */}
          <div className="row mb-3 text-light price-calculator__result">
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_2')}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_2.title_1')}: {formTwo.conveyance}
                </p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_2.title_2')}: {formThree.country}
                </p>
              </div>
            </div>
          </div>
          {/* Tercera file */}
          <div className="row mb-2 text-light price-calculator__result">
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_title">{t('price_calculator.resume.section_3')}</p>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card-body">
                <p className="price-calculator_resume-footer_text">
                {t('price_calculator.resume.section_3.title_1')}: {formFour.marketingSegment}
                </p>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>

        <div className="row price-calculator_resume-footer">
          <div className="col-sm-5 botonesCubicajeContainer">
            <div className="botones-price-calculator" onClick={downloadPdf}>
              <img className="file-pdf-solid-kDj" src={pdf} alt={t('price_calculator.resume.download_form')} />
              <div className="buttonsCubicaje">{t('price_calculator.resume.download')}</div>
            </div>
          </div>
          <div className="col-sm-auto price-calculator_resume-footer_container" onClick={resetFormClick}>
            <div className="price-calculator_resume-footer_btn">{t('price_calculator.resume.new')}</div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PriceCalculatorResumen;
