import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import "./Cubicaje.css";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import circleError from "../../assets/images/circle-exclamation-error.svg";
import {
  faArrowRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { IFormData, Pallet } from "./Cubicaje";

interface Props {
  onPalletSelected: (palletItem: Pallet) => void;
  palletItems: Pallet[];
  handleContinueClick: () => void;
  areErrors: React.MutableRefObject<boolean>;
  setFormData: Function;
  formData: IFormData;
  setIsLegalModalVisible: Function;
}

type IsErrorsVisible = {
  isErrorPalletSelected: boolean;
  isErrorReferenceName: boolean;
};

const CubicajeSeccionUno: React.FC<Props> = ({
  onPalletSelected,
  palletItems,
  handleContinueClick,
  areErrors,
  setFormData,
  formData,
  setIsLegalModalVisible,
}) => {
  const {t} = useTranslation()
  const [idpalletSelected, setIdPalletSelected] = useState<any>(null);
  const [isErrorsVisible, setIsErrorsVisible] = useState<IsErrorsVisible>({
    isErrorPalletSelected: false,
    isErrorReferenceName: false,
  });

  const validateInformation = () => {
    const { palletSelected, referenceName } = formData;
    if (palletSelected !== -1 && referenceName !== "") {
      areErrors.current = false;
      handleContinueClick();
    } else {
      areErrors.current = true;
      setIsErrorsVisible({
        isErrorPalletSelected: palletSelected === -1,
        isErrorReferenceName: referenceName === "",
      });
    }
  };

  const onHandlerChangeReferenceName = (event: any) => {
    const referenceName = event.target.value;
    setFormData((formData: FormData) => ({
      ...formData,
      referenceName,
    }));
  };

  return (
    <div>
      <div className="row mb-1">
        <div className="cubicaje-fieldset">
          <input
            type="text"
            className="cubicaje-information__textbox-general"
            placeholder={t('cubicaje.step_1.nameReference.placeholder')}
            onChange={onHandlerChangeReferenceName}
          />{" "}
          <div className="cubicaje-fieldset-tooltip" >
            <CustomToolTip
              title=""
              tooltipText={t('cubicaje.step_1.nameReference.tooltip')}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="font-size: 15px"
              />
            </CustomToolTip>
          </div>
        </div>
      </div>
      {isErrorsVisible.isErrorReferenceName && (
        <div className="container errorContainer">
          <img src={circleError} alt="error-icon" />
          <p className="errorInputValidation">{t('cubicaje.form.require')}</p>
        </div>
      )}
      <div className=" mb-2">{t('cubicaje.step_1.subtitle')}</div>

      <div className="auto-group-6qah-KNM">
        <p className="conteoPalletsPestanaUno">
          {palletItems.length} {t('cubicaje.step_1.available')}.{" "}
          <span>
          {t('cubicaje.step_1.availableLink')}{" "}
            <CustomToolTip
              title=""
              tooltipText={t('cubicaje.step_1.availableLink.tooltip')}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="font-size: 15px"
              />
            </CustomToolTip>
          </span>
        </p>
        {palletItems.map((xData, questionIndex) => (
          <div
            className="typePallet"
            key={questionIndex}
            onClick={() => {
              setIdPalletSelected(questionIndex);
              onPalletSelected(xData);
              setFormData((prevData: IFormData) => ({
                ...prevData,
                palletSelected: questionIndex,
                palletData: xData,
              }));
            }}
          >
            <div
              className={
                idpalletSelected === questionIndex
                  ? `auto-group-pspf-ddw palletSelected`
                  : `auto-group-pspf-ddw`
              }
            >
              <div className="auto-group-pund-wed">
                <p className="europallet-U8m">{xData.titlePallet}</p>
                <div className="auto-group-emux-CaZ">
                  {" "}
                  <CustomToolTip
                    title=""
                    tooltipText={t('cubicaje.step_1.europalet.tooltip')}
                  >
                    <FontAwesomeIcon
                      icon={faCircleQuestion}
                      className="font-size: 15px"
                    />
                  </CustomToolTip>
                  {xData.europa ? (
                    <div className="auto-group-6qav-prq ml-5 rounded">
                      {t('cubicaje.step_1.recomended')}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* //---> Check box */}
                </div>
              </div>
              <div className="group-84-cXo">
                <div className="auto-group-6spv-9Gq">
                  <p className="x800x145mm-ToK">{xData.anchoLargo}</p>
                  <p className="ancho-x-largo-x-alto-mJD">
                    {t('cubicaje.step_1.subItem_1.title')}{" "}
                  </p>
                </div>
                <div className="auto-group-faqu-S9T">
                  <p className="peso-x-pallet-nUD">{t('cubicaje.step_1.subItem_2.title')} </p>
                  <p className="kg-4wX">{xData.pesoPallet}</p>
                </div>
                <div className="auto-group-pxb3-aus">
                  <p className="carga-dinmica-vyj">
                  {t('cubicaje.step_1.subItem_3.title')}{" "}
                    <CustomToolTip
                      title=""
                      tooltipText={t('cubicaje.step_1.subItem_3.tooltip')}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        className="font-size: 15px"
                      />
                    </CustomToolTip>
                  </p>
                  <p className="kg-RvV">{xData.cargaDinamica}</p>
                </div>
                <div className="auto-group-2gox-MZF">
                  <p className="carga-esttica-7HX">
                  {t('cubicaje.step_1.subItem_4.title')}{" "}
                    <CustomToolTip
                      title=""
                      tooltipText={t('cubicaje.step_1.subItem_4.tooltip')}
                    >
                      <FontAwesomeIcon
                        icon={faCircleQuestion}
                        className="font-size: 15px"
                      />
                    </CustomToolTip>
                  </p>

                  <p className="kg-ow3">{xData.cargaEstatica}</p>
                </div>
              </div>
              <p className="es-el-ms-usado-en-europa-debido-a-las-dimensiones-de-las-cajas-de-los-remolques-XMF">
                {xData.nota}
              </p>
            </div>
          </div>
        ))}
      </div>
      {isErrorsVisible.isErrorPalletSelected && (
        <div className="container errorContainer">
          <img src={circleError} alt="error-icon" />
          <p className="errorInputValidation">{t('cubicaje.step_1.error')}</p>
        </div>
      )}
      <div className="mainContainerFooter">
        <div
          className="see_legals"
          onClick={() => setIsLegalModalVisible(true)}
        >
          <p>{t('cubicaje.legal')}</p>
        </div>
        <div
          className="cubicaje-information__next"
          onClick={validateInformation}
        >
          <div className="btn-test-next-text">{t('cubicaje.button')}</div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
    </div>
  );
};

export default CubicajeSeccionUno;
