import React, { useState } from "react";
import PriceCalculator from "../../components/PriceCalculator/PriceCalculator";
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import InitialAnimationPriceCalculator from "../../components/PriceCalculator/InitialAnimationPriceCalculator";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "./price-calculator.css";
import ModalLegal from "../../components/PriceCalculator/ModalLegal";
import FirstVideo from "../../assets/videos/calcu_precios/video1.mp4"
import SecondAvionVideo from "../../assets/videos/calcu_precios/video2_avion.mp4"
import SecondBarcoVideo from "../../assets/videos/calcu_precios/video2_barco.mp4"
import ThirdAvionVideo from "../../assets/videos/calcu_precios/video3_avion.mp4"
import ThirdBarcoVideo from "../../assets/videos/calcu_precios/video3_barco.mp4"
import FourthDeliveryVideo from "../../assets/videos/calcu_precios/video4_delivery.mp4"
import FourthRetailVideo from "../../assets/videos/calcu_precios/video4_retail.mp4"
import { useVideo } from "../../context/VideoContext";

const PriceCalculatorPage: React.FC = () => {
  const {videoName} = useVideo()
  const {t} = useTranslation()
  const [showVideoComponent, setShowVideoComponent] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleStartButtonClick = () => {
    setShowVideoComponent(true);
  };

  function onShowtitle(arg0: boolean) {
    setShowTitle(arg0);
  }

  return (
    <div className="">
      <div className="price-calc__container-page">
        <div className="price-calc__container">
          <div className="price-calc__header">
            {showTitle ? (
              <>
                <div className="price-calc__principal-title">
                  <span>{t('price_calculator.header.title')}</span>
                  <CustomToolTip
                    title=""
                    tooltipText={
                      <>
                        <span>
                          {t('price_calculator.header.tooltip')}{" "}
                          <button
                            onClick={handleClickOpen}
                            className="price-calc__tootip-link"
                          >
                            {t('price_calculator.header.button')}
                          </button>
                        </span>
                      </>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="font-size: 20px"
                    />
                  </CustomToolTip>
                </div>
                <div className="price-calc__principal-sub-title">
                  <p>{t('price_calculator.header.subtitle')}</p>
                </div>
              </>
            ) : null}
          </div>
          <div className="price-calc__row">
            <div className="price-calc__flex">
              <PriceCalculator
                onShowtitle={onShowtitle}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                showVideoComponent={showVideoComponent}
              />
            </div>
            <div className="price-calc__fixed ">
              {showVideoComponent ? (
                !!videoName &&
                <div className="calc__fixed__video">
                <ReactPlayer
                  url={
                    videoName === "firstVideo" 
                      ? FirstVideo
                      : videoName === "secondAvionVideo"
                        ? SecondAvionVideo 
                        : videoName === "secondBarcoVideo"
                          ? SecondBarcoVideo
                          : videoName === "thirdBarcoVideo"
                            ? ThirdBarcoVideo
                            : videoName === "thirdAvionVideo"
                              ? ThirdAvionVideo
                              : videoName === "fourthDeliveryVideo"
                                ? FourthDeliveryVideo
                                : videoName === "fourthRetailVideo"
                                  ? FourthRetailVideo
                                  : ''
                  }
                  playing={true}
                  controls={true}
                  loop={false}
                  muted={false}
                  playsinline={true}
                  width='100%'
                  height='100%'
                />
                </div>
              ) : (
                <InitialAnimationPriceCalculator
                  onStartButtonClick={handleStartButtonClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalLegal open={open} handleClose={handleClose} />
    </div>
  );
};

export default PriceCalculatorPage;
