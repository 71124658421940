import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostApiUserRecoverPassword, usePostApiUserResetPassword } from '../../api/interconectar-api';

export function useResetRecoverPasswordActions(): {
  recoverPassword: (emailAlias: string) => void;
  isLoadingRecover: boolean;
  resetPassword: (newPassword: string, newPasswordConfirm: string, hashRecover?: string) => void;
  isLoadingResetPassword: boolean;
} {
    
  const { mutate: recoverPasswordAPI, isLoading: isLoadingRecover } = usePostApiUserRecoverPassword();
  const { mutate: resetPasswordAPI, isLoading: isLoadingResetPassword } = usePostApiUserResetPassword();
  const navigate = useNavigate();
  const recoverPassword = useCallback(
    (emailAlias: string): void => {
        recoverPasswordAPI(
        {
          data: { emailAlias: emailAlias },
        },
        {
          onSuccess(data, variables, context) {
              navigate("/information", { state: { variation: "recover_password_ok" } });
          },
          onError(error, variables, context) {
              navigate("/information", { state: { variation: "error" } });
          },
        }
      )
    },
    [recoverPasswordAPI, navigate]
  );


  const resetPassword = useCallback(
    (newPassword: string, newPasswordConfirm: string, hashRecover?: string): void => {
        resetPasswordAPI(
        {
          data: { newPassword: newPassword, newPasswordConfirm: newPasswordConfirm, hashRecover },
        },
        {
          onSuccess(data, variables, context) {
              navigate("/information", { state: { variation: "reset_password_ok" } });
          },
          onError(error, variables, context) {
              navigate("/information", { state: { variation: "error" } });
          },
        }
      )
    },
    [resetPasswordAPI, navigate]
  );

  return {
    recoverPassword,
    isLoadingRecover,
    resetPassword,
    isLoadingResetPassword
  };
}