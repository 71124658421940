import React from "react";
import TopContent from "../../../components/FinalReport/topContent/topContent";
import OurTools from "../../../components/FinalReport/ourTools/ourTools";
import OurServices from "../../../components/FinalReport/OurServicesReport/OurServicesReport";
import { useLocation, useNavigate } from "react-router-dom";

//ALEX: acá se define el report del test level y sublevel
export type TExporterCategory = "emergent" | "expert" | "consolidated";
export interface IFinalReportState {
  category: TExporterCategory;
  level: number;
  subLevel: number;
}

const FinalReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { category, level }: IFinalReportState = location.state || {};

  if (!category && !level) {
    navigate(-1);
  }

  const exporter: Record<TExporterCategory, string> = {
    expert: "finalReport.category.expert",
    consolidated: "finalReport.category.consolidated",
    emergent: "finalReport.category.emergent",
  };

  const actualLevel = {
    name: exporter[category],
    level: level,
    sublevel: 3,
  };

  return (
    <div>
      <TopContent {...actualLevel} />
      <OurTools />
      <OurServices {...actualLevel} />
    </div>
  );
};

export default FinalReport;
