import React from "react";
import "./Preferences.css";
import preferencias from "../../assets/images/preferencias.svg";
import edit_image from "../../assets/images/edit_image.svg";
import arrows_image from "../../assets/images/arrows_image.svg";

const Preferences = ({ onClickEdit }: { onClickEdit: () => void }) => {
  return (
    <div className="mainPreferencesContainer">
      <div className="preferences__principal-text">
        <img
          src={preferencias}
          className="preferences__title-image"
          alt="preferencias"
        />
        <span className="preferences__title">
          Personaliza tu experiencia seleccionando tus preferencias
        </span>
        <span className="preferences__subtitle">
          Recuerda que puedes personalizar tus preferencias en cualquier momento
          desde la opción <img src={edit_image} alt="editar"></img>{" "}
          <span className="preferences__title-image">Editar</span> y ajustar el
          orden en que aparecen desde{" "}
          <img src={arrows_image} alt="Reordenar"></img>
          <span className="preferences__title-image">Reordenar</span>
        </span>
      </div>
      <div className="preferences__centered-button">
        <button onClick={onClickEdit} className="preferences__btn-text">
          Elegir preferencias
        </button>
      </div>
    </div>
  );
};

export default Preferences;
