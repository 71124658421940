/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import * as DO from './DataOptions';

import './PriceCalculator.css';
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";

const PriceCalculatorStepThree = ({ handleClickOpen, handleContinueClick }:{  handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void }) => {
    const {t} = useTranslation()
    const {setVideoName} = useVideo()
    const [formThreeValues, setFormThreeValues] = useState<any>();

    const formTwo = JSON.parse(window.localStorage.getItem("formTwo")!)
    
    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
      } = useForm();

    const onSubmit = (data: any) => {
        localStorage.setItem('formThree', JSON.stringify(data))
        setFormThreeValues(data)
        handleContinueClick()
    }

    useEffect( () => {
        const formThree = localStorage.getItem('formThree') ? JSON.parse(localStorage.getItem('formThree')!) : ''
        if(Object.keys(formThree).length) setFormThreeValues(formThree)
    },[])

    useEffect(() => {
        if (formThreeValues) {
          reset((prev) => ({...prev, ...formThreeValues}));
        }
    }, [formThreeValues, reset]);

    const destinationCountry = Object.entries(DO.destination_country);

    useEffect(()=>{
        formTwo.conveyance === 'maritimo' || formTwo.conveyance === 'multimodal'
        ? setVideoName('thirdBarcoVideo')
        : formTwo.conveyance === 'aereo'
        ? setVideoName('thirdAvionVideo')
        : setVideoName('')
    },[])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="exporter-test__read-only">
                <div className='price-calculator__container-main'>
                    <div className="price-calculator___inner">
                        <div className="container price-calculator___container">
                            <div className="row">
                                <div className='col-12'>
                                    <span className='price-calculator__container-title'>{t('price_calculator.step_3.subtitle')}</span>
                                </div>
                            </div>

                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_3.valuePortExpenses.placeholder')}
                                            {...register('valuePortExpenses', { required: true })}
                                            value={!!formThreeValues?.valuePortExpenses ? formThreeValues?.valuePortExpenses : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, valuePortExpenses: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.valuePortExpenses.tooltip')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faCircleQuestion}
                                                    className="font-size: 18px"
                                                />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.valuePortExpenses && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_3.valueCustomsExpenses.placeholder')}
                                            value={!!formThreeValues?.valueCustomsExpenses ? formThreeValues?.valueCustomsExpenses : ''}
                                            {...register('valueCustomsExpenses', { required: true })}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, valueCustomsExpenses: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.valueCustomsExpenses.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.valueCustomsExpenses && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className='row price-calculator__row mb-4'>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <select
                                            {...register('country', { required: true })}
                                            value={!!formThreeValues?.country ? formThreeValues?.country : null}
                                            onChange={ (e) => { setFormThreeValues({...formThreeValues, country: e.target.value})}}
                                            className="price-calc__dropdown-general price-calculator__select-font"
                                            style={{ color: 'var(--gray-300, #BBC2C7)', fontFamily: 'Barlow', fontSize: '15.19px' }}
                                        >
                                            <option value="" selected disabled>{t('price_calculator.step_3.country.placeholder')}</option>
                                            {destinationCountry.map(([region, countries]) => (
                                                <optgroup key={region} label={region} className='price-calculator__select-font'>
                                                    {countries.map(country => (
                                                        <option
                                                            key={country}
                                                            value={country}
                                                            className='price-calculator__select-font'
                                                        >
                                                            {country}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </select>
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.country.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.country && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-md-3 '>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_3.adValoremTax.placeholder')}
                                        {...register('adValoremTax', { required: true })}
                                        value={!!formThreeValues?.adValoremTax ? formThreeValues?.adValoremTax : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormThreeValues({...formThreeValues, adValoremTax: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.adValoremTax && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-md-3 '>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_3.weightTax.placeholder')}
                                        {...register('weightTax', { required: true })}
                                        value={!!formThreeValues?.weightTax ? formThreeValues?.weightTax : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormThreeValues({...formThreeValues, weightTax: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.weightTax && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                            
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12'>

                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_3.transportationCost.placeholder')}
                                            {...register('transportationCost', { required: true })}
                                            value={!!formThreeValues?.transportationCost ? formThreeValues?.transportationCost : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormThreeValues({...formThreeValues, transportationCost: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />

                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_3.transportationCost.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {(!!errors?.distance || !!errors?.transportationCost) && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_3.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-calculator__container-footer">
                        <div
                            className="price-calculator__footer-link"
                            onClick={handleClickOpen}
                        >{t('price_calculator.footer.link')}</div>
                        <button type="submit" className="price-calc__next">
                            <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                            <FontAwesomeIcon icon={faArrowRight} color="black" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PriceCalculatorStepThree