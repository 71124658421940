import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './CountryCodeSelector.css';


const CountryCodeSelector = () => {
  const {t} = useTranslation()
  const [value, setValue] = useState<string | undefined>(undefined);

  return (
    <div className="row">
      <div className='col-3 col-md-3 col-lg-3'>
        <div className='textbox-country-indicator'>
          <div className="custom-country-title">{t('countryCodeSelector.title')}</div>
          <div className="country-info">
            <PhoneInput
              id="phoneInputId"
              name="phoneInputName"
              international
              defaultCountry="US"
              placeholder="+1"
              value={value as any}
              onChange={setValue}
              //countries={['DE', 'AR', 'CO', 'US', 'FR', 'GB']}
              countries={['AG', 'AR', 'AW', 'BS', 'BB', 'BZ', 'BO', 'BQ', 'BR', 'CA', 'CL', 'CO', 'CR', 'CU', 'CW', 'DM', 'EC', 'SV', 'US', 'GD', 'GL', 'GT', 'GF', 'GQ', 'GY', 'HT', 'HN', 'KY', 'TC', 'VG', 'JM', 'MQ', 'MX', 'NI', 'PA', 'PY', 'PE', 'PR', 'DO', 'KN', 'VC', 'LC', 'SR', 'TT', 'UY', 'VE', 'AL', 'DZ', 'AM', 'AT', 'AZ', 'BY', 'BE', 'BA', 'BG', 'CN', 'HR', 'CY', 'CZ', 'DK', 'EG', 'EE', 'FI', 'FR', 'GE', 'DE', 'GR', 'HK', 'HU', 'IS', 'IN', 'IE', 'IL', 'IT', 'JP', 'JO', 'XK', 'LV', 'LB', 'LY', 'LI', 'LT', 'LU', 'MT', 'MD', 'ME', 'MA', 'NL', 'NZ', 'NG', 'MK', 'NO', 'PS', 'PL', 'PT', 'RO', 'RU', 'RS', 'SG', 'SK', 'SI', 'ZA', 'KR', 'ES', 'SE', 'CH', 'SY', 'TW', 'TN', 'TR', 'UA', 'GB']} 
            />
          </div>
        </div></div>
      <div className='col-9 col-md-9 col-lg-9'>
        <div className="input-container">
          <input type='number' placeholder={t('countryCodeSelector.placeholder')} className='textbox-tel' />
        </div>
      </div>
    </div>
  );
};

export default CountryCodeSelector;
