import React from "react";
import Banner from "../../components/Banner/Banner";
import Main from "../../components/Main/Main";
import Aside from "../../components/Aside/Aside";
import Suggestions from "../../components/Suggestions/Suggestions";
import "./landing-page.css";

const LandingPage: React.FC = () => {
  return (
      <div className="landing--page">
        <Banner />
        <Aside />
          <div className="landing__container">
            <div className="landing__row">
              <div className="landing__col landing__flex">
                <Main />
              </div>
              <div className="landing__col landing__fixed">
                <Suggestions />
              </div>
            </div>
          </div>
      </div>
  );
};

export default LandingPage;
