export const APP_ROUTES = {
  home: "/home",
  register: "/register",
  activateAccount: "/activate-account",
  profile: "/profile",
  exportertest: "/exportertest",
  information: "/information",
  restorepassword: "/restorepassword",
  login: "/login",
  cubicaje: "/cubicaje",
  terminosServicio: "/terminosservicio",
  priceComparator: "/pricecomparator",
  politicasPrivacidad: "/politicasprivacidad",
  noticias: "/noticias",
  noticia: "/noticia",
  acercaInterconectar: "/acerca-interconectar",
  servicios: "/servicios",
  preferencias: "/preferencias",
  calcularPrecios: "/calcularprecios",
  finalReportTestExporter: "/informe-final-test-exportador",
  resetPassword: "/recover-password",
};
