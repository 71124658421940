/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'

import './PriceCalculator.css';
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";

const PriceCalculatorStepFour = ({ handleClickOpen, handleContinueClick }:{  handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void }) => {
    const {t} = useTranslation()
    const {setVideoName} = useVideo()
    const [formFourValues, setFormFourValues] = useState<any>();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();

    const onSubmit = (data: any) => {
        localStorage.setItem('formFour', JSON.stringify(data))
        setFormFourValues(data)
        handleContinueClick()

        data.marketingSegment === 'Retail' || data.marketingSegment === 'Minorista'
        ? setVideoName('fourthRetailVideo')
        : data.marketingSegment === 'Ecommerce'
        ? setVideoName('fourthDeliveryVideo')
        : setVideoName('')
    }

    useEffect( () => {
        const formFour = localStorage.getItem('formFour') ? JSON.parse(localStorage.getItem('formFour')!) : ''
        if(Object.keys(formFour).length) setFormFourValues(formFour)
    },[])

    useEffect(() => {
        if (formFourValues) {
          reset((prev) => ({...prev, ...formFourValues}));
        }
    }, [formFourValues, reset]);

    setVideoName('')
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="exporter-test__read-only">
                <div className='price-calculator__container-main'>
                    <div className="price-calculator___inner">
                        <div className="container price-calculator___container">
                            <div className="row">
                                <div className='col-12'>
                                    <span className='price-calculator__container-title'>{t('price_calculator.step_4.subtitle')}</span>
                                </div>
                            </div>

                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_4.wholesalerProfit.placeholder')}
                                            {...register('wholesalerProfit', { required: true })}
                                            value={!!formFourValues?.wholesalerProfit ? formFourValues?.wholesalerProfit : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormFourValues({...formFourValues, wholesalerProfit: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_4.wholesalerProfit.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.wholesalerProfit && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_4.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_4.quantitymerchandise.placeholder')}
                                            {...register('quantitymerchandise', { required: true })}
                                            value={!!formFourValues?.quantitymerchandise ? formFourValues?.quantitymerchandise : ''}
                                            onChange={ (e) => { 
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormFourValues({...formFourValues, quantitymerchandise: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_4.quantitymerchandise.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.quantitymerchandise && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_4.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="row price-calculator__row mb-4">
                                <div className='col-12'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_4.transportationCost.placeholder')}
                                            {...register('transportationCost', { required: true })}
                                            value={!!formFourValues?.transportationCost ? formFourValues?.transportationCost : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormFourValues({...formFourValues, transportationCost: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_4.transportationCost.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {(!!errors?.distance || !!errors?.transportationCost) && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_4.error_message')}</div>
                                        </div>
                                    }    
                                </div>
                                
                            </div>
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <select
                                        className="price-calc__dropdown-general price-calculator__select-font"
                                        {...register('marketingSegment', { required: true })}
                                        value={!!formFourValues?.marketingSegment ? formFourValues?.marketingSegment : null}
                                        onChange={ (e) => { setFormFourValues({...formFourValues, marketingSegment: e.target.value})}}
                                        
                                    >
                                        <option value="" selected disabled>{t('price_calculator.step_4.marketingSegment.placeholder')}</option>
                                        <option className='exporter-test__select-font'>Retail</option>
                                        <option className='exporter-test__select-font'>Ecommerce</option>
                                        <option className='exporter-test__select-font'>Minorista</option>
                                    </select>
                                    {!!errors?.marketingSegment && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_4.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_4.retailerProfit.placeholder')}
                                            {...register('retailerProfit', { required: true })}
                                            value={!!formFourValues?.retailerProfit ? formFourValues?.retailerProfit : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormFourValues({...formFourValues, retailerProfit: formatNumber(e.target.value)})
                                                }
                                            }}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_4.retailerProfit.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.retailerProfit && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_4.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="price-calculator__container-footer">
                        <div
                            className="price-calculator__footer-link"
                            onClick={handleClickOpen}
                        >{t('price_calculator.footer.link')}</div>
                        <button type="submit" className="price-calc__next">
                            <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                            <FontAwesomeIcon icon={faArrowRight} color="black" />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PriceCalculatorStepFour