import React, { useEffect } from "react";
import { useActivateAccountActions } from "./activateAccount.logic";

interface ProfileProps {
  token?: string;
}
const ActivateAccount: React.FC<ProfileProps> = ({ token }) => {
  const { activateUser } = useActivateAccountActions()

  useEffect(() => {
    if (token && token !== "")
        activateUser(token)
  },[activateUser, token])


  return <div>Loading...</div>;
};

export default ActivateAccount;
