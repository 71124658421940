type Option = {
  label: string;
  value: string;
  parent?: string;
  options: any[];
};

export const preferencesDataList: Option[] = [
  {
    label: "Tipo de Mercado",
    value: "Tipo de Mercado",
    options: [
      {
        subtitle: "Mercado de bienes de consumo",
        values: [
          {
            label: "Mercado de servicios",
            value: "Mercado de servicios",
            parent: "Mercado de bienes de consumo",
          },
          {
            label: "Unión Europea",
            value: "Unión Europea",
            parent: "Mercado de bienes de consumo",
          },
          {
            label: "Estados Unidos y Canadá",
            value: "Estados Unidos y Canadá",
            parent: "Mercado de bienes de consumo",
          },
          {
            label: "Oriente Medio",
            value: "Oriente Medio",
            parent: "Mercado de bienes de consumo",
          },
        ],
      },
      {
        subtitle: "Según el canal de distribución",
        values: [
          {
            label: "Mercado minorista",
            value: "Mercado minorista",
            parent: "Según el canal de distribución",
          },
          {
            label: "Mercado mayorista",
            value: "Mercado mayorista",
            parent: "Según el canal de distribución",
          },
          {
            label: "Retail Discount",
            value: "Retail Discount",
            parent: "Según el canal de distribución",
          },
        ],
      },
      {
        subtitle: "Tipo de Producto",
        values: [
          {
            label:
              "Bienes duraderos (por ejemplo, electrodomésticos, automóviles)",
            value:
              "Bienes duraderos (por ejemplo, electrodomésticos, automóviles)",
            parent: "Según la durabilidad",
          },
          {
            label:
              "Bienes no duraderos (por ejemplo, alimentos perecederos, productos de limpieza)",
            value:
              "Bienes no duraderos (por ejemplo, alimentos perecederos, productos de limpieza)",
            parent: "Según la durabilidad",
          },
        ],
      },
      {
        subtitle: "Según la durabilidad",
        values: [
          {
            label: "Bienes de consumo (por ejemplo, ropa, alimentos)",
            value: "Bienes de consumo (por ejemplo, ropa, alimentos)",
            parent: "Según la durabilidad",
          },
          {
            label:
              "Bienes industriales (por ejemplo, maquinaria, materias primas)",
            value:
              "Bienes industriales (por ejemplo, maquinaria, materias primas)",
            parent: "Según la durabilidad",
          },
        ],
      },
      {
        subtitle: "Según la exclusividad",
        values: [
          {
            label: "Bienes de lujo",
            value: "Bienes de lujo",
            parent: "Según la durabilidad",
          },
          {
            label: "Bienes de necesidad básica",
            value: "Bienes de necesidad básica",
            parent: "Según la durabilidad",
          },
        ],
      },
    ],
  },
  {
    label: "Tipo de Producto",
    value: "Tipo de Producto",
    options: [
      {
        subtitle: "Según la durabilidad",
        values: [
          {
            label:
              "Bienes duraderos(por ejemplo, electrodomésticos, automóviles)",
            value:
              "Bienes duraderos(por ejemplo, electrodomésticos, automóviles)",
            parent: "Según la durabilidad ",
          },
          {
            label:
              "Bienes no duraderos(por ejemplo, alimentos perecederos, productos de limpieza)",
            value:
              "Bienes no duraderos(por ejemplo, alimentos perecederos, productos de limpieza)",
            parent: "Según la durabilidad ",
          },
        ],
      },
      {
        subtitle: "Según el uso",
        values: [
          {
            label: "Bienes de consumo(por ejemplo, ropa, alimentos)",
            value: "Bienes de consumo(por ejemplo, ropa, alimentos)",
            parent: "Según el uso",
          },
          {
            label:
              "Bienes industriales(por ejemplo, maquinaria, materias primas)",
            value:
              "Bienes industriales(por ejemplo, maquinaria, materias primas)",
            parent: "Según el uso",
          },
        ],
      },
      {
        subtitle: "Según la exclusividad",
        values: [
          {
            label: "Bienes de lujo",
            value: "Bienes de lujo",
            parent: "Según la exclusividad",
          },
          {
            label: "Bienes de necesidad básica",
            value: "Bienes de necesidad básica",
            parent: "Según la exclusividad",
          },
        ],
      },
      {
        subtitle: "Según el ciclo de vida del producto",
        values: [
          {
            label: "Productos nuevos",
            value: "Productos nuevos",
            parent: "Según el ciclo de vida del producto",
          },
          {
            label: "Productos en crecimiento",
            value: "Productos en crecimiento",
            parent: "Según el ciclo de vida del producto",
          },
          {
            label: "Productos maduros",
            value: "Productos maduros",
            parent: "Según el ciclo de vida del producto",
          },
          {
            label: "Productos en declive",
            value: "Productos en declive",
            parent: "Según el ciclo de vida del producto",
          },
        ],
      },
      {
        subtitle: "Según el grado de innovación",
        values: [
          {
            label: "Productos innovadores",
            value: "Productos innovadores",
            parent: "Según el grado de innovación",
          },
          {
            label: "Productos imitativos",
            value: "Productos imitativos",
            parent: "Productos innovadores",
          },
          {
            label: "Productos de mejora continua",
            value: "Productos de mejora continua",
            parent: "Productos imitativos",
          },
        ],
      },
      {
        subtitle: "Según la marca",
        values: [
          {
            label: "Marcas de fabricante",
            value: "Marcas de fabricante",
            parent: "Según la marca",
          },
          {
            label: "Marcas blancas o de distribuidor",
            value: "Marcas blancas o de distribuidor",
            parent: "Según la marca",
          },
        ],
      },
      {
        subtitle: "Según la frecuencia de compra",
        values: [
          {
            label: "Productos de compra frecuente",
            value: "Productos de compra frecuente",
            parent: "Según la frecuencia de compra",
          },
          {
            label: "Productos de compra ocasional",
            value: "Productos de compra ocasional",
            parent: "Según la frecuencia de compra",
          },
          {
            label: "Productos de compra impulsiva",
            value: "Productos de compra impulsiva",
            parent: "Según la frecuencia de compra",
          },
        ],
      },
      {
        subtitle: "Según la edad del público objetivo",
        values: [
          {
            label: "Productos para niños",
            value: "Productos para niños",
            parent: "Según la edad del público objetivo",
          },
          {
            label: "Productos para adolescentes",
            value: "Productos para adolescentes",
            parent: "Según la edad del público objetivo",
          },
          {
            label: "Productos para adultos",
            value: "Productos para adultos",
            parent: "Según la edad del público objetivo",
          },
          {
            label: "Productos para personas mayores",
            value: "Productos para personas mayores",
            parent: "Según la edad del público objetivo",
          },
          {
            label: "Productos para cualquier edad",
            value: "Productos para cualquier edad",
            parent: "Según la edad del público objetivo",
          },
        ],
      },
      {
        subtitle: "Según la preferencia de canal de compra",
        values: [
          {
            label: "Productos preferidos para compras en línea",
            value: "Productos preferidos para compras en línea",
            parent: "Según la preferencia de canal de compra",
          },
          {
            label: "Productos preferidos para compras en tiendas físicas",
            value: "Productos preferidos para compras en tiendas físicas",
            parent: "Según la preferencia de canal de compra",
          },
        ],
      },
      {
        subtitle: "Según la necesidad de educación del consumidor",
        values: [
          {
            label: "Productos de uso intuitivo",
            value: "Productos de uso intuitivo",
            parent: "Según la necesidad de educación del consumidor",
          },
          {
            label: "Productos que requieren una mayor comprensión del usuario",
            value: "Productos que requieren una mayor comprensión del usuario",
            parent: "Según la necesidad de educación del consumidor",
          },
        ],
      },
      {
        subtitle: "Según el tipo de destinatario",
        values: [
          {
            label: "Productos para personas",
            value: "Productos para personas",
            parent: "Según el tipo de destinatario",
          },
          {
            label: "Productos para animales",
            value: "Productos para animales",
            parent: "Según el tipo de destinatario",
          },
          {
            label: "Productos para el hogar",
            value: "Productos para el hogar",
            parent: "Según el tipo de destinatario",
          },
          {
            label: "Productos para comercios e industrias",
            value: "Productos para comercios e industrias",
            parent: "Según el tipo de destinatario",
          },
        ],
      },
      {
        subtitle: "Economía mixta",
        values: [
          {
            label: "Industrias",
            value: "Industrias",
            parent: "Economía mixta",
          },
          {
            label: "Tecnología de la Información",
            value: "Tecnología de la Información",
            parent: "Economía mixta",
          },
          {
            label: "Industrias creativas",
            value: "Industrias creativas",
            parent: "Economía mixta",
          },
          {
            label: "Farmacia, salud y cosmética",
            value: "Farmacia, salud y cosmética",
            parent: "Economía mixta ",
          },
          {
            label: "Turismo",
            value: "Turismo",
            parent: "Economía mixta",
          },
          {
            label: "Alimentos y bebidas",
            value: "Alimentos y bebidas",
            parent: "Economía mixta",
          },
          {
            label: "Equipos industiales",
            value: "Equipos industiales",
            parent: "Economía mixta",
          },
          {
            label: "Energías, reciclado, y tecnologías ambientales",
            value: "Energías, reciclado, y tecnologías ambientales",
            parent: "Economía mixta",
          },
          {
            label: "Servicios y soluciones para empresas",
            value: "Servicios y soluciones para empresas",
            parent: "Economía mixta",
          },
        ],
      },
      {
        subtitle: "Alimentos procesados",
        values: [
          {
            label: "Productos secos",
            value: "Productos secos",
            parent: "Alimentos procesados",
          },
          {
            label: "Productos refrigerados",
            value: "Productos refrigerados",
            parent: "Alimentos procesados",
          },
          {
            label: "Productos congelados",
            value: "Productos congelados",
            parent: "Alimentos procesados",
          },
        ],
      },
      {
        subtitle: "Productos para Salud",
        values: [
          {
            label: "Aging",
            value: "Aging",
            parent: "Productos para Salud",
          },
          {
            label: "Coronavirus",
            value: "Coronavirus",
            parent: "Productos para Salud",
          },
          {
            label: "Covid - 19",
            value: "Covid - 19",
            parent: " Productos para Salud",
          },
        ],
      },
      {
        subtitle: "Fitness",
        values: [
          {
            label: "Mens Health",
            value: "Mens Health",
            parent: "Fitness",
          },
          { label: "Nutrition", value: "Nutrition", parent: "Fitness" },
          { label: "Sleep", value: "Sleep", parent: "Fitness" },
          {
            label: "Trans Healthcare",
            value: "Trans Healthcare",
            parent: "Fitness",
          },
          { label: "Vaccines", value: "Vaccines", parent: "Fitness" },
          {
            label: "Weight Loss",
            value: "Weight Loss",
            parent: "Fitness",
          },
          {
            label: "Womens Health",
            value: "Womens Health",
            parent: "Fitness",
          },
        ],
      },
      {
        subtitle: "Productos para mascotas",
        values: [
          {
            label: "Gatos",
            value: "Gatos",
            parent: "Productos para mascotas",
          },
          {
            label: "Perros",
            value: "Perros",
            parent: "Productos para mascotas",
          },
          {
            label: "Entrenamiento",
            value: "Entrenamiento",
            parent: "Productos para mascotas",
          },
          {
            label: "Animales grandes",
            value: "Animales grandes",
            parent: "Productos para mascotas",
          },
          {
            label: "Animales exóticos",
            value: "Animales exóticos",
            parent: "Productos para mascotas",
          },
        ],
      },
    ],
  },
  {
    label: "Países",
    value: "Países",
    options: [
      {
        subtitle: "Países que integran la Comunidad Europea",
        values: [
          {
            label: "Alemania",
            value: "Alemania",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Austria",
            value: "Austria",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Bélgica",
            value: "Bélgica",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Bulgaria",
            value: "Bulgaria",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Chipre",
            value: "Chipre",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Croacia",
            value: "Croacia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Dinamarca",
            value: "Dinamarca",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Eslovaquia",
            value: "Eslovaquia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Eslovenia",
            value: "Eslovenia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "España",
            value: "España",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Estonia",
            value: "Estonia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Finlandia",
            value: "Finlandia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Francia",
            value: "Francia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Grecia",
            value: "Grecia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Hungría",
            value: "Hungría",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Irlanda",
            value: "Irlanda",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Italia",
            value: "Italia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Letonia",
            value: "Letonia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Lituania",
            value: "Lituania",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Luxemburgo",
            value: "Luxemburgo",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Malta",
            value: "Malta",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Países Bajos",
            value: "Países Bajos",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Polonia",
            value: "Polonia",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Portugal",
            value: "Portugal",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "República Checa",
            value: "República Checa",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Rumanía",
            value: "Rumanía",
            parent: "Países que integran la Comunidad Europea",
          },
          {
            label: "Suecia",
            value: "Suecia",
            parent: "Países que integran la Comunidad Europea",
          },
        ],
      },
      {
        subtitle: "Países europeos que no integran la Comunidad Europea",
        values: [
          {
            label: "Albania",
            value: "Albania",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Andorra",
            value: "Andorra",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Armenia",
            value: "Armenia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Aserbadjian",
            value: "Aserbadjian",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Bielorusia",
            value: "Bielorusia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Bosnia y Herzegovina",
            value: "Bosnia y Herzegovina",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Escocia",
            value: "Escocia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Gales",
            value: "Gales",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Georgia",
            value: "Georgia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Inglaterra",
            value: "Inglaterra",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Irlanda del Norte",
            value: "Irlanda del Norte",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Islandia",
            value: "Islandia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Kasajistan",
            value: "Kasajistan",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Kosovo",
            value: "Kosovo",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Liechtenstein",
            value: "Liechtenstein",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Macedonia del Norte",
            value: "Macedonia del Norte",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Moldavia",
            value: "Moldavia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Monaco",
            value: "Monaco",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Montenegro",
            value: "Montenegro",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Noruega",
            value: "Noruega",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Rusia",
            value: "Rusia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "San Marino",
            value: "San Marino",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Serbia",
            value: "Serbia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Suiza",
            value: "Suiza",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Turquia",
            value: "Turquia",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Ucrania",
            value: "Ucrania",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
          {
            label: "Vaticano",
            value: "Vaticano",
            parent: "Países europeos que no integran la Comunidad Europea",
          },
        ],
      },
    ],
  },
  {
    label: "Publicaciones",
    value: "Publicaciones",
    options: [
      {
        subtitle: "Tipos de publicaciones",
        values: [
          {
            label: "Información general",
            value: "Información general",
            parent: "Tipos de publicaciones",
          },
          {
            label: "Mercados",
            value: "Mercados",
            parent: "Tipos de publicaciones",
          },
        ],
      },
      {
        subtitle: "De productos",
        values: [
          {
            label: "Financieras",
            value: "Financieras",
            parent: "De productos",
          },
          { label: "Aduaneras", value: "Aduaneras", parent: "De productos" },
        ],
      },
    ],
  },
  {
    label: "Normativas EU",
    value: "Normativas EU",
    options: [
      {
        subtitle: "Normativas EU",
        values: [
          {
            label: "Financieras",
            value: "Financieras",
            parent: "Normativas EU",
          },
          { label: "Aduaneras", value: "Aduaneras", parent: "Normativas EU" },
        ],
      },
    ],
  },
];
