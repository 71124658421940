import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import loginImage from "../../assets/images/login-img.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from "../../assets/images/info.svg";
import "./Login.css";
import { usePostApiUserLogin } from '../../api/interconectar-api'
import { APP_ROUTES } from "../../routes";

const Login: React.FC = () => {
  const {t} = useTranslation()
  const { setIsAuthenticated } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setloginFailed] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { mutate: loginUser } = usePostApiUserLogin();
  const handleBlur = (field: string) => {
    switch (field) {
      case "username":
        setloginFailed(false);
        if (username.length === 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: t('login.error.message'),
          }));
        } else {
          setloginFailed(false);
          setErrors((prevErrors) => ({
            ...prevErrors,
            username: "",
          }));
        }
        break;
      default:
        break;
    }
  };
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleButtonClick = () => {
      loginUser({
        data: {emailAlias: username, password: password}
      }, {
        onSuccess(data, variables, context) {
          setIsAuthenticated(true);
          localStorage.setItem('token', data.result?.accessToken ?? "");
          localStorage.setItem("username", data.result?.user?.email ?? "");
          localStorage.setItem("alias", data.result?.user?.alias ?? "");
          setloginFailed(false);
          if (data.result?.user?.personalInformation === null){
            navigate("/profile");
          }
          else{
            navigate("/home");
          }
        },
        onError(error, variables, context) {
          setloginFailed(true);
          localStorage.removeItem('token');
        },
      });
  };

  return (
    <div className="login-container">
      <img
        className="login-image"
        src={loginImage}
        alt="Login"
      />
      <h2 className="login-title">{t('login.title')}</h2>
      <div>
        <input
          className={`${errors.username ? "txt-error" : username.length > 0 ? "txt-success" : ""} txt-user`}
          placeholder={t('login.username.placeholder')}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onBlur={() => handleBlur("username")}
        />
      </div>
      {errors.username && (
        <div className="error-container">
          <InfoIcon fill="#F8A0D2" />
          <div className="error-message">{errors.username}</div>
        </div>
      )}
      {loginFailed && (
        <div className="error-container">
           <InfoIcon fill="#F8A0D2"/>
          <div className="error-message">{t('login.username.error.message')}</div>
        </div>
      )}
      <div className="password-input-container">
        <input
          placeholder={t('login.password.placeholder')}
          className="txt-pass"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={toggleShowPassword}
          className="toggle-password-icon"
        />
      </div>
      {loginFailed && (
        <div className="error-container">
           <InfoIcon fill="#F8A0D2"/>
          <div className="error-message">{t('login.username.error.message')}</div>
        </div>
      )}
      <div>
        <Link className="txt-recover-password" to={APP_ROUTES.resetPassword}>{t('login.link')}</Link>
      </div>
      <button className="login-btn login-btn-text" onClick={handleButtonClick}>{t('login.button')}</button>
      <div>
        <span className="normal-text">{t('login.new.text')}</span><Link to="/register" className="txt-recover-password">{t('login.new.link')}</Link>
      </div>
    </div>
  );
};

export default Login;
