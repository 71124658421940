// CATEGORIAS
export const UnidadMedidaPeso = [
    "mg", "g", "kg", "libra", "onza" 
];

export const UnidadMedidaM = [
    "mm", "cm", "ft", "in"   // "ft" -> Pie    // in -> Pulgadas
];

export const CantidadPallets = [
    "1","2","3","4","5","6","7"
];
