import React, { FC, useState } from "react";
import { IconCarret } from "../Icons/IconCarret";
import { Link } from "react-router-dom";
import { IconCalcularPrecios, IconCubicaje, IconExporterTest, IconPriceComparator } from "../Icons";
import { IconHerramientas } from "../Icons/IconHerramientas";
import { useTranslation } from "react-i18next";

const HeaderDropdownMobile: FC = () => {
    const { t } = useTranslation();
    
    // Estado para controlar si el dropdown está abierto o cerrado
    const [isOpen, setIsOpen] = useState(false);

    // Función para alternar el estado del dropdown
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <div className="headerdropdown hide-desktop">
        
            <button onClick={toggleDropdown} 
            className={`headerdropdown-button ${isOpen ? 'active' : ''}`}
            >
                <div className="headerdropdown-button-icon">
                    <IconHerramientas />
                </div>
                <IconCarret fill="white"/>
            </button>
        
        {isOpen && (
            <div className="headerdropdown-menu">
                <div className="headerdropdown-body">
                    <h3 className="headerdropdown-title">{t("header.tools")}</h3>
                    <div className="headerdropdown-items">
                        <Link className="headerdropdown-link" to="/exportertest" onClick={closeDropdown}>
                            <div className="headerdropdown-icon">
                                <IconExporterTest fill="#425563"  />
                            </div>
                            <span className="headerdropdown-name">{t("aside.box1.title")} </span>
                        </Link>
                        <Link className="headerdropdown-link" to="/cubicaje" onClick={closeDropdown}>
                            <div className="headerdropdown-icon">
                                <IconCubicaje />
                            </div>
                            <span className="headerdropdown-name">{t("aside.box2.title")} </span>
                        </Link>
                        <Link className="headerdropdown-link" to="/calcularprecios" onClick={closeDropdown}>
                            <div className="headerdropdown-icon">
                                <IconCalcularPrecios />
                            </div>
                            <span className="headerdropdown-name">{t("aside.box3.title")} </span>
                        </Link>
                        <Link className="headerdropdown-link" to="/pricecomparator" onClick={closeDropdown}>
                            <div className="headerdropdown-icon">
                                <IconPriceComparator />
                            </div>
                            <span className="headerdropdown-name">{t("aside.box4.title")} </span>
                        </Link>
                    </div>
                </div>
            </div>
        )}
      </div>
    )

}
export default HeaderDropdownMobile;


