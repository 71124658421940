import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import "./Cubicaje.css";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import {
  faArrowRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomizedSwitch from "../../core/CustomizedSwitch/CustomizedSwitch";
import { UnidadMedidaM, UnidadMedidaPeso } from "./DataOptions";
import CustomizedSelect from "../../core/CustomizedSelect/CustomizedSelect";
import CustomMessage from "../../core/CustomMessage/CustomMessage";
import { IFormData, Pallet } from "./Cubicaje";
import circleError from "../../assets/images/circle-exclamation-error.svg";
import euroPaletAng from "../../assets/videos/cubicaje/45.png";
import standardAng1 from "../../assets/videos/cubicaje/standard pallet 45.png";
import standardAng from "../../assets/videos/cubicaje/autoelevador-1m-standard.gif";
import euroPalet360 from "../../assets/videos/cubicaje/europallet360.gif";
import standardPalet360_1 from "../../assets/videos/cubicaje/Standard-pallet-360.gif";
import standardPalet360 from "../../assets/videos/cubicaje/autoelevador-1m-standard.gif";
import euroFull from "../../assets/videos/cubicaje/europallet360carga.gif";
import standardFull1 from "../../assets/videos/cubicaje/Standard-pallet-360-cargado.gif";
import standardFull from "../../assets/videos/cubicaje/autoelevador-1m-standard.gif";
import euroPaletVideo from "../../assets/videos/cubicaje/autoelevador-1m.gif";
interface Props {
  palletItem: Pallet;
  handleContinueClick: () => void;
  formData: IFormData;
  setFormData: Function;
  onCubicajeSelected: Function;
  setIsLegalModalVisible: Function;
}

type IsVisibleErrors = {
  isErrorCheckMaximumWeight: boolean;
  isErrorCheckMaximumHeight: boolean;
};

const CubicajeSeccionTres: React.FC<Props> = ({
  palletItem,
  handleContinueClick,
  formData,
  setFormData,
  onCubicajeSelected,
  setIsLegalModalVisible,
}) => {
  const {t} = useTranslation()
  const [activePesoMax, setactivePesoMax] = useState(false);
  const [activeAltMax, setactiveAltMax] = useState(false);
  const [pesoDinamico, setPesoDinamico] = useState<number>();
  const [unidadMedidaDistancia, setUnidadMedidaDistancia] = useState("mm");
  const [showMenssageCargaDinamica, setShowMenssageCargaDinamica] =
    useState(false);
  const [showMessageCero, setShowMessageCero] = useState(false);
  const [nCajasPallet, setNCajasPallet] = useState<number>(0);
  const [cajasCalculado, setCajasCalculado] = useState(0);
  const [showMessageValidator, setShowMessageValidator] = useState(false);
  const [cargaDinamicaSeleccionado] = useState(palletItem.cargaDinamica);
  const [palletAncho] = useState(palletItem.ancho);
  const [palletLargo] = useState(palletItem.largo);

  const [isMaxSizeValueError, setIsMaxSizeValueError] =
    useState<boolean>(false);
  const [isMaxLargeValueError, setIsMaxLargeValueError] =
    useState<boolean>(false);
  const [isLineValueError, setIsLineValueError] = useState<boolean>(false);
  const [maxSize, setMaxSize] = useState<any>(null);

  const [isCheckMaximumWeight, setIsCheckMaximumWeight] =
    useState<boolean>(false);
  const [isCheckMaximumHeight, setIsCheckMaximumHeight] =
    useState<boolean>(false);

  const [isVisibleErrors, setIsVisibleErrors] = useState<IsVisibleErrors>({
    isErrorCheckMaximumWeight: false,
    isErrorCheckMaximumHeight: false,
  });

  useEffect(() => {
     onCubicajeSelected(false, palletItem.index === 0 ? euroPaletVideo : standardFull);  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*ALEX: Acá se calcula la cantidad de filas de acuerdo al peso dinámico */
  useEffect(() => {
     onCubicajeSelected(false, palletItem.index === 0 ? euroPaletVideo : standardFull);  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*ALEX: Acá se calcula la cantidad de filas de acuerdo al peso dinámico */
  useEffect(() => {
    const KCajasXFila = Math.round(
      CantidadCajasxAncho() * CantidadCajasxLargo()
    );
    const PesoFilaCajas = KCajasXFila * parseFloat(formData.pesoStep2);
    const cargaPosibleCajas = cargaDinamicaSeleccionado - 27
    const varKFilasMaxPeso = Math.round(
      cargaPosibleCajas / PesoFilaCajas
    );

    setFormData((prevData: IFormData) => ({
      ...prevData,
      KcajasXFila: KCajasXFila.toString(),
      PesoFilaCajas: PesoFilaCajas.toString(),
      FilasMaximoPeso: varKFilasMaxPeso.toString(),
    }));

    setNCajasPallet(varKFilasMaxPeso);
    setCajasCalculado(varKFilasMaxPeso);

    //calculo Cantidad de cajas por ancho =parte entera de ( [ancho del palet] / [ancho de la caja])
    function CantidadCajasxAncho() {
      const anchoCaja = parseFloat(formData.anchoStep2);
      return palletAncho / anchoCaja;
    }

    //Cantidad de cajas por largo =parte entera de ( [largo del palet] / [largo de la caja]) Ejemplo: 800/230 = 3.4782; resultado = 3
    function CantidadCajasxLargo() {
      const largoCaja = parseFloat(formData.largoStep2);
      return palletLargo / largoCaja;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargaDinamicaSeleccionado, palletAncho, palletLargo]);

  function handlePesoMaximo(arg0: boolean) {
    setactivePesoMax(arg0);
  }

  function handleAlturaMax(arg0: boolean) {
    setactiveAltMax(arg0);
  }

  function handleMeidaDis(value: string) {
    setUnidadMedidaDistancia(value);
  }

  const validateActiveWeightValues = () => {
    if (activePesoMax) {
      if (pesoDinamico === undefined || pesoDinamico === null) {
        setIsMaxSizeValueError(true);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const validateMaxSizeValues = () => {
    if (activeAltMax) {
      if (maxSize === undefined || maxSize === null) {
        setIsMaxLargeValueError(true);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onValidate = () => {
    if (
      isCheckMaximumWeight &&
      isCheckMaximumHeight &&
      !isMaxSizeValueError &&
      !isMaxLargeValueError
    ) {
      if (
        nCajasPallet === undefined ||
        nCajasPallet === null ||
        nCajasPallet === 0 ||
        isNaN(nCajasPallet)
      ) {
        setIsLineValueError(true);
      }

      if (
        validateActiveWeightValues() &&
        validateMaxSizeValues() &&
        nCajasPallet
      ) {
        handleContinueClick();
      }
    } else {
      setIsVisibleErrors((preValue) => ({
        ...preValue,
        isErrorCheckMaximumWeight: !isCheckMaximumWeight,
        isErrorCheckMaximumHeight: !isCheckMaximumHeight,
      }));
    }
  };

  return (
    <div>
      {showMenssageCargaDinamica && (
        <CustomMessage
          background={"#F4B400"}
          textMessage={t('cubicaje.step_3.message_1')}
        />
      )}

      {showMessageCero && (
        <CustomMessage
          background={"#F4B400"}
          textMessage={t('cubicaje.step_3.message_2')}
        />
      )}
      <form>
        <div className="mb-3">
          <label className="form-label"> {t('cubicaje.step_3.item_1.title')}
            
          </label>
          <CustomizedSwitch
            onChange={([activePesoMax]) => {
              // if (activePesoMax) {
              //   onCubicajeSelected(
              //     false,
              //     palletItem.titlePallet === "Europalet"
              //       ? euroPaletAng
              //       : standardAng
              //   );
              // } else {
              //   onCubicajeSelected(
              //     false,
              //     palletItem.titlePallet === "Europalet"
              //       ? euroFull
              //       : standardFull
              //   );
              // }
              handlePesoMaximo(activePesoMax);
              setIsCheckMaximumWeight(true);
              setIsVisibleErrors((preValue) => ({
                ...preValue,
                isErrorCheckMaximumWeight: false,
              }));
              setIsMaxSizeValueError(activePesoMax);
            }}
            checked={activePesoMax}
          />
        </div>
        {isVisibleErrors.isErrorCheckMaximumWeight && (
          <div className="container errorContainer">
            <img src={circleError} alt="error-icon" />
            <p className="errorInputValidation">{t('cubicaje.step_3.selectOption')}</p>
          </div>
        )}
        {activePesoMax && (
          <div className="rowcubicaje-container rowcubicaje-container-doblerow">
            <div className="rowcubicaje-col rowcubicaje-min">
              <CustomizedSelect title={"Unidad"} options={UnidadMedidaPeso} />
            </div>
            <div className="rowcubicaje-rightmin">
              <input
                type="number"
                value={pesoDinamico}
                onChange={(e) => {
                  e.preventDefault();
                  setPesoDinamico(parseFloat(e.currentTarget.value));
                  setIsMaxSizeValueError(e.currentTarget.value === "");
                  if (
                    parseFloat(e.currentTarget.value) > cargaDinamicaSeleccionado
                  ) {
                    setShowMenssageCargaDinamica(true);
                  } else {
                    if (
                      parseFloat(e.currentTarget.value) <= 0 ||
                      e.currentTarget.value === undefined ||
                      e.currentTarget.value === null
                    ) {
                      setShowMessageCero(true);
                    } else {
                      setShowMessageCero(false);
                    }
                    setShowMenssageCargaDinamica(false);
                  }
                }}
                className="inputItem shortInput"
                placeholder={t('cubicaje.step_3.item_1.placeholder')}
              />
            </div>
            {isMaxSizeValueError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_3.required')}</p>
              </div>
            )}
          </div>
        )}
        <div className="mb-3 mt-3">
          <label className="form-label">{t('cubicaje.step_3.item_2.title')}</label>

          <CustomizedSwitch
            onChange={([activeAltMax]) => {
              // if (activeAltMax) {
              //   onCubicajeSelected(
              //     false,
              //     palletItem.titlePallet === "Europalet"
              //       ? euroPalet360
              //       : standardPalet360
              //   );
              // }
              handleAlturaMax(activeAltMax);
              setIsCheckMaximumHeight(true);
              setIsVisibleErrors((preValue) => ({
                ...preValue,
                isErrorCheckMaximumHeight: false,
              }));
              setIsMaxLargeValueError(activeAltMax);
            }}
            checked={activeAltMax}
          />
        </div>
        {isVisibleErrors.isErrorCheckMaximumHeight && (
          <div className="container errorContainer">
            <img src={circleError} alt="error-icon" />
            <p className="errorInputValidation">{t('cubicaje.step_3.selectOption')}</p>
          </div>
        )}
        {activeAltMax && (
          <div className="rowcubicaje-container rowcubicaje-container-doblerow">
            <div className="rowcubicaje-col rowcubicaje-min">
              <div className="CustomizedSelect__container">
                <div className="CustomizedSelect__title">{t('cubicaje.step_3.unit')}</div>
                <div className="country-info">
                  <select
                    style={{ maxWidth: 65 }}
                    defaultValue={unidadMedidaDistancia}
                    onChange={(e) => {
                      handleMeidaDis(e.currentTarget.value);
                    }}
                  >
                    {UnidadMedidaM.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="rowcubicaje-col rowcubicaje-min rowcubicaje-rightmin">
              <input
                type="text"
                className="inputItem shortInput"
                placeholder={t('cubicaje.step_3.item_2.placeholder')}
                onChange={(e) => {
                  e.preventDefault();
                  setMaxSize(parseFloat(e.currentTarget.value));
                  setIsMaxLargeValueError(e.currentTarget.value === "");
                }}
              />
            </div>
            {isMaxLargeValueError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_3.required')}</p>
              </div>
            )}
          </div>
        )}
        <div className="mb-3 mt-3">
          <label className="form-label">{t('cubicaje.step_3.item_3.title')}</label>
          <div className="selectCantidadPallet">
            <input
              type="number"
              value={nCajasPallet}
              min={0}
              onChange={(e) => {
                // onCubicajeSelected(
                //   false,
                //   palletItem.titlePallet === "Europalet"
                //     ? euroFull
                //     : standardFull
                // );
                e.preventDefault();
                setNCajasPallet(parseFloat(e.currentTarget.value));
                setIsLineValueError(false);
                if (parseFloat(e.currentTarget.value) <= cajasCalculado) {
                  setShowMessageValidator(false);
                  const varKFilasMaxPeso = Math.round(
                    parseFloat(formData.PesoFilaCajas) /
                      parseFloat(e.currentTarget.value)
                  );
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    newCalculoPesoFilasMax: varKFilasMaxPeso.toString(),
                  }));
                  setIsLineValueError(false);
                } else {
                  setShowMessageValidator(true);
                }
              }}
              className="cubicaje-information__textbox-general"
              placeholder={t('cubicaje.step_3.item_3.placeholder')}
            />
            <div style={{ marginLeft: "15px" }}>
              <CustomToolTip
                title=""
                tooltipText={t('cubicaje.step_3.item_3.tooltip')}
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className="font-size: 15px"
                />
              </CustomToolTip>
            </div>
          </div>
          {showMessageValidator && (
            <CustomMessage
              background={`#F4B400`}
              textMessage={`${t('cubicaje.step_3.message_3a')} ${cajasCalculado}, ${t('cubicaje.step_3.message_3b')}`}
            />
          )}
        </div>
        {isLineValueError && (
          <div className="container errorContainer">
            <img src={circleError} alt="error-icon" />
            <p className="errorInputValidation">{t('cubicaje.step_3.required')}</p>
          </div>
        )}
      </form>
      <div className="mainContainerFooter">
        <div
          className="see_legals"
          onClick={() => setIsLegalModalVisible(true)}
        >
          <p>{t('cubicaje.legal')}</p>
        </div>
        <div className="cubicaje-information__next" onClick={onValidate}>
          <div className="btn-test-next-text">{t('cubicaje.button')}</div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
    </div>
  );
};

export default CubicajeSeccionTres;
