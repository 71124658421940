import React from "react";
import ActivateAccount from "../../components/ActivateAccount/ActivateAccount";
import { useParams } from 'react-router-dom';

const ActivateAccountPage: React.FC = () => {
  const params = useParams();
  return (
      <ActivateAccount token={params.token} />
  );
};

export default ActivateAccountPage;
