import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CustomizedSwitch.css';
import Button from '@mui/material/Button';

export type TSwitchOption = "option1" | "option2" | "option3";
export type TSwitchValue = [boolean, TSwitchOption];

interface SwitchProps {
  onChange: (checked: TSwitchValue) => void;
  checked: boolean;
  isThreeOptions?: boolean;
}

function CustomizedSwitch({ onChange, checked, isThreeOptions }: SwitchProps) {
  const {t} = useTranslation()
  const [selectedOption, setSelectedOption] = useState('option0');

  const handleButtonClick = (option : any) => {
    setSelectedOption(option);
    switch (option) {
      case 'option0':
        break;     
      case 'option1':
        onChange([true, 'option1']);
        break;   
      case 'option2':
        onChange([false, 'option2']);
        break; 
      case 'option3':
        onChange([false, 'option3']);
        break;
    
      default:
    }

  };

  return (
    <div style={{ display: 'flex' }}>
        <Button
          variant={selectedOption === 'option1' ? 'contained' : 'outlined'}
          className='CustomSwitch__LeftOption CustomSwitch__Font'
          onClick={() => handleButtonClick('option1')}
          style={{
            backgroundColor: selectedOption === 'option1' ? '#BED5C6' : '#425563',
            color: selectedOption === 'option1' ? '#1A2228' : '#BBC2C7',
            textTransform: 'none',
          }}
        >
          {t('customizedSwitch.yes')}
        </Button>
        <Button
          variant={selectedOption === 'option2' ? 'contained' : 'outlined'}
          className={isThreeOptions === true ? 'CustomSwitch__CenterOption CustomSwitch__Font' : 'CustomSwitch__RightOption CustomSwitch__Font'} 
          onClick={() => handleButtonClick('option2')}
          style={{
            backgroundColor: selectedOption === 'option2' ? '#BED5C6' : '#425563',
            color: selectedOption === 'option2' ? '#1A2228' : '#BBC2C7',
            textTransform: 'none',
          }}
        >
          {t('customizedSwitch.no')}
        </Button>
        <Button
          variant={selectedOption === 'option3' ? 'contained' : 'outlined'}
          className={isThreeOptions === true ? 'CustomSwitch__RightOption CustomSwitch__Font' : 'CustomSwitch__NoVisible'} 
          onClick={() => handleButtonClick('option3')}
          style={{
            backgroundColor: selectedOption === 'option3' ? '#BED5C6' : '#425563',
            color: selectedOption === 'option3' ? '#1A2228' : '#BBC2C7',
            textTransform: 'none',
          }}
        >
          {t('customizedSwitch.idk')}
        </Button>
      </div>
  );
}

export default CustomizedSwitch;