// AnimationTest.tsx
import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import menStartTestImage from "../../assets/images/men-start-test.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./ExporterTest.css";

interface Props {
  onStartButtonClick: () => void;
}

const AnimationTest: React.FC<Props> = ({ onStartButtonClick }) => {
  const {t} = useTranslation()
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.body.classList.add("dark-overlay");
    const animationContainer = document.querySelector(
      ".animationTest__container"
    );
    if (animationContainer) {
      animationContainer.classList.add("activate-overlay");
    }
  }, []);

  const handleNextButtonClick = () => {
    if (page === 1) {
      setPage(page + 1);
    } else {
      document.body.classList.remove("dark-overlay");
      const animationContainer = document.querySelector(
        ".animationTest__container"
      );
      if (animationContainer) {
        animationContainer.classList.remove("activate-overlay");
        onStartButtonClick();
      }
    }
  };
  const isLastPage = page === 2;

  return (
    <div className="animationTest__container">
      <span className="animationTest__title">
        {page === 1
          ? t('exporterTest.initial_screen.page_1.title')
          : t('exporterTest.initial_screen.page_2.title')}
      </span>
      <p className="animationTest__p">
        {page === 1
          ? t('exporterTest.initial_screen.page_1.description')
          : t('exporterTest.initial_screen.page_2.description')}
      </p>
      <div className="exporter-test__container-footer">
        <div className="animationTest__next" onClick={handleNextButtonClick}>
          <div className="animationTest__next-text">
            {" "}
            {isLastPage ? t('exporterTest.initial_screen.page_2.button') : t('exporterTest.initial_screen.page_1.button')}{" "}
          </div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
      <div className="animationTest__image-container">
        <img src={menStartTestImage} alt="Men Start Test" />
      </div>
    </div>
  );
};

export default AnimationTest;
