import React from 'react';
import videoRetail from '../../assets/videos/comparador/retail_1.mp4'; 
import videoEcommerce from '../../assets/videos/comparador/ecommerce.mp4'; 

import './PriceComparator.css';
import { useComparatorVideo } from '../../context/ComparatorVideoContext';

const TransitionAnimation: React.FC = () => {
  const {comparatorVideo} = useComparatorVideo()

  return (
    <div className="transition-animation__container">
      <div className="transition-animation__image-container-video">
        <video controls autoPlay width="700" height="500" src={comparatorVideo % 2 === 0 ? videoEcommerce : videoRetail} />
      </div>
    </div>
  );
};

export default TransitionAnimation;
