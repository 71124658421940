import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostApiUserActivate } from '../../api/interconectar-api';

export function useActivateAccountActions(): {
  activateUser: (token: string) => void;
} {
    
  const { mutate: activateUserAPI } = usePostApiUserActivate();
  const navigate = useNavigate();
  const activateUser = useCallback(
    (token: string): void => {
        activateUserAPI(
        {
          data: { token: token },
        },
        {
          onSuccess(data, variables, context) {
              navigate("/information", { state: { variation: "activate_account_ok" } });
          },
          onError(error, variables, context) {
              navigate("/information", { state: { variation: "error" } });
          },
        }
      )
    },
    [activateUserAPI, navigate]
  );

  return {
    activateUser,
  };
}
