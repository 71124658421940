// AnimationTest.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import menStartTestImage from '../../assets/images/men-start-test.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './Cubicaje.css';

interface Props {
  onStartButtonClick: () => void;
}

const AnimationCubicaje: React.FC<Props> = ({ onStartButtonClick }) => {
  const {t} = useTranslation()
  const [page] = useState(1);

  useEffect(() => {
    document.body.classList.add('dark-overlay');
    const animationContainer = document.querySelector('.animationTest__container');
    if (animationContainer) {
      animationContainer.classList.add('activate-overlay');
    }
  }, []);

  const handleNextButtonClick = () => {
    if (page === 1) {
      document.body.classList.remove('dark-overlay');
      const animationContainer = document.querySelector('.animationTest__container');
      if (animationContainer) {
        animationContainer.classList.remove('activate-overlay');
        onStartButtonClick();
      }
    }
  };
  const isLastPage = page === 3;

  return (
    <div className="animationTest__container">
      <p className='animationTest__titleCubicaje'>{t('cubicaje.initial_screen.page_1.title')}</p>
      <p className='animationTest__pCubicaje'>{t('cubicaje.initial_screen.page_1.description')}</p>
      <div className='cubicaje__container-footer'>
        <div className="animationTest__next" onClick={handleNextButtonClick}>
          <div className="animationTest__next-text"> {isLastPage ? t('cubicaje.initial_screen.page_2.button') : t('cubicaje.initial_screen.page_1.button')}{' '}</div>
          <FontAwesomeIcon icon={faArrowRight} color="black" />
        </div>
      </div>
      <div className="animationTest__image-container">
        <img src={menStartTestImage} alt="Men Start Test" />
      </div>
    </div>
  );
};

export default AnimationCubicaje;
