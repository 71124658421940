import React from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import print from "../../assets/images/print-solid.png";
import pdf from "../../assets/images/file-pdf-solid.png";
import "./CubicajeResumen.css";
import { IFormData } from "./Cubicaje";

interface Props {
  resetFormClick: () => void;
  formData: IFormData;
}

const CubicajeResumen: React.FC<Props> = ({ resetFormClick, formData }) => {
  const {t} = useTranslation()
  const altoStep2 = formData.altoStep2;
  const anchoStep2 = formData.anchoStep2;
  const filasMaximoPeso = formData.FilasMaximoPeso;
  const kCajasXFila = formData.KcajasXFila;
  const kPallet = formData.kPallet;
  const largoStep2 = formData.largoStep2;
  const pesoFilaCajas = formData.PesoFilaCajas;
  const pesoStep2 = formData.pesoStep2;
  const KFilaMaxAltura = formData.KFilaMaxAltura

  const cajasTotales = parseInt(KFilaMaxAltura) *  parseInt(kCajasXFila)
 const pesoTotal = cajasTotales * parseInt(pesoStep2) + 27
 const alturaTotal = parseInt(KFilaMaxAltura) * parseInt(altoStep2) + 150

  const items = [
    {
      id: 0,
      title: parseFloat(kPallet) * cajasTotales,
      description: t('cubicaje.resume.box_1.title'),
    },
    {
      id: 2,
      title: `${pesoTotal * parseFloat(kPallet)}${
        formData.palletData.unidadPeso
      }`,
      description: t('cubicaje.resume.box_2.title'),
    },
    {
      id: 3,
      title: `${alturaTotal}${formData.palletData.unidadMedida}`,
      description: t('cubicaje.resume.box_3.title'),
    },
  ];

  return (
    <div className="mainContainerCubicajeResumen">
      <h2>{t('cubicaje.resume.name')}</h2>
      <h3>{formData.referenceName}</h3>
      <div className="squareItemsContainer">
        {items.map((item) => (
          <div key={item.id} className="itemContainer">
            <h5>{item.title}</h5>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
      <div className="descriptionMainContainer">
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_1.title')}</h6>
          <div className="middleColumnText">
            <p>{formData.palletData.titlePallet}</p>
          </div>
          <div></div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_2.title')}</h6>
          <div className="middleColumnText">
            <p>
            {t('cubicaje.resume.item_2.subtitle_1')}: {anchoStep2} x {largoStep2} x {altoStep2}{" "}
              {formData.palletData.unidadMedida}
            </p>
          </div>
          <div className="rightColumn">
            <p>
              {t('cubicaje.resume.item_2.subtitle_2')}: {pesoStep2}
              {formData.palletData.unidadPeso}
            </p>
          </div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_3.title')}</h6>
          <div>
            <div className="middleColumnText">
              <p>{t('cubicaje.resume.item_3.subtitle_1')}: {kCajasXFila}</p>
              <p>
                {t('cubicaje.resume.item_3.subtitle_2')}: {cajasTotales}
              </p>
              <p>
              {t('cubicaje.resume.item_3.subtitle_3')}: {pesoTotal}
                {formData.palletData.unidadPeso}
              </p>
            </div>
          </div>
          <div className="rightColumn">
            <p>{t('cubicaje.resume.item_3.subtitle_4')}: {parseInt(KFilaMaxAltura)}</p>
            <p>
            {t('cubicaje.resume.item_3.subtitle_5')}: {alturaTotal}
              {formData.palletData.unidadMedida}
            </p>
          </div>
        </div>
        <div className="rowContainer">
          <h6>{t('cubicaje.resume.item_4.title')}</h6>
          <div className="middleColumnText">
            <p>{formData.contenedor?.titlePallet ?? ""}</p>
          </div>
          <div className="rightColumn">
            <p>{t('cubicaje.resume.item_4.subtitle_1')}: {kPallet}</p>
          </div>
        </div>
      </div>
      <div className="row frame-111-M3B">
        <div className="col-sm-3 botonesCubicajeContainer">
          <img className="print-solid-k5K" src={print} alt="print formulario" />
          <div className="buttonsCubicaje">{t('cubicaje.resume.print')}</div>
        </div>
        <div className="col-sm-3 botonesCubicajeContainer">
          <img
            className="file-pdf-solid-kDj"
            src={pdf}
            alt="downliad formulario"
          />
          <div className="buttonsCubicaje">{t('cubicaje.resume.download')}</div>
        </div>
        <div className="newCalButton" onClick={resetFormClick}>
          <div className="btn-cubicajeNuevo-text">{t('cubicaje.resume.button')}</div>
        </div>
      </div>
    </div>
  );
};

export default CubicajeResumen;
