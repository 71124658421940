/* eslint-disable react-hooks/exhaustive-deps */
import React, { MouseEventHandler, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import * as DO from './DataOptions';

import './PriceCalculator.css';
import CustomMessage from "../../core/CustomMessage/CustomMessage";
import { formatNumber, numberRegex } from "../../utils/helpers";
import { useVideo } from "../../context/VideoContext";

const PriceCalculatorStepTwo = ({ handleClickOpen, handleContinueClick }:{  handleClickOpen: MouseEventHandler<HTMLDivElement>, handleContinueClick: () => void }) => {
    const {t} = useTranslation()
    const {setVideoName} = useVideo()
    const [formTwoValues, setFormTwoValues] = useState<any>();
    const [transportation, setTransportation] = useState('');
    const [validRange, setValidRange] = useState(true);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
      } = useForm();

    const handleChangeConveyance = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setTransportation( e.target.value);
        setFormTwoValues({countryValue: !!formTwoValues?.countryValue ? formTwoValues?.countryValue : '', conveyance: e.target.value})
        localStorage.removeItem('formTwo')
        reset({countryValue: !!formTwoValues?.countryValue ? formTwoValues?.countryValue : '', containerType:'', internationalFreightValue:''})
    };

    const onSubmit = (data: any) => {
        if(!!data.countryValue) {
            if(data.conveyance === 'maritimo'){
                const maritimo = {
                    countryValue: data.countryValue,
                    conveyance: data.conveyance,
                    containerType: data.containerType,
                    internationalFreightValue: data.internationalFreightValue,
                }
                localStorage.setItem('formTwo', JSON.stringify(maritimo))
            }

            if(data.conveyance === 'multimodal'){
                const multimodal = {
                    countryValue: data.countryValue,
                    conveyance: data.conveyance,
                    internationalFreightValue: data.internationalFreightValue,
                }
                localStorage.setItem('formTwo', JSON.stringify(multimodal))
            }

            if(data.conveyance === 'aereo'){
                const aereo = {
                    countryValue: data.countryValue,
                    conveyance: data.conveyance,
                    totalWeight: data.totalWeight,
                    totalVolume: data.totalVolume,
                    internationalFreightValue: data.internationalFreightValue,
                }
                localStorage.setItem('formTwo', JSON.stringify(aereo))
            }
            
            handleContinueClick()
        }
    }

    useEffect( () => {
        const formTwo = localStorage.getItem('formTwo') ? JSON.parse(localStorage.getItem('formTwo')!) : ''
        if(Object.keys(formTwo).length) setFormTwoValues(formTwo)
    },[])

    useEffect(() => {
        if (formTwoValues) {
        reset((prev) => ({...prev, ...formTwoValues}));
        }
    }, [formTwoValues, reset]);

    useEffect( () => {
        if(!!formTwoValues?.conveyance) {
            setValue('conveyance', formTwoValues?.conveyance)
            setTransportation(formTwoValues?.conveyance);
        }
    },[formTwoValues?.conveyance])
    
    const europePorts = Object.entries(DO.europe_ports);
    
    const validateRange = !!errors?.internationalFreightValue && (errors?.internationalFreightValue.type === "min" || errors?.internationalFreightValue.type === "max")

    useEffect(() => {
        transportation === 'maritimo' || transportation === 'multimodal'
        ? setVideoName('secondBarcoVideo')
        : transportation === 'aereo'
        ? setVideoName('secondAvionVideo')
        : setVideoName('')
    },[transportation])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="exporter-test__read-only">
            <div className='price-calculator__container-main'>
                <div className="price-calculator___inner">
                    <div className="price-calculator___container">
                        <div className="row">
                            <div className='col-12'>
                                <span className='price-calculator__container-title' style={{ marginBottom: validateRange ? "0" : "24px"}}>{t('price_calculator.step_2.subtitle')}</span>
                                <CustomToolTip
                                    title=""
                                    tooltipText={t('price_calculator.step_2.subtitle_tooltip')}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        style={{fontSize: '15px', marginLeft: '8px'}}
                                    />
                                </CustomToolTip>
                            </div>
                        </div>

                        { !validRange &&
                            <CustomMessage
                                background={"#F4B400"}
                                width='100%'
                                textMessage={`Ten en cuenta que los valores actuales del flete internacional oscilan entre ${DO?.flete_range?.rangeTo!} a ${DO.flete_range.rangeFrom}.`}
                            />
                        }

                        <div className='row price-calculator__row mb-4'>
                            <div className='col-12 col-sm-12 col-md-6'>
                                <div className='price-calculator__field-with-icon'>
                                    <select
                                        {...register('countryValue', { required: true })}
                                        value={!!formTwoValues?.countryValue ? formTwoValues?.countryValue : null}
                                        onChange={ (e) => { setFormTwoValues({...formTwoValues, countryValue: e.target.value})}}
                                        className="price-calc__dropdown-general price-calculator__select-font"        
                                    >
                                        <option value="" selected disabled>{t('price_calculator.step_2.countryValue.placeholder')}</option>

                                        {europePorts.map(([region, countries]) => (
                                        <optgroup key={region} label={region} className='price-calculator__select-font'
                                        >
                                            {countries.map(country => (
                                            <option
                                                key={country}
                                                value={country}
                                                className='price-calculator__select-font'
                                            >
                                                {country}
                                            </option>
                                            ))}
                                        </optgroup> 
                                        ))}

                                    </select>
                                    
                                    <div style={{ marginLeft: "8px"}}>
                                        <CustomToolTip
                                            title=""
                                            tooltipText={t('price_calculator.step_2.countryValue.tooltip')}
                                        >
                                        <FontAwesomeIcon
                                            icon={faCircleQuestion}
                                            className="font-size: 18px"
                                        />
                                        </CustomToolTip>
                                    </div>                                    
                                </div>
                                {!!errors?.countryValue && 
                                    <div className="error-container">
                                        <InfoIcon fill="#F8A0D2" />
                                        <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                    </div>
                                }
                            </div>
                            <div className='col-12 col-sm-12 col-md-6'>
                                <select
                                    {...register('conveyance', { required: true })}
                                    value={!!formTwoValues?.conveyance ? formTwoValues?.conveyance : null}
                                    onChange={handleChangeConveyance}
                                    className="price-calc__dropdown-general price-calculator__select-font"
                                >
                                    <option value="" disabled selected hidden>{t('price_calculator.step_2.conveyance.placeholder')}</option>
                                    <option value="maritimo">Marítimo</option>
                                    <option value="multimodal">Multimodal</option>
                                    <option value="aereo">Aéreo</option>
                                </select>
                                {!!errors?.conveyance && 
                                    <div className="error-container">
                                        <InfoIcon fill="#F8A0D2" />
                                        <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        { transportation === 'aereo' &&
                            <div className="row price-calculator__row mb-4">
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <input
                                        {...register('totalWeight', { required: true })}
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_2.totalWeight.placeholder')}
                                        value={!!formTwoValues?.totalWeight ? formTwoValues?.totalWeight : ''}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormTwoValues({...formTwoValues, totalWeight: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.totalWeight && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <input
                                        type="text"
                                        className="price-calculator__textbox-general-col2"
                                        placeholder={t('price_calculator.step_2.totalVolume.placeholder')}
                                        value={!!formTwoValues?.totalVolume ? formTwoValues?.totalVolume : ''}
                                        {...register('totalVolume', { required: true })}
                                        onChange={ (e) => {
                                            if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                setFormTwoValues({...formTwoValues, totalVolume: formatNumber(e.target.value)})
                                            }
                                        }}
                                    />
                                    {!!errors?.totalVolume && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        <div className="row price-calculator__row mb-4">
                            { transportation === 'maritimo' &&
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <select
                                            {...register('containerType', { required: true })}
                                            value={!!formTwoValues?.containerType ? formTwoValues?.containerType : ''}
                                            onChange={ (e) => { setFormTwoValues({...formTwoValues, containerType: e.target.value})}}
                                            className="price-calc__dropdown-general price-calculator__select-font"
                                        >
                                            <option value="" disabled selected hidden>{t('price_calculator.step_2.containerType.placeholder')}</option>
                                            <option value="Contenedor Seco 20' (Dry-Van)'">Contenedor Seco 20' (Dry-Van)</option>
                                            <option value="Contenedor Seco 40' (Dry-Van)">Contenedor Seco 40' (Dry-Van)</option>
                                            <option value="Contenedor Seco 40' High Cube (Dry-Van)">Contenedor Seco 40' High Cube (Dry-Van)</option>
                                            <option value="Contenedor Seco 20' High Cube (Dry-Van)">Contenedor Seco 20' High Cube (Dry-Van)</option>
                                            <option value="Contenedor Refrigerado 40' (Reefer)">Contenedor Refrigerado 40' (Reefer)</option>
                                            <option value="Contenedor Refrigerado 40' High Cube (Reefer)">Contenedor Refrigerado 40' High Cube (Reefer)</option>
                                        </select>
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_2.containerType.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.containerType && 
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            }
                            { transportation !== '' &&
                                <div className='col-12 col-sm-12 col-md-6'>
                                    <div className='price-calculator__field-with-icon'>
                                        <input
                                            type="text"
                                            className="price-calculator__textbox-general-col2"
                                            placeholder={t('price_calculator.step_2.internationalFreightValue.placeholder')}
                                            {...register('internationalFreightValue', { required: true })}
                                            value={!!formTwoValues?.internationalFreightValue ? formTwoValues?.internationalFreightValue : ''}
                                            onChange={ (e) => {
                                                if (e.target.value === '' || numberRegex.test(e.target.value)) {
                                                    setFormTwoValues({...formTwoValues, internationalFreightValue: formatNumber(e.target.value)})
                                                }
                                            }}
                                            onBlur={ (e) => setValidRange(Number(e.target.value) >= 2000 && Number(e.target.value) <= 8000)}
                                        />
                                        <div style={{ marginLeft: "8px"}}>
                                            <CustomToolTip
                                                title=""
                                                tooltipText={t('price_calculator.step_2.internationalFreightValue.tooltip')}
                                            >
                                            <FontAwesomeIcon
                                                icon={faCircleQuestion}
                                                className="font-size: 18px"
                                            />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {!!errors?.internationalFreightValue && errors?.internationalFreightValue.type === "required" &&
                                        <div className="error-container">
                                            <InfoIcon fill="#F8A0D2" />
                                            <div className="error-message">{t('price_calculator.step_2.error_message')}</div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    
                    </div>
                </div>
            </div>
            <div className="price-calculator__container-footer">
                    <div
                        className="price-calculator__footer-link"
                        onClick={handleClickOpen}
                    >{t('price_calculator.footer.link')}</div>
                    <button type="submit" className="price-calc__next">
                        <div className="btn-test-next-text">{t('price_calculator.footer.button')}</div>
                        <FontAwesomeIcon icon={faArrowRight} color="black" />
                    </button>
                </div>
        </div>
        </form>
    )
}

export default PriceCalculatorStepTwo