import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import "react-phone-number-input/style.css";
import circleError from "../../assets/images/circle-exclamation-error.svg";
import "./Cubicaje.css";
import { UnidadMedidaM, UnidadMedidaPeso } from "./DataOptions";
import CustomMessage from "../../core/CustomMessage/CustomMessage";
import { IFormData, Pallet } from "./Cubicaje";
import CustomToolTip from "../../core/CustomToolTip/CustomToolTip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { numeric } from 'yup-numeric';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  faArrowRight,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import europallet360carga from "../../assets/videos/cubicaje/europallet360carga.gif";
import standardPalet360Cargado from "../../assets/videos/cubicaje/Standard-pallet-360-cargado.gif";
import europalet360desaprovechado from "../../assets/videos/cubicaje/euro-pallet-desaprovechado.gif";
import standardpalet360desaprovechado from "../../assets/videos/cubicaje/standard-pallet-desaprovechado.gif";
import { formatNumber, numberDotRegex, validateNumberRegex } from "../../utils/helpers";

interface Props {
  palletItem: Pallet;
  handleSecondStep: (status: boolean) => void;
  handleContinueClick: () => void;
  areErrors: React.MutableRefObject<boolean>;
  formData: IFormData;
  setFormData: Function;
  onCubicajeSelected: Function;
  setIsLegalModalVisible: Function;
}

interface Map {
  [key: string]: number
}
const CubicajeSeccionDos: React.FC<Props> = ({
  palletItem,
  handleSecondStep,
  handleContinueClick,
  formData,
  setFormData,
  areErrors,
  onCubicajeSelected,
  setIsLegalModalVisible,
}) => {
  const {t} = useTranslation()
  var convert = require("convert-units");
  const [showMessageLargo, setShowMessageLargo] = useState(false);
  const [showMessageAncho, setShowMessageAncho] = useState(false);
  const [showMessageYellowLargo, setShowMessageYellowLargo] = useState(false);
  const [showMessageYellowAncho, setShowMessageYellowAncho] = useState(false);
  const [showMessageAprovechamiento, setShowMessageAprovechamiento] =
    useState(false);
  const [showMessageWeight, setShowMessageWeight] = useState<boolean>(false);
  const [ShowMessageYellowAprov, setShowMessageYellowAprov] = useState(false);
  const [showMessageValidator, setShowMessageValidator] = useState(false);
  const [alto, setAlto] = useState<any | null>();
  const [largo, setLargo] = useState<any | null>();
  const [ancho, setAncho] = useState<any | null>();
  const [aprovCalc, setAprovCalc] = useState<number>();
  const [peso, setPeso] = useState<any>();
  const [unidadMedidaDistancia, setUnidadMedidaDistancia] = useState(
    palletItem.unidadMedida
  );
  const [unidadMedidaPesoSelected, setunidadMedidaPesoSelected] = useState(
    palletItem.unidadPeso
  );
  const [showMessageDesaprovechamiento, setShowMessageDesaprovechamiento] =
    useState(false);
  const [isBridgeError, setIsBridgeError] = useState(false);
  const [isLargeError, setIsLargeError] = useState(false);
  const [isTallError, setIsTallError] = useState(false);
  const [isWeightError, setIsWeightError] = useState(false);
  const [areShowErrorsVisible, setAreErrorsVisible] = useState<boolean>(false);
  const [showMessageErrorAprovechamiento, setShowMessageErrorAprovechamiento] =
    useState<boolean>(false);
  const [showMessageRedAprov, setShowMessageRedAprov] =
    useState<boolean>(false);

  const largoConvertRef = useRef<number>(0);
  const anchoConvertRef = useRef<number>(0);
  const superficieCajaRef = useRef<number>(0);
  const superficiePalletRef = useRef<number>(0);
  const aprovechamientoRef = useRef<number>(0);

  const titlePallet = palletItem.index;

  const pesoStep21: Map = {
    mg: 400000000,
    g: 4000000,
    kg: 4000,
    libra: 8818.49,
    onza: 141096,
  }


  const medidasStep2 = [
    {
      id: 0,
      title:  t("cubicaje.step_1.europalet.title"),
      medidas: [
        {
          unidadMedida: 'mm',
          ancho: 800,
          largo: 1200,
          alto: 2135,
        },
        {
          unidadMedida: 'cm',
          ancho: 80,
          largo: 120,
          alto: 213.5,
        },
        {
          unidadMedida: 'ft',
          ancho: 2.62467,
          largo: 3.937,
          alto: 7,
        },
        {
          unidadMedida: 'in',
          ancho: 31.496,
          largo: 47.244,
          alto: 84.05,
        }
      ]
    },
    {
      id: 1,
      title: t("cubicaje.step_1.americano.title"),
      medidas: [
        {
          unidadMedida: 'mm',
          ancho: 1000,
          largo: 1200,
          alto: 2135,
        },
        {
          unidadMedida: 'cm',
          ancho: 100,
          largo: 120,
          alto: 213.5,
        },
        {
          unidadMedida: 'ft',
          ancho: 3.2808,
          largo: 3.937,
          alto: 7,
        },
        {
          unidadMedida: 'in',
          ancho: 39.37,
          largo: 47.244,
          alto: 84.05,
        }
      ]
    }
  ]

  const medidasTitlePallet = medidasStep2.filter(({id}) => id === titlePallet)
  const unidadMedida = medidasTitlePallet[0]?.medidas?.filter(({unidadMedida}) => unidadMedida === unidadMedidaDistancia)
  const schema = yup.object().shape({
    altos:
      numeric()
      .min(1, 'Min value 1')
      .required()
      .typeError(t('cubicaje.step_2.required'))
      .max(unidadMedida[0].alto, `max ${unidadMedida[0]?.alto}`)
    ,
    anchos:
      numeric()
      .min(1, 'Min value 1')
      .required()
      .typeError(t('cubicaje.step_2.required'))
      .max(unidadMedida[0].ancho, `max ${unidadMedida[0].ancho}`)
    ,
    largos:
      numeric()
      .min(1, 'Min value 1')
      .required()
      .typeError(t('cubicaje.step_2.required'))
      .max(unidadMedida[0].largo, `max ${unidadMedida[0].largo}`)
    ,
    pesos:
      numeric()
      .min(1, 'Min value 1')
      .required()
      .typeError(t('cubicaje.step_2.required'))
      .max(pesoStep21[unidadMedidaPesoSelected], `max ${pesoStep21[unidadMedidaPesoSelected]}`)
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => {

    hanldeCalcLargo(parseFloat(formData.largoStep2));
    hanldeCalcAncho(parseFloat(formData.anchoStep2));
    hanldeCalcMedidas();
    verificarSiEjecutaAprov();

    handleSecondStep(true);
    areErrors.current = false;
    handleContinueClick();
}

  function hanldeCalcMedidas() {
    SuperficieOcupanCajas();
    SuperficiePalet();

    const aprovPercent = Aprovechamiento();
    if (aprovPercent < 100 && aprovPercent > palletItem.aprov) {
      setShowMessageDesaprovechamiento(true);
      onCubicajeSelected(
        false,
        palletItem.index === 0
          ? europalet360desaprovechado
          : standardpalet360desaprovechado
      );
    } else {
      setShowMessageDesaprovechamiento(false);
    }
  }

  function handleMeidaDis(value: string) {
    setUnidadMedidaDistancia(value);
  }

  function handleMeidaPeso(value: string) {
    setunidadMedidaPesoSelected(value);
  }

  //Calcular Largo con la unidad de medida del Pallet vs Seleccionado
  function hanldeCalcLargo(unidadIngresada: number) {
    let valor1 = unidadIngresada;
    if (isNaN(unidadIngresada)) {
      setShowMessageYellowLargo(false);
      setShowMessageLargo(false);
      areErrors.current = true;
    } else {
      if (unidadMedidaDistancia === palletItem.unidadMedida) {
        valor1 = unidadIngresada;
        largoConvertRef.current = valor1;
      } else {
        valor1 = convert(largo)
          .from(unidadMedidaDistancia)
          .to(palletItem.unidadMedida);
        largoConvertRef.current = valor1;
      }

      //Validar Valores despues de conversion.
      if (valor1 !== undefined && valor1 > palletItem.largo) {
        setShowMessageYellowLargo((status) => (status = true));
        setShowMessageLargo((status) => (status = true));
        handleSecondStep(false);
        setAreErrorsVisible(true);
        areErrors.current = true;
      } else {
        setShowMessageYellowLargo(false);
        setShowMessageLargo(false);
        handleSecondStep(true);
      }
    }
  }

  //Calcular Ancho con la unidad de medida del Pallet
  function hanldeCalcAncho(unidadIngresada: number) {
    let valorAncho = unidadIngresada;
    if (isNaN(unidadIngresada)) {
      setShowMessageYellowAncho(false);
      setShowMessageAncho(false);
      areErrors.current = true;
    } else {
      if (unidadMedidaDistancia === palletItem.unidadMedida) {
        valorAncho = unidadIngresada;
        anchoConvertRef.current = valorAncho;
      } else {
        valorAncho = convert(unidadIngresada)
          .from(unidadMedidaDistancia)
          .to(palletItem.unidadMedida);
        anchoConvertRef.current = valorAncho;
      }

      //Validar Valores despues de conversion.
      if (valorAncho !== undefined && valorAncho > palletItem.ancho) {
        setShowMessageYellowAncho((status) => (status = true));
        setShowMessageAncho((status) => (status = true));
        handleSecondStep(false);
        setAreErrorsVisible(true);
        areErrors.current = true;
      } else {
        setShowMessageYellowAncho(false);
        setShowMessageAncho(false);
        handleSecondStep(true);
      }
    }
  }

  function verificarSiEjecutaAprov() {
    if (
      largoConvertRef.current !== undefined &&
      largoConvertRef.current > 0 &&
      anchoConvertRef.current !== undefined &&
      anchoConvertRef.current > 0
    ) {
      const aprovechamiento = aprovechamientoRef.current;
      setAprovCalc(aprovechamiento);

      if (aprovechamiento > 100) {
        setShowMessageErrorAprovechamiento(true);
        setShowMessageAprovechamiento(false);
        setShowMessageRedAprov(true);
        areErrors.current = true;
      } else if (aprovechamiento < palletItem.aprov) {
        setShowMessageYellowAprov(false);
        setShowMessageAprovechamiento(true);
        onCubicajeSelected(
          false,
          palletItem.index === 0
            ? europallet360carga
            : standardPalet360Cargado
        );
        setShowMessageErrorAprovechamiento(false);
        areErrors.current = true;
      } else {
        setShowMessageYellowAprov(true);
        setShowMessageAprovechamiento(true);
        onCubicajeSelected(
          false,
          palletItem.index === 0
            ? europallet360carga
            : standardPalet360Cargado
        );
        setShowMessageErrorAprovechamiento(false);
        areErrors.current = false;
      }
      setAreErrorsVisible(true);
    }
  }

  //calculo Cantidad de cajas por ancho =parte entera de ( [ancho del palet] / [ancho de la caja])
  function CantidadCajasxAncho() {
    const anchoPallet = palletItem.ancho;
    const anchoCaja = anchoConvertRef.current;
    return (Math.trunc(anchoPallet / anchoCaja).toFixed(0));
  }

  //Cantidad de cajas por largo =parte entera de ( [largo del palet] / [largo de la caja]) Ejemplo: 800/230 = 3.4782; resultado = 3
  function CantidadCajasxLargo() {
    const largoPallet = palletItem.largo;
    const largoCaja = largoConvertRef.current;
    return (Math.trunc(largoPallet / largoCaja).toFixed(0));
  }

  //(kCajasAncho x [ancho de la caja] x (kCajasLargo x [largo de la caja] Ejemplo: (2 x 555) x (3x230) = 765900
  function SuperficieOcupanCajas() {
    const cajasXAncho = parseFloat(CantidadCajasxAncho());
    const anchoCaja = anchoConvertRef.current;
    const cajasXLargo = parseFloat(CantidadCajasxLargo());
    const largoCaja = largoConvertRef.current;
    const superficieCaja = cajasXAncho * anchoCaja * (cajasXLargo * largoCaja);
    superficieCajaRef.current = superficieCaja;

    return superficieCaja;
  }

  //[Superficie del palet] = [ancho del palet] x [largo del palet] Ejemplo: 1200 x 800 = 96
  function SuperficiePalet() {
    const anchoPallet = palletItem.ancho;
    const largoPallet = palletItem.largo;
    const superficiePallet = anchoPallet * largoPallet;
    superficiePalletRef.current = superficiePallet;

    return superficiePallet;
  }

  //Calculo para el Aprovechamiento Aprovechamiento: ([Superficie que ocupan las cajas] / [Superficie del palet] * 100
  function Aprovechamiento() {
    var superficieCaja = superficieCajaRef.current;
    var superficiePallet = superficiePalletRef.current;
    const aprovechamiento = parseFloat(
      ((superficieCaja / superficiePallet) * 100).toFixed(2)
    );
    aprovechamientoRef.current = aprovechamiento;

    return aprovechamiento;
  }

  //Calcular el desperdicio test
  function CalcularDesperdicio() {
    var aprovechamiento = aprovechamientoRef.current;
    return (100 - aprovechamiento).toFixed(2);
  }

  // const onValidateForm = () => {
  //   const isErrorAlto = !alto || alto === null || isNaN(alto);
  //   const isErrorAncho = !ancho || ancho === null || isNaN(ancho);
  //   const isErrorLargo = !largo || largo === null || isNaN(largo);
  //   const isErrorPeso = !peso || peso === null || isNaN(peso);
  //   const isErrorPesoPermitido =
  //     !peso || peso > formData.palletData.allowedWeight;

  //   setIsTallError(isErrorAlto);
  //   areErrors.current = isErrorAlto;
  //   setAreErrorsVisible(isErrorAlto);

  //   setIsBridgeError(isErrorAncho);
  //   areErrors.current = isErrorAncho;
  //   setAreErrorsVisible(isErrorAncho);

  //   setIsLargeError(isErrorLargo);
  //   areErrors.current = isErrorLargo;
  //   setAreErrorsVisible(isErrorLargo);

  //   setIsWeightError(isErrorPeso);
  //   areErrors.current = isErrorPeso;
  //   setAreErrorsVisible(isErrorPeso);

  //   areErrors.current = isErrorPesoPermitido;
  //   setAreErrorsVisible(isErrorPesoPermitido);
  //   setShowMessageWeight(isErrorPesoPermitido);

  //   if (peso && largo && alto && ancho && !areErrors.current) {
  //     hanldeCalcLargo(parseFloat(formData.largoStep2));
  //     hanldeCalcAncho(parseFloat(formData.anchoStep2));
  //     hanldeCalcMedidas();
  //     verificarSiEjecutaAprov();
  //     handleContinueClick();
  //   }
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="cubicaje_title-step_2 ">
        {t('cubicaje.step_2.subtitle')}{" "}
        <CustomToolTip
          title=""
          tooltipText={t('cubicaje.step_2.subtitle.tooltip')}
        >
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className="font-size: 15px"
          />
        </CustomToolTip>
      </div>
      {showMessageLargo && areShowErrorsVisible && (
        <CustomMessage
          background={`${showMessageYellowLargo ? "#F4B400" : "#2ECADF"}`}
          textMessage={t('cubicaje.step_2.message_1')}
        />
      )}

      {showMessageAncho && areShowErrorsVisible && (
        <CustomMessage
          background={`${showMessageYellowAncho ? "#F4B400" : "#2ECADF"}`}
          textMessage={t('cubicaje.step_2.message_2')}
        />
      )}

      {showMessageDesaprovechamiento && areShowErrorsVisible && (
        <CustomMessage
          background={`#F4B400`}
          textMessage={`${t('cubicaje.step_2.message_3a')} ${CalcularDesperdicio()}% ${t('cubicaje.step_2.message_3b')}.`}
        />
      )}

      {showMessageAprovechamiento && areShowErrorsVisible && (
        <CustomMessage
          background={`${ShowMessageYellowAprov ? "#F4B400" : "#2ECADF"}`}
          textMessage={`${t('cubicaje.step_2.message_4a')} ${aprovCalc}% ${t('cubicaje.step_2.message_3b')}.`}
        />
      )}

      {showMessageErrorAprovechamiento && areShowErrorsVisible && (
        <CustomMessage
          background={`${showMessageRedAprov ? "red" : "#2ECADF"}`}
          textMessage={`${t('cubicaje.step_2.message_5')} ${aprovCalc}%.`}
        />
      )}

      {showMessageValidator && areShowErrorsVisible && (
        <CustomMessage
          background={`#F4B400`}
          textMessage={t('cubicaje.step_2.message_6')}
        />
      )}

      {showMessageWeight && areShowErrorsVisible && (
        <CustomMessage
          background={`#F4B400`}
          textMessage={`${t('cubicaje.step_2.message_7')} ${formData.palletData.allowedWeight}${formData.palletData.unidadPeso}.`}
        />
      )}



      <div className="rowcubicaje-container">
        <div className="rowcubicaje-col rowcubicaje-min">
          <div className="CustomizedSelect__container">
            <div className="CustomizedSelect__title">{t('cubicaje.step_2.unit')}</div>
            <div className="country-info">
              <select
                style={{ maxWidth: 65 }}
                defaultValue={unidadMedidaDistancia}
                onChange={(e) => {
                  handleMeidaDis(e.currentTarget.value);
                }}
              >
                {UnidadMedidaM.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="rowcubicaje-col rowcubicaje-min rowcubicaje-rightmin shortInput" >
          <div className="shortInputDiv">
            <input
              {...register('largos')}
              type="text"
              className="inputItem shortInput"
              placeholder={t('cubicaje.step_2.long.placeholder')}
              value={largo || ""}
              onChange={(e) => {
                e.preventDefault();
                setAreErrorsVisible(false);
                const largoStep2 = e.currentTarget.value;

                if (numberDotRegex.test(largoStep2)) {
                  const formatLargo = formatNumber(largoStep2)
                  setLargo(formatLargo);
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    largoStep2: formatLargo,
                  }));

                  if (
                    alto !== null &&
                    ancho !== null &&
                    parseFloat(largoStep2) !== null &&
                    alto !== 0 &&
                    ancho !== 0 &&
                    parseFloat(largoStep2) !== 0
                  ) {
                    setShowMessageValidator(false);
                  }
                  setIsLargeError(false);
                }
              }}
            />
            {errors.largos && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{errors.largos?.message}</p>
              </div>
            )}
            {isLargeError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_2.required')}</p>
              </div>
            )}
          </div>
        </div>
        <div className="rowcubicaje-col rowcubicaje-half">
          <div className="shortInput">
            <input
              {...register('anchos')}
              type="text"
              className="inputItem"
              placeholder={t('cubicaje.step_2.width.placeholder')}
              value={ancho || ""}
              onChange={(e) => {
                e.preventDefault();
                setAreErrorsVisible(false);
                const anchoStep2 = e.currentTarget.value;

                if (numberDotRegex.test(anchoStep2)) {
                  const formatAncho = formatNumber(anchoStep2)
                  setAncho(formatAncho);
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    anchoStep2: formatAncho,
                  }));
                  if (
                    alto !== null &&
                    ancho !== null &&
                    largo !== null &&
                    alto !== 0 &&
                    ancho !== 0 &&
                    largo !== 0
                  ) {
                    setShowMessageValidator(false);
                  }
                  setIsBridgeError(false);
                }
              }}
            />
            {isBridgeError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_2.required')}</p>
              </div>
            )}
            {errors.anchos && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{errors.anchos?.message}</p>
              </div>
            )}
          </div>
        </div>
        <div className="rowcubicaje-col rowcubicaje-half" >
          <div className="shortInput">
            <input
              {...register('altos')}
              type="text"
              className="inputItem shortInput"
              placeholder={t('cubicaje.step_2.height.placeholder')}
              value={alto || ""}
              onChange={(e) => {
                e.preventDefault();

                setAreErrorsVisible(false);
                const altoStep2 = e.currentTarget.value;
            
                if (numberDotRegex.test(altoStep2)) {
                  const formatAlto = formatNumber(altoStep2)
                  setAlto(formatAlto);
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    altoStep2: formatAlto,
                  }));
                  if (
                    alto !== null &&
                    ancho !== null &&
                    largo !== null &&
                    alto !== 0 &&
                    ancho !== 0 &&
                    largo !== 0
                  ) {
                    setShowMessageValidator(false);
                  }
                  setIsTallError(false);
                }
                
              }}
            />
            {errors.altos && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{errors.altos?.message}</p>
              </div>
            )}
            {isTallError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_2.required')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="rowcubicaje-container rowcubicaje-container--doble">
        <div className="rowcubicaje-col">
          <div className="CustomizedSelect__container">
            <div className="CustomizedSelect__title">{t('cubicaje.step_2.unit')}</div>
            <div className="country-info">
              <select
                style={{ maxWidth: 65 }}
                defaultValue={unidadMedidaPesoSelected}
                onChange={(e) => {
                  const pesoStep2 = e.currentTarget.value;
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    pesoStep2,
                  }));
                  handleMeidaPeso(pesoStep2);
                }}
              >
                {UnidadMedidaPeso.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="rowcubicaje-col col-11 weightContainer">
          <div className="inputContainer">
            <input
              {...register('pesos')}
              type="text"
              className="inputItemWeight"
              placeholder={t('cubicaje.step_2.weight.placeholder')}
              value={peso || ""}
              onChange={(e) => {
                e.preventDefault();
                setAreErrorsVisible(false);
                const pesoStep2 = e.currentTarget.value;

                if (numberDotRegex.test(pesoStep2)) {
                  const formatPeso = formatNumber(pesoStep2)
                  setPeso(formatPeso);
                  setFormData((prevData: IFormData) => ({
                    ...prevData,
                    pesoStep2: formatPeso,
                  }));
                  setIsWeightError(false);
                }
              }}
            />
            {errors.pesos && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{errors.pesos?.message}</p>
              </div>
            )}
            {isWeightError && (
              <div className="container errorContainer">
                <img src={circleError} alt="error-icon" />
                <p className="errorInputValidation">{t('cubicaje.step_2.required')}</p>
              </div>
            )}
          </div>
        </div>
        </div>
        <div className="mainContainerFooter">
          <div
            className="see_legals"
            onClick={() => setIsLegalModalVisible(true)}
          >
            <p>{t('cubicaje.legal')}</p>
          </div>
          <button type="submit" className="cubicaje-information__next">
            <div className="btn-test-next-text">{t('cubicaje.button')}</div>
            <FontAwesomeIcon icon={faArrowRight} color="black" />
          </button>
        </div>
      
    </form>
  );
};

export default CubicajeSeccionDos;
