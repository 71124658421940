import React, { useEffect, useState } from "react";
import "../Preferences.css";
import market from "../../../assets/images/market.svg";
import product from "../../../assets/images/shoping.svg";
import country from "../../../assets/images/paises.svg";
import figures from "../../../assets/images/publicaciones.svg";
import { preferencesDataList } from "../preferencesData";

const tabs = [
  { id: 0, title: "Tipo de Mercado", image: market },
  { id: 1, title: "Tipo de Producto", image: product },
  { id: 2, title: "Países", image: country },
  { id: 3, title: "Publicaciones", image: figures },
  { id: 4, title: "Normativas EU", image: market },
];

const PreferencesEditor = ({
  onHandleBack,
  preferencesList,
}: {
  onHandleBack: () => void;
  preferencesList: any[];
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState<any[]>(preferencesList);
  const [dataList, setDataList] = useState<any>(preferencesDataList);
  const [dataListFiltered, setDataListFiltered] = useState<any>([]);

  const toggleTagSelection = (tag: any, parentCategory: string) => {
    const itemFounded: any = selectedTags.find(
      (e: any) => e.tag.label === tag.label && e.tag.parent === tag.parent
    );
    if (itemFounded) {
      const itemsFiltered = selectedTags.filter(
        (item: any) => item !== itemFounded
      );
      setSelectedTags(itemsFiltered);
    } else {
      setSelectedTags([
        ...selectedTags,
        { tag, parentCategory: parentCategory },
      ]);
    }
  };

  const onSaveTags = () => {
    window.localStorage.setItem(
      "preferencesSelected",
      JSON.stringify(selectedTags)
    );
    setSelectedTags([]);
  };

  const isItemSelected = (itemSelected: any) => {
    const itemFounded = selectedTags.find(
      (e) =>
        e.tag.label === itemSelected?.label &&
        e.tag.parent === itemSelected.parent
    );
    if (itemFounded) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    onFilterData();
  }, [activeTab, selectedTags]);

  const onFilterData = () => {
    const filterSelectedValue = tabs[activeTab];
    const elementsFounded = dataList.filter(
      (e: any) => e?.label === filterSelectedValue.title
    );
    setDataListFiltered(elementsFounded);
    setDataList(preferencesDataList);
  };

  return (
    <>
      <div className="navtabs">
        {tabs.map((tab, index) => (
          <span
            onClick={() => setActiveTab(tab.id)}
            key={tab.id}
            className={`navtabs__tab ${activeTab === index ? "active" : ""}`}
          >
            <img src={tab.image} alt={tab.title} className="navtabs__tab__icon" />
            <div className="navtabs__tab__text">{tab.title}</div>
          </span>
        ))}
      </div>
      <div className="preferences__tag-container">
        {dataListFiltered[0]?.options?.map((group: any, groupIndex: number) => (
          <div key={groupIndex} className="preferences__tag-group">
            <span className="preferences__tag-title">{group.subtitle}</span>
            <div className="preferences__container preferencesChildrenItemsContainer">
              {group?.values.map((option: any) => (
                <div className="preferences__tags">
                  <div
                    key={`${groupIndex}_option`}
                    className={`preferences__tag ${
                      isItemSelected(option) ? "preferences__tag--selected" : ""
                    }`}
                    onClick={() =>
                      toggleTagSelection(option, dataListFiltered[0].label)
                    }
                  >
                    {option.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="preferences__button-container">
        <button className="preferences__btn-exit" onClick={onHandleBack}>
          Salir de edición
        </button>
        <button
          onClick={() => {
            onSaveTags();
            onHandleBack();
          }}
          className="preferences__btn-save"
        >
          Guardar preferencias
        </button>
      </div>
    </>
  );
};

export default PreferencesEditor;
