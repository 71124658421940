import React, { useEffect, useState } from "react";
import "./noticias.css";
import todo from "../../assets/images/todo.svg";
import market from "../../assets/images/market.svg";
import product from "../../assets/images/shoping.svg";
import country from "../../assets/images/paises.svg";
import figures from "../../assets/images/publicaciones.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as LinkSolid } from "../../assets/images/link-solid.svg";
import { ReactComponent as Bookmark } from "../../assets/images/bookmark-regular.svg";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { News } from "../../models/news.model";
import { useNavigate, useParams } from "react-router-dom";
import Suggestions from "../../components/Suggestions/Suggestions";
import { useGetApiNews } from "../../api/news.api";

const NoticiasPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const keywordFromParams = params.keyword;
  const handleButtonClick = (url: string) => {
    navigate(url);
  };
  const [activeTab, setActiveTab] = useState<number>(0); // Estado para almacenar el índice de la pestaña activa
  const [content, setContent] = useState<News[]>([]); // Estado para enseñar el contenido de la pestaña activa

  const tabs = [
    { id: 0, title: "Todo", image: todo },
    { id: 1, title: "Mercados", image: market },
    { id: 2, title: "Productos", image: product },
    { id: 3, title: "Países", image: country },
    { id: 4, title: "Publicaciones", image: figures },
    { id: 5, title: "Otro", image: market },
  ];

  const { data: dataNewsList, isLoading, mutate: newsList } = useGetApiNews()

  const getNewsAccordingToTab = (index: number): News[] => {
    const arrayNews: News[] = dataNewsList as News[];
    if (keywordFromParams) {
      const itemsFiltered = arrayNews.filter((e) =>
        e.title.toLowerCase().includes(keywordFromParams.toLowerCase())
      );
      return itemsFiltered;
    } else {
      return arrayNews;
    }
  };

  const handleTabClick = (index: number) => {
    setActiveTab(index); // Actualizar el tab activo al hacer clic en uno nuevo
    const newContent = getNewsAccordingToTab(index); // Obtener el contenido según el tab seleccionado
    setContent(newContent); // Actualizar el estado del contenido
  };

  const handleNextTabClick = (index: number) => {
    let nextTab = index + 1;
    if (nextTab >= tabs.length) {
      nextTab = 0; // Reiniciar cuando se termine
    }
    setActiveTab(nextTab); // Actualizar la pestaña activa
    const newContent = getNewsAccordingToTab(nextTab); // Obtener el contenido para la siguiente pestaña
    setContent(newContent); // Actualizar el estado del contenido
  };

  useEffect(() => {
    const data = getNewsAccordingToTab(activeTab);
    setContent(data);
  }, [activeTab, getNewsAccordingToTab, keywordFromParams]);

  useEffect(() => newsList({}, {
    onSuccess(data, variables, context) {

    }
    , onError(error, variables, context) {

    },
  }), [])
  useEffect(() => setContent(getNewsAccordingToTab(activeTab)), [dataNewsList])

  return (
    <div>
      <section>
        <div className="container">
          <div className="row">
            <span className="banner__title">Noticias</span>
          </div>
        </div>
      </section>
      <div className="content-news-sgg">
        <div className="main">
          <div className="container-main">
            <div className="tabs">
              {tabs.map((tab, index) => (
                <span
                  key={tab.id}
                  className={`tab ${activeTab === index ? "active" : ""}`} // Aplica la clase 'active' si la pestaña está activa
                  onClick={() => handleTabClick(index)} // Maneja el clic en la pestaña
                >
                  <img src={tab.image} alt={tab.title} className="tab-icon" />
                  <div className="tab-text">{tab.title}</div>
                </span>
              ))}
              <span
                className="tab-next"
                onClick={() => handleNextTabClick(tabs[activeTab].id)}
              >
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  className="btn-next"
                />
              </span>
            </div>

            {/* Aquí renderizas el contenido correspondiente al tab activo */}
            {content?.map((item, index) => (
              // Renderizar el contenido aquí según el estado 'content'
              // Por ejemplo:
              <div
                key={index}
                className="noticias_hover"
                onClick={() => handleButtonClick("/noticia/" + item.id)}
              >
                {index === 0 && (
                  <div className="tab-content-filter">
                    <span className="preference">
                      <a href="/#" className="preference-text">
                        Nombre preferencia
                      </a>
                    </span>
                    <span className="preference">
                      <a href="/#" className="preference-text">
                        Nombre preferencia
                      </a>
                    </span>
                    <span className="preference">
                      <a href="/#" className="preference-text">
                        Nombre preferencia
                      </a>
                    </span>
                    <span>
                      <a href="#tab-content" className="ver-todos-text">
                        Ver todos
                      </a>
                    </span>
                  </div>
                )}
                <div id="tab-content" className="tab-content">
                  <div className="tab-content-main">
                    <div className="tab-content-main-photo">
                      <img
                        src={item.image}
                        alt={item.title}
                        style={{
                          marginTop: index === 0 ? "10px" : "10px",
                          marginBottom: index === 0 ? "10px" : "10px",
                          width: index === 0 ? "244px" : "109px", // Aplicar diferentes anchos según la posición
                          height: index === 0 ? "180px" : "109px", // Aplicar diferentes anchos según la posición
                          alignSelf: "stretch",
                          marginLeft: index !== 0 ? "1em" : undefined, // Aplicar marginLeft condicionalmente
                        }}
                      />
                    </div>
                    <div className="tab-content-main-summary">
                      <div className="tab-content-main-title">{item.title}</div>
                      <div className="tab-content-main-text">S
                        {item.summary}
                      </div>
                      <div
                        className="tab-content-main-footer"
                        style={{ gap: index === 0 ? "1em" : "5em" }} // Aplicar diferentes anchos según la posición
                      >
                        <div className="tab-content-footer-date">
                          {item.date}
                        </div>
                        <div className="tab-content-footer-tag">
                          ● {item.source}
                        </div>
                        <div className="content-btn-news">
                          <LinkSolid className="tab-content-footer-share" />
                          <Bookmark className="tab-content-footer-save" />
                        </div>
                      </div>
                      <div className="tab-content-footer-filter">
                        <span className="preference-news">
                          <a href="/#" className="preference-text">
                            Nombre preferencia
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="suggestions">
          <Suggestions />
        </div>
      </div>
    </div>
  );
};

export default NoticiasPage;
