import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
export const AXIOS_INSTANCE = Axios.create({ baseURL: process.env.REACT_APP_API_URL });

  // Add a request interceptor
  AXIOS_INSTANCE.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization =  `Bearer ${token}`;
     
    return config;
  });

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(
    ({ data }) => data,
  );

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by react Query');
  };

  return promise;
};

export default customInstance;

export interface ErrorType<Error> extends AxiosError<Error> {}