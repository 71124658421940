import React from "react";
import { useTranslation } from 'react-i18next';
import paper from "../../../assets/images/papper.svg";
import "../generalStyles.css";

const InformeTecnico = () => {
  const {t} = useTranslation()
  const width = window.innerWidth;

  const checkRowContainer = width > 1220 ? "sectionDescription" : "sectionDescriptionResponsive"

  return (
    <div className="container p0 sectionMainContainer">
      <div className="col-6 mt-5">
        {/* <img src={paper} alt="paper-icon" /> */}
        <h4 className="sectionText">{t('services.tab_2.sectionTitle')}</h4>
        <p className={checkRowContainer}>{t('services.tab_2.sectionDescription')}</p>
        <div className="rowMainContainer">
          <p className="itemTitle">{t('services.tab_2.customsRequirementsTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.customsRequirementsList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.customsRequirementsList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.customsRequirementsList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.customsRequirementsList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.customsRequirementsList.item_5')}</p>
          </div>
        </div>
        <div className="rowMainContainer">
          <p className="itemTitle">{t('services.tab_2.logisticsInformationTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.logisticsInformationList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.logisticsInformationList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.logisticsInformationList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.logisticsInformationList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.logisticsInformationList.item_5')}</p>
          </div>
        </div>
      </div>
      <div className="col-6 pr-5 mt-2">
        <div className="rowMainContainer">
          <p className="itemTitle">{t('services.tab_2.healthLegalAnalysisTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_3')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_4')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_5')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_6')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_7')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_8')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_9')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_10')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_11')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.healthLegalAnalysisList.item_12')}</p>
          </div>
        </div>
        <div className="rowMainContainer">
          <p className="itemTitle">{t('services.tab_2.qualityRequirementsTitle')}</p>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.qualityRequirementsList.item_1')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.qualityRequirementsList.item_2')}</p>
          </div>
          <div className="subitemTextContainer">
            <div className="itemCircleIcon" />
            <p className="itemText">{t('services.tab_2.qualityRequirementsList.item_3')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformeTecnico;
