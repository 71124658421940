import React from 'react';
import Select, { components } from 'react-select';
import { ReactComponent as CloseIcon } from '../../assets/images/circle-xmark.svg';
import './multi-select.css';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

export interface OptionSwitch {
    label: string;
    score: number;
}

export interface Option {
    label: string;
    value: string;
    parent?: string;
    score?: number;
}

interface Props {
    data: Option[];
    placeholder?: string;
    width?: string;
    onChange?: (selectedOptions: Option[]) => void;
}

const CustomMultiSelect: React.FC<Props> = ({ data, placeholder, width = '100%', onChange }) => {

    const optionsWithDisabledParents: Option[] = data.map((option) => ({
        ...option,
        isDisabled: !option.parent,
    }));

    const MultiValueRemove = (props: any) => {
        const { innerProps } = props;
        return (
            <components.MultiValueRemove {...props}>
                <div className="close-icon-container">
                    <CloseIcon {...innerProps} className="close-icon" />
                </div>
            </components.MultiValueRemove>
        );

    };

    const formatOptionLabel = (option: Option) => {
        if (option.parent) {
            return <div style={{ paddingLeft: '1em' }}>{option.label}</div>;
        }
        return <strong>{option.label}</strong>;
    };

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            width: width,
            height: '50px',
            backgroundColor: '#425563',
            borderColor: '#425563',
            boxShadow: '0px 0.125em 0.25em rgba(0, 0, 0, 0.1)',
            marginTop: '0',
            cursor: 'pointer',

        }),
        valueContainer: (provided: any) => ({
            ...provided,
            overflowY: 'auto',
            height: 'inherit',
            '&::-webkit-scrollbar': {
                width: '12px',
                height: '50%',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#425563',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#2E3C46',
            },
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: '#BBC2C7',
            fontSize: '16px',
            fontFamily: 'Barlow',
            width: width,
            cursor: 'pointer',
            backgroundColor: '#425563',

            ':hover': {
                backgroundColor: '#2E3C46',
            },
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: '#2E3C46',
            boxShadow: '0px 0.125em 0.25em rgba(0, 0, 0, 0.1)',
            borderRadius: '8x',
            ':hover': {
                backgroundColor: 'transparent',
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: '#5E6F7A',
            color: '#BBC2C7',
            width: width,
            position: 'absolute', // o 'fixed'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#BBC2C7',
            fontFamily: 'Barlow',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '15px'
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: '#2E3C46',
            boxShadow: '0px 0.125em 0.25em rgba(0, 0, 0, 0.1)',
            borderRadius: '12x',
            color: '#D4D8DB',
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            // color: '#D4D8DB',
        }),
        menuList: (provided: any) => ({
            ...provided,
            backgroundColor: '#425563',
        }),
    };

    return (
        <Select
            closeMenuOnSelect={false}
            defaultValue={[]}
            isMulti
            options={optionsWithDisabledParents}
            className="custom-multi-select"
            components={{ MultiValueRemove }}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            placeholder={placeholder}
            onChange={(selectedOptions) => {
              // Call the passed onChange prop with the selected options
              if (onChange) {
                  // Ensure we are working with an array, even if it's empty
                  const optionsArray = Array.isArray(selectedOptions) ? selectedOptions : [];
                  onChange(optionsArray);
              }
            }}
        />
    );
};

export default CustomMultiSelect;