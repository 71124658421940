import React, { } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import './Suggestions.css';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as Bookmark} from '../../assets/images/bookmark-regular.svg';

const Suggestions: React.FC = () => {
  const {t} = useTranslation()

  return (
    <div className="suggest ">
      <div className="suggest__row ">
        <div className="suggest__header ">
          <h3 className="suggest__header__title ">{t('suggestions.title')}</h3>
        </div>
        <div className="suggest__item">
          <div className="suggest__body">
            <h4 className="suggest__body__title">EU trade relationships by country</h4>
            <p className="suggest__body__text ">
              The EU and New Zealand have today signed their free trade agreement (FTA), which will deliver will deliver will deliv sign...
            </p>
          </div>
          <div className="suggest__footer ">
            <span className="suggest__date ">August 21</span>
            <div className='suggest__actions '>
              <Bookmark className="suggest__save " />
            </div>
          </div>
        </div>
        <div className="suggest__more suggestions-see-more-button">
          <div className="suggest__more__text suggestions-see-more-text">{t('suggestions.more')}</div>
          <FontAwesomeIcon className='suggest__more__svg' icon={faArrowRight} color="black" />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
