import { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { createTheme } from '@mui/material/styles';
import { MUIDataTableTextLabels } from '../interfaces';

const fontColor = '#ECEEEF'

// Define a Spanish localization object
const spanishLocalization: MUIDataTableTextLabels = {
  body: {
    noMatch: "Lo siento, no se encontraron registros",
    toolTip: "Sort",
    columnHeaderTooltip: (column: MUIDataTableColumn) => `Sort for ${column.label}`
  },
  pagination: {
    next: "Siguiente página",
    previous: "Página anterior",
    rowsPerPage: "Filas por página:",
    displayRows: "de",
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Descargar CSV",
    print: "Imprimir",
    viewColumns: "Ver Columnas",
    filterTable: "Filtrar Tabla",
  },
  filter: {
    all: "Todos",
    title: "FILTROS",
    reset: "REINICIAR",
  },
  viewColumns: {
    title: "Mostrar Columnas",
    titleAria: "Mostrar/Ocultar Columnas de la Tabla",
  },
  selectedRows: {
    text: "fila(s) seleccionada(s)",
    delete: "Eliminar",
    deleteAria: "Eliminar Filas Seleccionadas",
  },
  
};

export const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: fontColor,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: fontColor,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: fontColor,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'white !important',
          backgroundColor: 'var(--gray-700, #425563)', // Change toolbar background color
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: fontColor,
          backgroundColor: 'var(--gray-700, #425563)', // Change toolbar background color
          '& .MuiFormControlLabel-label': {
            color: 'white'
          }
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #2E3C46)',
          '@media (max-width: 768px)': {
            border: '0',
            padding: '20px'
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: fontColor,
          '@media (max-width: 768px)': {
            padding: '0 0 20px !important',
            borderTop: 'solid 22px rgb(67 85 99) !important'
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: fontColor,
          borderTop: 'solid 16px var(--gray-800, #2E3C46)',
          borderBottom: 'solid 16px var(--gray-800, #2E3C46)',
          padding: '16px 0',
          
          '&:first-of-type': {
            borderRadius: '16px 0 0 16px'
          },
          '&:last-child': {
            borderRadius: '0 16px 16px 0'
          },
          '@media (max-width: 768px)': {
            borderRadius: '0',
            border: '0',
            padding: '0 20px 10px',
            display: 'flex !important',
            gap: '15px',
            flexDirection: 'column !important',
            
            '&:first-of-type': {
              borderRadius: '0'
            },
            '&:last-child': {
              borderRadius: '0'
            },
          },
          
        },
        
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: fontColor,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: 'var(--orange-200, #FFCABF) !important',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: fontColor,
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change toolbar background color
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #2E3C46)', // Change table background color
          padding: '16px 8px 0',
          borderBottom: 'none !important',

          '& .MuiButtonBase-root': {
            padding: '0',
            textTransform: 'none',
            color: 'var(--gray-100, #ECEEEF) !important',
            fontFamily: 'Barlow',
            fontSize: '15.19px',
            fontWeight: '700',
            lineHeight: '23.926px' 
          },
        },
      },
    },
    MUIDataTableViewCol: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
          
        },
      },
    },
    MUIDataTableResize: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
          
        },
      },
    },
    MUIDataTableJumpToPage: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
          
        },
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
          
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MUIDataTableFilter-checkboxListTitle': {
            color: 'white',
          }
        },
      },
    },
    MUIDataTableFilterList: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Change table background color
          padding: '20px 0'
          
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
          
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #425563)', // Change table background color
        },
      },
    },
    MUIDataTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #2E3C46)', // Change table background color
          color: fontColor,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--gray-700, #2E3C46)', // Change table background color
          color: fontColor,
          padding: '0 40px',
          boxShadow: 'none',
          '@media (max-width: 768px)': {
            padding: '0 0 50px',
            // maxWidth: '50%'
          },
        },
      },
    },
    MuiTablePagination: { // This targets the pagination component
      styleOverrides: {
        root: {
          color: fontColor, // Change to your preferred color
          // If you also want to change the color of the select input and actions
          '& .MuiTablePagination-select': {
            color: fontColor, // Change to your preferred color
          },
          '& .MuiTablePagination-selectLabel': {
            margin: '0'
          },
          '& .MuiTablePagination-displayedRows': {
            margin: '0'
          },
          '& .MuiTablePagination-selectIcon': {
            color: fontColor, // Change to your preferred color
          },
          '& .MuiTypography-body2': {
            color: fontColor, // Change to your preferred color
          },
        },
      },
    },
    // You can add more components here
  },
});

export const options: MUIDataTableOptions = {
  filterType: 'checkbox',
  filter: true,
  responsive: 'vertical',
  //responsive: standard | vertical | simple
  rowsPerPage: 5,
  rowsPerPageOptions: [ 5, 10, 15, 100 ],
  selectableRows: 'none',
  textLabels: spanishLocalization,
};