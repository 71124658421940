import React from "react";
import { useTranslation } from 'react-i18next';
import InformationComponent from "../../core/Information/Information";
import { useLocation } from "react-router-dom";
import "./information-page.css";

interface InformationPageProps {
  variation: "error" | "register_ok" | "email_register" | "email_password";
}

const InformationPage: React.FC<InformationPageProps> = () => {
  const {t} = useTranslation()

  let imageName = "";
  let title = "";
  let text = "";
  let btnText = "";
  let lblText = "";
  let urlText = "";

  const location = useLocation();
  const variation = location.state?.variation || "defaultValue";

  switch(variation)
  {
    case "register_ok":
      imageName = "register_ok";
      title = t('register.information.register_ok.title');
      text = t('register.information.register_ok.text');
      btnText = t('register.information.register_ok.button');
      urlText = "/home";
      break;
    case "email_register":
      imageName = "email_register";
      title = t('register.information.email_register.title');
      text = "";
      lblText = t('register.information.email_register.text');
      urlText = "/test";
      break;
    case "email_password":
      imageName = "email_password";
      title = t('register.information.email_password.title');
      text = "";
      lblText = t('register.information.email_password.text');
      urlText = "/login";
      break;
    case "activate_account_ok":
      imageName = 'register_ok';
      title = t('register.information.activate_account_ok.title');
      text = t('register.information.activate_account_ok.text');
      btnText = t('register.information.activate_account_ok.button');
      urlText='/login'
      break;
    case "recover_password_ok":
      imageName = 'register_ok';
      title = t('login.recover.success.title');
      text = t('login.recover.success.text');
      break;
    case "reset_password_ok":
      imageName = 'register_ok';
      title = t('login.reset.success.title');
      text = t('login.reset.success.text');
      btnText = t('register.information.activate_account_ok.button');
      urlText='/login'
      break;
    case "error":
    case "defaultValue":
      imageName = "default";
      title = t('register.information.error.title');
      text = t('register.information.error.text');
      lblText = t('register.information.error.button');
      urlText = "/home";
      break;
  }

  return (
    <div className="informationContainerPage">
      <InformationComponent
        imageName={imageName}
        title={title}
        text={text}
        btnText={btnText}
        lblText={lblText}
        urlText={urlText}
      />
    </div>
  );
};

export default InformationPage;
