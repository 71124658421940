import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import products1 from '../../assets/videos/comparador/products1.json'
import products2 from '../../assets/videos/comparador/products2.json'
import products3 from '../../assets/videos/comparador/products3.json'
import products4 from '../../assets/videos/comparador/products4.json'
import products5 from '../../assets/videos/comparador/products5.json'
import products6 from '../../assets/videos/comparador/products6.json'
import { options, theme } from '../../utils/dataTableConfig';

import { ProductsData } from '../../interfaces/index';

import 'react-phone-number-input/style.css';
import './PriceComparator.css';

import CustomToolTip from '../../core/CustomToolTip/CustomToolTip';
import fileArrowDown from '../../assets/images/file-arrow-down-solid.svg';
import { useForm } from '../../context/FormContext';
import ModalLegalResumen from './ModalLegalResumen';

const imagePath = `../assets/images/`
const defaultPathIcons = `${imagePath}store-solid.svg`

const PriceComparatorTable: React.FC = () => {
  const {t} = useTranslation()
  const { formData } = useForm();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { productName, id } = formData;
  const [imageError, setImageError] = useState<{ [key: number]: boolean }>({});

  const [ loadingProducts ] = useState(false); //TODO: use Redux 
  const [ data, setData ] = useState<ProductsData[]>([]);

  // const dispatch = useDispatch();
  // const { productsData, loadingProducts } = useSelector((state) => state.products);
  
  const columns = [
  {
    label: t('price_comparator.table.col_1.title'),
    name: 'image',
    options: {
        filter: false,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
            const productImage = data[dataIndex]['imagen'];

            const hasError = imageError[dataIndex];
            
            const imageBackground = hasError
            ? `url(${defaultPathIcons}) lightgray 50%/cover no-repeat`
            : `url(${imagePath}${productImage}.png) lightgray 50%/cover no-repeat`;

            return (
              <CustomToolTip title="" tooltipText={t('price_comparator.table.col_1.tooltip')}>
                <div className="price-comparator__image-column" style={{
                  width: '80px',
                  height: '80px',
                  borderRadius: '48px',
                  background: imageBackground
                }}>
                </div>
              </CustomToolTip>
            );
        }
    }
  },
  {
    label: t('price_comparator.table.col_2.title'),
    name: 'producto_description',
    options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
            const productName = data[dataIndex]['producto_name'];
            const productSubtitle = data[dataIndex]['producto_subtitle'];
            const productDescription = data[dataIndex]['producto_description'];
            
            return (
              <>
                <div className='price_comparator-col1-title'>{productName}</div>
                <div className='price_comparator-col1-subtitle'>{productSubtitle}</div>
                <div className='price_comparator-col1-description'>{productDescription}</div>
              </>
            );
        }
      }
    },
    {
      label: t('price_comparator.table.col_3.title'),
      name: 'precio_sin_iva',
      options: {
          filter: false,
          sort: true,
          empty: true,
          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
              const precioSinIvaGr = data[dataIndex]['precio_sin_iva_gr'];
              const monedaSimbolo = data[dataIndex]['moneda_simbolo'];
              const pesoGr = data[dataIndex]['peso_gr'];
              const pesoUnidadGr = data[dataIndex]['peso_unidad_gr'];

              const precioSinIvaKg = data[dataIndex]['precio_sin_iva_kg'];
              const pesoKg = data[dataIndex]['peso_kg'];
              const pesoUnidadKg = data[dataIndex]['peso_unidad_kg'];
              
              return (
                  <div className='price_comparator-box-1'>
                      <p>
                        <span className='price_comparator-box-price'>{precioSinIvaGr + ' ' + monedaSimbolo + ' '}</span> 
                        <span className='price_comparator-box-weight'>{pesoGr +''+ pesoUnidadGr}</span>
                      </p>
                      <p>
                        <span className='price_comparator-box-price'>{precioSinIvaKg + ' ' + monedaSimbolo + ' '}</span>
                        <span className='price_comparator-box-weight'>{pesoKg +''+ pesoUnidadKg}</span>
                      </p>
                  </div>
                  
              );
          }
      }
    },
    {
      label: t('price_comparator.table.col_4.title'),
      name: 'precio_con_iva',
      options: {
          filter: false,
          sort: true,
          empty: true,
          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
              const precioConIvaGr = data[dataIndex]['precio_con_iva_gr'];
              const monedaSimbolo = data[dataIndex]['moneda_simbolo'];
              const pesoGr = data[dataIndex]['peso_gr'];
              const pesoUnidadGr = data[dataIndex]['peso_unidad_gr'];

              const precioConIvaKg = data[dataIndex]['precio_con_iva_kg'];
              const pesoKg = data[dataIndex]['peso_kg'];
              const pesoUnidadKg = data[dataIndex]['peso_unidad_kg'];
              
              return (
                <div className='price_comparator-box-2'>
                      <p>
                        <span className='price_comparator-box-price'>{precioConIvaGr + ' ' + monedaSimbolo + ' '}</span>
                        <span className='price_comparator-box-weight'>{pesoGr +''+ pesoUnidadGr}</span>
                      </p>
                      <p>
                        <span className='price_comparator-box-price'>{precioConIvaKg + ' ' + monedaSimbolo + ' '}</span>
                        <span className='price_comparator-box-weight'>{pesoKg +''+ pesoUnidadKg}</span>
                      </p>
                  </div>
                  
              );
          }
      }
    },
    {
      label: t('price_comparator.table.col_5.title'),
      name: 'brand_name',
      options: {
          filter: true,
          sort: true,
          empty: true,
          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
              const brandName = data[dataIndex]['brand_name'];
              const brandFlag = data[dataIndex]['brand_flag'];

              const hasError = imageError[dataIndex];
            
              const imageBackground = hasError
              ? `url(${defaultPathIcons}) lightgray 50%/cover no-repeat`
              : `url(${imagePath}${brandFlag}.png) 50%/cover no-repeat`;
              
              return (
                  <div className="price-comparator__icon-column">
                    <div className="">{brandName}</div>
                    <div className="price-comparator__image-flag" style={{
                      background: imageBackground
                    }}>
                    </div>
                  </div>
                  
              );
          }
      }
  },
  {
    label: t('price_comparator.table.col_6.title'),
    name: 'source',
    options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
            const source = data[dataIndex]['source'];
            const flagSource = data[dataIndex]['flag_source'];
            const hasError = imageError[dataIndex];

            const imageBackground = hasError
            ? `url(${defaultPathIcons}) lightgray 50%/cover no-repeat`
            : `url(${imagePath}${flagSource}.png) 50%/cover no-repeat`;
            return (
                <div className="price-comparator__icon-column">
                  <div className="">{source}</div>
                  <div className="price-comparator__image-flag" style={{
                    background: imageBackground
                  }}>
                  </div>
                </div>
            );
        }
      }
    },
    {
      label: t('price_comparator.table.col_7.title'),
      name: 'segment',
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
            const source = data[dataIndex]['segment'];
            const segmentIcon = data[dataIndex]['segment_icon'];

            const hasError = imageError[dataIndex];

            const imageBackground = hasError
            ? `url(${defaultPathIcons}) lightgray 50%/cover no-repeat`
            : `url(${imagePath}${segmentIcon}.png) no-repeat`;

            return (
                <div className="price-comparator__icon-column">
                  <div className="">{source}</div>
                  <div
                    className="price-comparator__image-flag" style={{
                      background: imageBackground
                    }}
                  >
                  </div>
                </div>
            );
        }
      }
    }
];

// useEffect(
//     () => {
//         // dispatch the products whenever the productsTable component is rendered and when the dispatch is fired
//         // dispatch the action to get the products from the API 
//         dispatch(productsStartLoading());
//     },
//     [ dispatch ]
// );

  useEffect(
      () => {
          // setData(productsData);
          // if (productsData && Array.isArray(productsData.data)) {
            // setData(productsData.data);
const productData = id === 0 
                    ? products1 
                    : id === 1
                    ? products2 
                    : id === 2
                    ? products3 
                    : id === 3
                    ? products4 
                    : id === 4
                    ? products5 
                    : id === 5
                    ? products6 
                    : products1
            setData(productData?.data!);
        // }
      },
      [id]
      // [ loadingProducts, productsData ]
  );

  useEffect(() => {
    const preloadImages = () => {
      // This object will contain the state of whether the image has errored
      const newImageErrorState: { [key: number]: boolean } = {};

      data.forEach((item, index) => {
        const imagePathFinal = `${imagePath}${item.segment_icon}.png`;
        console.log('imagePathFinal',imagePathFinal)
        const img = new Image();
        img.onload = () => {
          newImageErrorState[index] = false;
          setImageError(prev => ({ ...prev, ...newImageErrorState }));
        };
        img.onerror = () => {
          newImageErrorState[index] = true;
          setImageError(prev => ({ ...prev, ...newImageErrorState }));
        };
        img.src = imagePathFinal;
      });
    };

    preloadImages();
  }, [data]);

  const downloadPdf = () => {
    const input = document.getElementById('divToPrint'); // The ID of the div you want to download as PDF
    html2canvas(input!)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('productData.pdf');
      });
  };

  return (
    <>
    <div className="price-comparator__container">
      <div id="divToPrint" className='price-comparator__price-comparator-container-main'>

        {/* <PriceComparatorResumen /> */}

        <div className="price-comparator__info-row">
          <span className='price-comparator__price-comparator-container'>
            <span className='price-comparator__price-comparator-container-head'>
              {t('price_comparator.resume.header.title')}
            </span>
            <span className='price-comparator__price-comparator-container-head-title'>
              {productName}
            </span>
          </span>
          <div className="price-comparator__info-right price-comparator__info-order price-comparator__info-right-button" onClick={downloadPdf}>
            <img src={fileArrowDown} alt={t('price_comparator.resume.header.download')} className="price-comparator__sort-icon" />
            {t('price_comparator.resume.header.download')}
          </div>
          <div className="price-comparator__new-comparator-button" onClick={() => window.location.reload()}>
            <div className="price-comparator__paginator-button-text">{t('price_comparator.resume.header.button')}</div>
          </div>
        </div>
        <hr className='price-comparator__price-comparator-container-title-separator'></hr>
        <div className="price-comparador_charts">
        <img src={`../../assets/images/price-calculator-charts0${id + 1}.png`} alt="" />
        </div>
        <hr className='price-comparator__price-comparator-container-title-separator'></hr>
        
        
        <div className="col-md-12">

        
        {!loadingProducts ? (
          <ThemeProvider theme={theme} >
            <MUIDataTable title={'Productos'} data={data} columns={columns} options={options}    />
            <div className="price-calculator-resume__footer">
              <button
                className="price-calculator-resume__footer-link"
                onClick={handleClickOpen}
                >
                {t('price_calculator.footer.link')}
              </button>
            </div>
          </ThemeProvider>
        ) : (
          null
          // <Spinner /> TODO: add Spinner component when call products API 
        )}
		</div>

      </div>
    </div>
    <ModalLegalResumen open={open} handleClose={handleClose} />
    </>
  );
};

export default PriceComparatorTable;
