import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header/Header";
import LandingPage from "./pages/LandingPage/landing-page";
import LoginPage from "./pages/LoginPage/login-page";
import RegisterPage from "./pages/RegisterPage/register-page";
import Profile from "./pages/Profile/profile";
import ExporterTestPage from "./pages/ExporterTest/exporter-test";
import CubicajePage from "./pages/Cubicaje/cubicaje";
import Footer from "./components/Footer/Footer";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { FormProvider } from "./context/FormContext";
import InformationPage from "./pages/Information/information-page";
import RestorePasswordPage from "./pages/RPasswordPage/restore-password";
import "./styles/styles.css";
import TerminosServicioPage from "./pages/TerminosServicio/terminosservicio";
import PriceComparatorPage from "./pages/PriceComparator/price-comparator";
import PoliticasPrivacidadPage from "./pages/PoliticasPrivacidad/politicasprivacidad";
import NoticiasPage from "./pages/Noticias/noticias";
import NoticiaPage from "./pages/Noticias/noticia";
import AcercaInterconectarPage from "./pages/AcercaInterconectar/acercaInterconectar";
import ServiciosPage from "./pages/Servicios/servicios";
import FinalReport from "./pages/Information/FinalReport/finalReport";
import PreferencesPage from "./pages/Preferences/preferences-page";
import PriceCalculatorPage from "./pages/PriceCalculator/price-calculator";
import { VideoProvider } from "./context/VideoContext";
import { ComparatorVideoProvider } from "./context/ComparatorVideoContext";
import { QueryClient, QueryClientProvider } from "react-query";
import ActivateAccountPage from "./pages/ActivateAccount/activate-account";
import RecoverPasswordPage from "./pages/RecoverPassword/recover-password";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FormProvider>
            <VideoProvider>
              <ComparatorVideoProvider>
                <AppContent />
              </ComparatorVideoProvider>
            </VideoProvider>
          </FormProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
};

const AppContent: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const navigate = useNavigate();
  let variation: "error" | "register_ok" | "email_register" | "email_password" =
    "error";

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isAuthenticated = !!token;

    setIsAuthenticated(isAuthenticated);

    // if (!isAuthenticated && pathname !== '/login' && pathname !== '/register' && pathname !== '/information' && pathname !== '/restorepassword' && pathname !== '/test') {
    //   navigate('/login');
    // }
  }, [setIsAuthenticated, pathname, navigate]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  const getPageComponent = (page: React.JSX.Element) => {
    return isAuthenticated ? page : <LoginPage />;
  };

  return (
    <div className="row_app">
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/activate-account/:token"
          element={<ActivateAccountPage />}
        />
        <Route
          path="/recover-password"
          element={<RecoverPasswordPage />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/exportertest"
          element={getPageComponent(<ExporterTestPage />)}
        />
        <Route
          path="/information"
          element={<InformationPage variation={variation} />}
        />
        <Route
          path="/informe-final-test-exportador"
          element={<FinalReport />}
        />
        <Route path="/reset-password/:token" element={<RestorePasswordPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={getPageComponent(<RegisterPage />)} />
        <Route path="/cubicaje" element={getPageComponent(<CubicajePage />)} />
        <Route path="/terminosservicio" element={<TerminosServicioPage />} />
        <Route
          path="/pricecomparator"
          element={getPageComponent(<PriceComparatorPage />)}
        />
        <Route
          path="/politicasprivacidad"
          element={<PoliticasPrivacidadPage />}
        />
         <Route path="/noticias" element={<NoticiasPage />} />
        <Route path="/noticias/:keyword" element={<NoticiasPage />} />
        <Route path="/noticia/:id" element={<NoticiaPage />} />
        <Route
          path="/acerca-interconectar"
          element={<AcercaInterconectarPage />}
        />
        <Route path="/servicios" element={<ServiciosPage />} />
        <Route path="/preferencias" element={<PreferencesPage />} />
        <Route path="/calcularprecios" element={<PriceCalculatorPage />} />
      </Routes>
      {isAuthenticated ? <Footer /> : null}
    </div>
  );
};

export default App;
